import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  articles: [],
  resources: [],
};

const wellnessSlice = createSlice({
  name: "wellness",
  initialState,
  reducers: {
    setWellness(state, action) {
      if(action?.payload?.articles) {
        state.articles = action?.payload?.articles
      }
      if(action?.payload?.resources) {
        state.resources = action?.payload?.resources
      }
    }
  }
});

export const { setWellness } = wellnessSlice.actions
const reducers = {
  wellness: wellnessSlice.reducer,
};

export const selectArticles = (state = {}) => {  
  const articles = state?.wellness?.articles ?? []

  return articles
};

export default reducers;
