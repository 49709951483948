import { AlertBody } from "./AlertBody";

export const Alerts = ({ alerts = [] }) => {
  return alerts.map((alert = {}, index) => {
    const { itemHash = `emergency-alert-${index}` } = alert;
    const lastItem = index === alerts.length - 1;
    const showDivider = alerts.length > 1 && !lastItem;
    const key = itemHash + index;
    return <AlertBody key={key} {...alert} divider={showDivider} />;
  });
};
