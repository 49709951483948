import React, { useState } from 'react';
import { Mui, MuiIcons } from '@osu/react-ui';
import { ALL } from './constants';
import useColorStyles from '../hooks/useColorStyles';

const AccountFilter = ({accounts, handleOnClick}) => {
    const [activeAccount, setActiveAccount] = useState(ALL);
    // can this get out of sync with parent and cause issues?
    // this looks simillar to use cases where i do a useReducer 
    // in the parent to have related funcitionality grouped together

    const handleFilterOnClick = (chipId) => {
        setActiveAccount(chipId);
        handleOnClick(chipId);
    }

    const FilterChip = ({account, active}) => {
        let sx = useColorStyles(account, active);
        
        return (
            <Mui.Chip
                variant={active ? "outlined" : ""}
                icon={<MuiIcons.FilterList color={sx.color}/>}
                sx={sx} 
                className="margin-right-2 margin-bottom-1" 
                label={account} 
                onClick={() => (handleFilterOnClick(account))}
            />
        )
    }


    return (
        <div className="margin-bottom-2">
            {accounts.map( (account, index) => {
                if (!account.name) { return false }
                let label = account.name;
                let active = activeAccount === label;
                return <FilterChip key={`${label}-${index}`} account={label} active={active} />
                
            })}
        </div>
    )
}

export default AccountFilter