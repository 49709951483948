import { addDays, isWeekend, nextMonday, previousMonday, isMonday } from "date-fns"

const currentDate = new Date()

const currentlyWeekend = isWeekend(currentDate)
export const closestMonday = 
  isMonday(currentDate) 
  ? currentDate 
  : currentlyWeekend 
  ? nextMonday(currentDate) 
  : previousMonday(currentDate)

let DISPLAY = {
  CONTROLS: {
    SHOW_FIELDS: "SHOW_FIELDS",
    VIEW_BY: "VIEW_BY",
    ADJUST_HEIGHT: "ADJUST_HEIGHT"
  },
  VIEW_BY_OPTIONS: {
    DAY: "day",
    WEEK: "week",
  },
  DEFAULT_STATE: {
    SIZING: {
      BASE: 4,
      MAX_HEIGHT: 30,
    },
    START_DATE: closestMonday.toISOString(),
    END_DATE: addDays(closestMonday, 4).toISOString(),
  }
}

DISPLAY.DEFAULT_STATE.SIZING.HEIGHT_PER_HOUR = DISPLAY.DEFAULT_STATE.SIZING.BASE + "rem"
DISPLAY.DEFAULT_STATE[DISPLAY.CONTROLS.VIEW_BY] = DISPLAY.VIEW_BY_OPTIONS.WEEK

const HTML_TABLE_IDS = {
  tableLabel: "classes-calendar-label",
  tableDescriptor: "classes-calendar-descriptor",
}

export {
  DISPLAY,
  HTML_TABLE_IDS
}