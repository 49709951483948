const DAYS_PAST_DUE_TO_SHOW = 7;

export function groupAssignments(assignments) {
  return assignments.reduce(function (memo, x) {
    let dueDate = new Date(x.dueAt).toDateString();
    if (!memo[dueDate]) {
      memo[dueDate] = [];
    }
    memo[dueDate].push(x);

    return memo;
  }, {});
}

export const filterAssignments = (assignments) => {
  return assignments.filter((assignment) => {
    if (assignment.lockedForUser || assignment.hasSubmittedSubmissions) {
      return false;
    }
    const dueDate = new Date(assignment.dueAt);
    const now = new Date();

    const daysPastDue = now.setDate(now.getDate() - DAYS_PAST_DUE_TO_SHOW);
    if (daysPastDue > dueDate) {
      return false;
    }
    return true;
  });
};

export function sortAssignments(assignments = []) {
  return [...assignments].sort((a, b) => {
    const dueDateA = a?.dueAt && new Date(a.dueAt);
    const dueDateB = b?.dueAt && new Date(b.dueAt);
    return dueDateA - dueDateB;
  });
}

export function getNextUpcomingDueDate(assignments = []) {
  const sortedAssignments = sortAssignments(assignments);
  const now = new Date();
  if (!sortedAssignments) {
    return null;
  }
  return sortedAssignments.find((assignment) => {
    const dueDate = new Date(assignment.dueAt);
    if (dueDate > now) {
      return dueDate;
    }
    return false;
  })?.dueAt;
}
