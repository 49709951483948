import { searchAcrossContentServer, getPerson } from "../data-sources/enums"
// import { getGoogle, searchAcrossContentServer, getPerson } from "../data-sources/enums"
const searchOptions = {
    GET: 'GetSearchResults',
    getPerson,
    getPersonal: searchAcrossContentServer,
    // getGoogle
}

export {
    searchOptions
}