const elevenItems = [...Array(11)];
let times = [{
    hour: 0,
    label: "12am"
}]
elevenItems.forEach((item, index) => {
    times.push({
        hour: index + 1,
        label: (index + 1) + "am"
    })
});

times.push({
    hour: 12,
    label: "12pm"
})

elevenItems.forEach((item, index) => {
    times.push({
        hour: (index + 13),
        label: (index + 1) + "pm"
    })
});

export { 
    times
}