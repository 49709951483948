import { useCallback, useContext, useEffect, useRef } from "react";
import { useSendTextToLexMutation } from "../../services/graphql/api";
import { useDispatch, useSelector } from "react-redux";
import { appendMessage, selectLexMessages } from "../../services/graphql/slices/lex_messages";
import { IntentsContext } from "@osu/chatbot";
import { Icons, intents } from "../components/Bot";
import useMessagingCleanup from "./useMessagingCleanup";
import { createTimeStamp } from "../util/functions";

const formatLex = (messages = [], botIntents = intents) => {
  return messages.map(({ content, ...rest }) => {
    const match = Object.entries(botIntents).find(([key, label]) => label === content)
    const Icon = match?.[0] && Icons?.[match[0]]

    return {
      ...rest,
      content: Icon ? <Icon /> : content
    }
  })
}

const useCustomizedInteractions = () => {
  useMessagingCleanup()
  const dispatch = useDispatch()
  const [sendMessage, results] = useSendTextToLexMutation();
  const { intents: contextIntents } = useContext(IntentsContext)

  const sendWelcomeMessage = useRef()
  const messages = useSelector((state) => {
    const lexMessages = selectLexMessages(state)
    return formatLex(lexMessages, contextIntents)
  })

  const isUninitialized = results?.isUninitialized && (!messages?.length)
  const sessionId = results?.data?.sessionId
  const welcomeQuery = contextIntents?.welcome ?? "Welcome"
  const welcomeCallback = useCallback(async () => {
    if(sendWelcomeMessage.current) {
      return
    }
    try {
      sendWelcomeMessage.current = sendMessage
      return await sendWelcomeMessage.current({
        message: welcomeQuery,
      })
    } catch (error) {
      console.error(error)
    }
  }, [sendMessage, welcomeQuery])
  
  useEffect(() => {
    if (!sendWelcomeMessage.current && isUninitialized) {
      welcomeCallback()
  }
    
}, [isUninitialized, welcomeCallback])


  const send = useCallback(
    async (newMessage) => {
      if(!newMessage) {
        return
      }      
      dispatch(appendMessage({
        message: {
          type: 'input',
          timestamp: createTimeStamp(),
          content: newMessage
        }
      }))
      let parms = {
        message: newMessage,
        sessionId
      }
      const res = await sendMessage(parms);
      return res?.data
    },
    [dispatch, sendMessage, sessionId]
  );

  return {
    ...results,
    messages,
    handleInput: send
  };
};
export default useCustomizedInteractions;
