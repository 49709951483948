import { Mui } from "@osu/react-ui";
import Meeting from "./Meeting"
import useColorizeListByKey, { KEYS } from "../Common/hooks/useColorizeListByKey";

function TableRows(props = {}) {
  const { events, hour, heightPerHour, show, index } = props;
  const hasMeetings = !!events.filter(event => !!event?.meetings.length)?.length
  const { map: colorList, references } = useColorizeListByKey({
    enforceInitializeColors: index === 0 && hasMeetings
  });  

  return events.map(({ dayOfYear, meetings }, idx) => {
    const filteredMeetings = meetings.filter(({ startHour, ...rest }) => {
        return (startHour === hour)
          && rest?.startTime
          && rest?.endTime
          && rest?.courseId
    })

    return (
      <Mui.TableCell key={idx + dayOfYear + "meeting" + filteredMeetings?.length} sx={{
        position: "relative",
      }}>
        {filteredMeetings.map((meeting, idx) => {
          const colorId = references.find((uc) => {
            return uc[KEYS.CLASSCOURSEID] === meeting.courseId
          })?.[KEYS.COLORID]
          return <Meeting
            key={idx + "-" + hour}
            meeting={{
              ...meeting,
              color: colorList[colorId ?? meeting.courseId]
            }}
            heightPerHour={heightPerHour}
            show={show}
            minutesAfterStartHour={meeting?.startMinutes}
            differenceInMinutes={meeting?.differenceInMinutes}
        />
        })}
      </Mui.TableCell>
    );
  });
}

TableRows.defaultProps = {
  events: []
}

export default TableRows;
