import PropTypes from "prop-types";
import { Mui } from "@osu/react-ui";
import ExternalLinkIcon from "./ExternalLinkIcon";
import { mergeSxStyles } from "../../styles/util";
import { forwardRef } from "react";

const ExternalLink = forwardRef((props, ref) => {
  const { disableIcon = false, children, sx, ...rest } = props
    return (
      <Mui.Link
        sx={mergeSxStyles(
          {
            fontWeight: 700
          },
          sx
        )}
        ref={ref}
        {...rest}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
        {!disableIcon && <ExternalLinkIcon />}
      </Mui.Link>
    );
})

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
};

export default ExternalLink;