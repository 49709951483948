import { STATUSES } from "../../../util/constants";
import { setStatus, setToDoList } from "../../graphql/slices/todos";
import { getToDoList } from "../enums";

const CONSTANTS = {
  get: getToDoList,
};

const get = `
    ${CONSTANTS.get} {
        todos {
          dueDate
          email
          id
          name
          postDate
          statusCode
          statusDate
          statusName
          summary
          title
          url
          urlLabel
        }
        lastModified
    }
  `;

  const transformResponse = async (response = {},  { dispatch, ...rest }) => {
    const todoresponse = response?.data?.getToDoList
    try {
      if(!todoresponse) {
        throw new Error("Unexpected response")
      }
      dispatch(setToDoList(todoresponse))
      return response
      
    } catch (error) {
      console.error(error)
      dispatch(setStatus(STATUSES.ERROR))
      return {
        error
      }
    }

  }

const source = {
  CONSTANTS,
  QUERIES: {
    get,
  },
  transformResponse
}

export default source;
