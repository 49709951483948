import { Mui } from "@osu/react-ui";

const GridItem = ({ children }) => {
  const theme = Mui.useTheme()
  const md = Mui.useMediaQuery(theme.breakpoints.only('md'))
  const xs = Mui.useMediaQuery(theme.breakpoints.only('xs'))
  const size = (xs || md) ? 12 : 6

  return <Mui.Grid item xs={size}>
     {children}
  </Mui.Grid>;
};

export default GridItem;
