import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sections: [],
};

const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    setContent(state, action) {
      if(action?.payload?.sections) {
        state.sections = action?.payload?.sections
      }
    }
  }
});

export const { setContent } = contentSlice.actions
const reducers = {
  content: contentSlice.reducer,
};

export const selectContent = (state = {}) => {  
  const sections = state?.content?.sections ?? []

  return sections
};

export default reducers;
