import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers-index";
import { graphqlApi } from "./services/graphql/api.js";
import { Hub } from "aws-amplify";
import { EVENTS, handleAmplifyConfigured, handleParsingCallBackUrl, handleSignInEvent, handleSignInFailure, handleSignOutEvent } from "./util/hub";
import { authorizationApi } from "./services/authorization/api";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(
    graphqlApi.middleware,
    authorizationApi.middleware,
    ),
});


Hub.listen("auth", (data) => {
  const state = store.getState()

  const event = data?.payload?.event
  const { authentication } = state ?? {}

  switch (event) {
    case EVENTS.signIn:
      return handleSignInEvent({
        data,
        authentication,
        store
      })
    case EVENTS.signOut:
      return handleSignOutEvent({
        authentication,
        store
      })
    case EVENTS.configured:
      return handleAmplifyConfigured({
        data,
        authentication,
        store
      })
    case EVENTS.parsingCallbackUrl:
      return handleParsingCallBackUrl({
        store
      })
    case EVENTS.customState_failure:
    case EVENTS.cognitoHostedUI_failure:
    case EVENTS.signIn_failure:
        return handleSignInFailure({
          store
        })
    default: 
      return
  }
})
  
export { store };
