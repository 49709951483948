import { getGoogle, getPerson, searchAcrossContentServer } from "../data-sources/enums"
import { searchOptions } from "./constants"
import { searchQueries } from "./queries"


const _createQuery = (gqlOptions, gqlQueries, queries) => {
    let variables = `$query: String`
    const required= queries.includes(getGoogle) || queries.includes(searchAcrossContentServer)
    if(required) {
        variables += '!'
    }
    if(queries.includes(getGoogle)) variables += `, $startIndex: String`
    if(queries.includes(getPerson)) variables += `, $nextPageLink: String`
    let query = `query ${gqlOptions.GET} (${variables}) {`

    queries.forEach((q) => {
        if(gqlQueries[q]) {
            query += gqlQueries[q]
        }
    })

    query += `
        }
    `

    return query
}

function createSearchQuery(queries = []) {
    return _createQuery(searchOptions, searchQueries, queries)
}

export {
    createSearchQuery
}
