import { Mui, MuiIcons } from "@osu/react-ui";
import InfoBox from "./InfoBox";
import GridItem from "../GridItem";
import usePaperStyleProps from "../../../Common/hooks/usePaperStyleProps";
import { paperSx } from "../../util";

const ListItem = (props = {}) => <Mui.ListItem
   className="display-flex justify-content-space-between" 
   {...props}
  />

const WidgetSubheading = (props = {}) =>  <Mui.Typography 
  variant="subtitle2"
    component="span"
    {...props}
  />

  const Icon = ({ type }) => {
    let Icon = false
    if(type === 'Sick') {
      Icon = MuiIcons.DeviceThermostat
    }
    if(type === 'Vacation') {
      Icon = MuiIcons.BeachAccess
    }
    return Icon && <Icon className="margin-right-1" />
  }

const Widget = ({ current, description, pending, index }) => {
  const { accordionChildren } = usePaperStyleProps()
  const sx = (theme) => paperSx(theme, accordionChildren.elevation + 3)

  return <GridItem>
    <Mui.Paper variant="outlined" component="section" sx={sx}>
      <Mui.Typography className="padding-x-1 padding-y-2 display-flex" variant="h4">
          <Icon type={description} />
          {description} Hours
        </Mui.Typography>
      <Mui.List className="padding-y-0">
          
        <ListItem divider>
          <WidgetSubheading>Current</WidgetSubheading>
          <Mui.Typography component="span">{current} hours</Mui.Typography>
        </ListItem>
        <ListItem divider>
          <WidgetSubheading>Pending</WidgetSubheading>
          <Mui.Typography component="span">{pending ? `${pending} hours`:  "-"}</Mui.Typography>
        </ListItem>
      </Mui.List>
      <InfoBox
        index={index}
        current={current}
        pending={pending}
        description={description}
      />
    </Mui.Paper>
  </GridItem>;
};

export default Widget;
