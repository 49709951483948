import { MuiIcons } from "@osu/react-ui"
import * as service_enums from "../../services/data-sources/enums"

const { ASSIGNMENTS, HOLDS, CARMEN, BALANCES, CLASSES, SOA, STUDENT_ORGS, WELLNESS, PAY,LEAVE } = service_enums

const keys = {
    SEI: "SEI",
    HOLDS,
    CARMEN,
    BALANCES,
    CLASSES,
    STATEMENT_OF_ACCOUNT: SOA,
    SOA,
    STUD_ORGS: STUDENT_ORGS,
    QUICK_LINKS: "QUICK_LINKS",
    POPULAR_LINKS: "POPULAR_LINKS",
    ANNOUNCEMENTS: "ANNOUNCEMENTS",
    NEWS: "NEWS",
    UP_DATES: "UP_DATES",
    WELLNESS,
    DASHBOARD: "DASHBOARD",
    PERSONAL: "PERSONAL",
    ASSIGNMENTS: "ASSIGNMENTS",
    GETHELP: "GETHELP",
    SUGGESTED: "SUGGESTED",
    INSTRUCTORS: "INSTRUCTORS",
    BUCKEYELEARN: "BUCKEYELEARN",
    PAY: PAY,
    LEAVE: LEAVE,
    APPS: 'APPS',
    TODOS: 'TODOS',
    TIMESHEET: 'TIMESHEET',
}
const { STUD_ORGS, QUICK_LINKS, ANNOUNCEMENTS, NEWS, UP_DATES, STATEMENT_OF_ACCOUNT, GETHELP, INSTRUCTORS, BUCKEYELEARN, APPS, TODOS, TIMESHEET } = keys

const labels = {
    [HOLDS]: "Holds",
    [CARMEN]: "Carmen Assignments",
    [BALANCES]: "BuckID Account",
    [CLASSES]: "Class Calendar",
    [STATEMENT_OF_ACCOUNT]: "Statement of Account",
    [SOA]: "Statement of Account",
    [STUD_ORGS]: "Student Organizations",
    [QUICK_LINKS]: "Quick Links",
    [ANNOUNCEMENTS]: "Announcements",
    [NEWS]: "News",
    [UP_DATES]: "Upcoming Dates",
    [WELLNESS]: "Wellness",
    [ASSIGNMENTS]: "Carmen Assignments",
    [GETHELP]: "Contact Buckeye Link",
    [INSTRUCTORS]: "Provide Course Feedback",
    [BUCKEYELEARN]: "BuckeyeLearn",
    [LEAVE]: "Leave Balances",
    [PAY]: 'Net Pay',
    [APPS]: 'Mobile Apps',
    [TODOS]: "To-Do List",
    [TIMESHEET]: "Weekly Timesheet"
}

let icons = {
    [HOLDS]: MuiIcons.Warning,
    [BALANCES]: MuiIcons.AccountBalance,
    [STATEMENT_OF_ACCOUNT]: MuiIcons.Description,
    [ASSIGNMENTS]: MuiIcons.AttachFile,
    [CLASSES]: MuiIcons.Backpack,
    [GETHELP]: MuiIcons.Assistant,
    [INSTRUCTORS]: MuiIcons.RateReview,
    [BUCKEYELEARN]: MuiIcons.Lightbulb,
    [LEAVE]: MuiIcons.AccessTimeFilled,
    [PAY]: MuiIcons.AttachMoney,
    [APPS]: MuiIcons.InstallMobile,
    [TODOS]: MuiIcons.Checklist,
    [TIMESHEET]: MuiIcons.DateRange,
}

const timelineReferences = {
    [BALANCES]: `7 days`
}

export {
    keys,
    labels,
    icons,
    timelineReferences
}