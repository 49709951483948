import { Fragment } from "react";
import { Mui } from "@osu/react-ui";
import GridItem from "../GridItem";
import usePaperStyleProps from "../../../Common/hooks/usePaperStyleProps";
import { createHighContrastDividerColor } from "../../../util/functions";
import { paperSx } from "../../util";

const borderBottom = (theme) => {
  return {
    borderBottom: `solid 1px ${createHighContrastDividerColor(theme)}`,
  }
};
const paddingY = 1;

const Record = ({ id, fields = [] }) => {
  return (
    <Fragment>
      {!!fields?.length &&
        fields.map(({ label, hours }, idx) => {
          const sx = {
            paddingY,
          };
          if (idx < fields.length - 1) {
            sx.borderBottom = borderBottom;
          }
          return (
            <Mui.Box
              key={id + idx + "fields"}
              display="flex"
              justifyContent="space-between"
              sx={sx}
            >
              <Mui.Typography component="dt">{label}</Mui.Typography>
              <Mui.Typography sx={{fontWeight: 'bold'}} component="dd">{hours}</Mui.Typography>
            </Mui.Box>
          );
        })}
    </Fragment>
  );
};

function Records({ index, items = [] }) {
  const { accordionChildren } = usePaperStyleProps()
  if (!items.length) {
    return <Mui.Typography>No timesheet information available.</Mui.Typography>;
  }  
  const sx = (theme) => paperSx(theme, accordionChildren.elevation + 3)
  return (
    <Fragment>
      {items.map(({ weekOf, fields = [] }, idx) => {
        const key = index + idx + weekOf + "records";
        if(!weekOf) return false
        let label = `Week of `
        const [year, month, day] = weekOf.split("-")
        if(year && month && day) {
          label += `${month}/${day}/${year}`
        } else {
          label += weekOf
        }
        return (
          <GridItem key={key}>
            <Mui.Paper
              component="section"
              variant={ "outlined"}
              className={`padding-1 height-100`}
              sx={sx}
            >
              <Mui.Typography
                id={key + "-h5"}
                variant="h5"
                sx={{
                  paddingY,
                  borderBottom,
                }}
              >
                {label}
              </Mui.Typography>
              {!fields?.length ? (
                <Mui.Typography className="padding-top-1">
                  You have not entered any hours for this week.
                </Mui.Typography>
              ) : (
                <dl aria-labelledby={key + "-h5"} className="margin-y-0">
                  <Record fields={fields} id={key} />
                </dl>
              )}
            </Mui.Paper>
          </GridItem>
        );
      })}
    </Fragment>
  );
}

export default Records;
