import { Mui } from "@osu/react-ui";

export const StyledTable = Mui.styled(Mui.Table)(({ theme }) => ({
  display: "block",
  "& thead tr, tbody tr": {
    display: "flex",
    width: `100%`,
  },
  [` .${Mui.tableCellClasses.root}`]: {
    padding: ".2rem",
    flex: 1,
  },
  [`& thead tr th.${Mui.tableCellClasses.root}:first-of-type, tbody tr th.${Mui.tableCellClasses.root}`]:
    {
      flex: 0.5
    },
}));

export const StyledTableHead = Mui.styled(Mui.TableHead)(({ theme }) => ({
  display: "block",
  position: "sticky",
  top: "0px",
  zIndex: theme.zIndex.appBar - 1,
}));

export const StyledTableRow = Mui.styled(Mui.TableRow)(({ theme }) => ({
  marginRight: "3rem",
  backgroundColor: "transparent",
  " tr": {
    backgroundColor: "transparent",
  },
  " tr th:first-of-type ": {
    borderTop: `solid 3rem ${
      theme?.palette?.background?.paper ?? theme?.palette?.background?.default
    }`,
  },
  " tr :not(th:first-of-type)": {
    backgroundColor:
      theme?.palette?.background?.paper ?? theme?.palette?.background?.default,
  },
}));
