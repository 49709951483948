import { Mui, MuiIcons } from "@osu/react-ui";
import React, { useEffect, useRef } from "react";
import { Fragment } from "react";
import { STATUSES } from "../../util/constants";

const Refresh = MuiIcons.Refresh
const createClasses = () => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
})

const id = 'error-alert'

function ErrorAlert(props = {}) {
  const { title, onClick, message, status, action = {}, ...rest } = props;
  const loading = status === STATUSES.LOADING
  const classes = createClasses()
  const defaultActionText = loading ? 'Retrying' : 'Retry'
  const defaultIcon = loading ? null : <Refresh />
  const { startIcon = defaultIcon, text = defaultActionText, ...restOfAction } = action;
  const ref = useRef()
  const success = status === STATUSES.SUCCESS
  const severity = props.severity ? props.severity : success ? STATUSES.SUCCESS : STATUSES.ERROR
  
  useEffect(() => {    
    if (
      message 
      && ref.current instanceof Element 
      && document?.activeElement?.id !== id
    ) {
      ref.current.focus();
    }
  }, [message]);

  let Component = Mui.Box;
  let componentProps = {};
  if (message) {
    Component = Mui.Alert;
    componentProps = {
      ...componentProps,
      ...rest,
      severity
    }
    if (text && onClick) {
      componentProps.action = (
        <Mui.Button
          disabled={loading}
          startIcon={startIcon}
          onClick={onClick}
          color="inherit"
          size="small"
          {...restOfAction}
        >
          {text}
          {loading && <Mui.CircularProgress size={24} sx={classes.buttonProgress} />}
        </Mui.Button>
      );
    }
  }

  return (
    <Component className={message ? "margin-bottom-2" : undefined} autoFocus id={id} tabIndex={-1} ref={ref} role="alert" aria-live="polite" {...componentProps}>
      {message && (
        <Fragment>
          {title && <Mui.AlertTitle>{title}</Mui.AlertTitle>}
          {message}
        </Fragment>
      )}
    </Component>
  );
}

ErrorAlert.defaultProps = {
    message: ''
}

export default ErrorAlert;
