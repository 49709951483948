import { Mui, Markdown as Md, generateUUID } from "@osu/react-ui";
import ExternalLink from "../../Common/components/ExternalLink";
import remarkBreaks from 'remark-breaks'

const Markdown = ({ children }) => {
  return (
    <Md
      markdown={children}
      remarkPlugins={[remarkBreaks]}
      components={{
        a: ({ href, children }) => {
          return <ExternalLink href={href}>{children}</ExternalLink>;
        },
        p: ({ children }) => {
          return (
            <Mui.Typography variant="body1" className="padding-bottom-1">
              {children}
            </Mui.Typography>
          );
        },
        ul: List,
        ol: List,
        li: ({ children }) => {
          return (
            <Mui.ListItem
              disablePadding
              className="padding-bottom-1"
            >
              {children}
            </Mui.ListItem>
          );
        }
      }}
    />
  );
};

const List = ({ children = [], ordered }) => {
  let list = Array.isArray(children) ? children : [];
  list = list.filter(
    (item) =>
      !!item && !["<p>", "<br>", "</p>", "</br>", "\n", "\n\n"].includes(item)
  );
  if (!list.length) {
    return false;
  }
  let listProps = {
    sx: {
      "&.stylized": {
        paddingInlineStart: ordered ? "2rem": "1rem",
      }
    }
  };
  if (ordered) {
    listProps.component = "ol";
  } else {
    listProps.sx["&.stylized li"] = {
        display: "list-item",
        paddingLeft: '.5rem',
        marginLeft: '.5rem'
      }
  }

  return (
    <Mui.List className="stylized" {...listProps} dense>
      {list.map((el, index) => {
        const isListItem = el?.props?.node?.tagName === "li";
        if (isListItem) {
          return el;
        } else {
          return (
            <Mui.ListItem key={el?.key ?? generateUUID(`${index}md-list`)}>
              {el}
            </Mui.ListItem>
          );
        }
      })}
    </Mui.List>
  );
};

export default Markdown;
