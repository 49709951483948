import { Fragment } from "react";
import { isValid, format, parseISO } from "date-fns";
import { Mui } from "@osu/react-ui";
import { useStyles } from "../hooks/useStyles";

export const AlertBody = ({ divider, title, description, date }) => {
  let validatedDate = date && parseISO(date);
  const { title: titleSx } = useStyles();
  return (
    <Fragment>
      {title && (
        <Mui.AlertTitle sx={titleSx} component="h3">
          {title}
        </Mui.AlertTitle>
      )}
      <Mui.Typography component="time" variant="caption">
        {isValid(validatedDate) &&
          `${format(validatedDate, `MMMM do, yyyy - `)}`}
      </Mui.Typography>
      {description}
      {divider && <Mui.Divider className="margin-y-1" />}
    </Fragment>
  );
};
