import React, { Fragment, useState } from "react";
import { Cache } from "aws-amplify";
import { CACHE_KEYS, CACHE_OPTIONS, SIX_MONTHS_FROM_NOW } from "../../util/constants";
import { Mui, MuiIcons } from '@osu/react-ui'

const darkModeOptions = Object.entries(CACHE_OPTIONS[CACHE_KEYS.darkMode])

const cachedOption = Cache.getItem(CACHE_KEYS.darkMode)
const isValidOption = darkModeOptions.find((([key,label]) => key === cachedOption))
const defaultSetting = isValidOption ? cachedOption : CACHE_KEYS.deviceSettings

function withAmplifyCacheValues(Component) {
  return function _withAmplifyCacheValues({ children, ...rest }) {
    const componentName =
      Component.displayName || Component.name || "Component";
    _withAmplifyCacheValues.displayName = `withAmplifyCacheValues(${componentName})`;
    const [darkModeDropdownValue, setDarkModeDropdownValue] = useState(defaultSetting);
    const useOverride = [CACHE_KEYS.off, CACHE_KEYS.on].includes(darkModeDropdownValue)
    let componentProps = {
      ...rest
    };
    if (useOverride) {
      componentProps.darkMode = darkModeDropdownValue === CACHE_KEYS.on;
    }
    componentProps.FooterProps = {
      LinkProps: {
        children: <Mui.Typography component="div" className="margin-top-2">
            <Select 
              value={darkModeDropdownValue}
              onChange={(event) => {
                const newValue = event.target.value
                setDarkModeDropdownValue(newValue)
                Cache.setItem(CACHE_KEYS.darkMode, newValue, {
                  expires: SIX_MONTHS_FROM_NOW,
                });
              }}
            />
        </Mui.Typography>
      }
    }

    return <Component {...componentProps}>{children}</Component>;
  };
}

const Select = ({ 
  onChange, 
  value = defaultSetting
}) => {
  const id = "footer-dark-mode-select"
  const labelId = id + '-label'

  return <Fragment>
    <label id={labelId}>
      <MuiIcons.DarkMode sx={{ mr: '.5em'}} fontSize="inherit" />
      Dark mode:{" "}
    </label>
    <Mui.Select
      labelId={labelId}
      id={id}
      value={value}
      onChange={onChange}
      variant="standard" 
    >
      {darkModeOptions.map(([key, label]) =>  {
        return <Mui.MenuItem key={id + 'select' + key} value={key}>{label}</Mui.MenuItem>
      })}
    </Mui.Select>
  </Fragment>
}

export default withAmplifyCacheValues;
