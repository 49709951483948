import organizationMappings from "../../../StudentOrgs/util/mappings";
import { alterTitleToId, sortByStringField } from "../../../util/functions";
import { getStudentOrganizations } from "../enums";

const CONSTANTS = {
  get: getStudentOrganizations,
};

const get = `
    ${CONSTANTS.get} {
        guid
        name
        sortName
        purposeStatement
        makeUp
        secondaryMakeUp
        keywords
        career
        leaderName
        leaderEmail
        secondaryLeaderName
        secondaryLeaderEmail
        treasurerName
        treasurerEmail
        advisorName
        advisorEmail
        orgEmail
        websiteUrl
        facebookUrl
        orgLogoUrl
        constitutionUrl
        meetingDate
        meetingAddr1
        meetingAddr2
        meetingCity
        meetingState
        meetingZip
        membershipType
        membershipContact
        membershipProcess
        membershipDues
        displayName
        campus
    }
`;

const samplingThreshold = 3;

export const _createStudentOrgFilters = (organizations) => {
  let categories = [];
  let filters = [];
  organizations.forEach(({ makeUp }) => {
    if (makeUp && !categories.includes(makeUp)) {
      categories.push(makeUp);
      filters.push({
        id: alterTitleToId(makeUp),
        title: makeUp,
      });
    }
  });

  filters = sortByStringField(filters, 'title')

  return {
    categories,
    filters,
  };
};

export const createSearchResults = (filters, organizations) => {
  let results = [];
  let disabled = []
  const sortedOrganizations = sortByStringField(organizations, "sortName") 
  filters.forEach(({ id, title, ...rest }) => {
    if (title) {
      let _list = sortedOrganizations.filter((org) => {
        return org?.makeUp && org?.makeUp === title;
      });
      if (_list?.length) {
        results.push({
          ...rest,
          title,
          id,
          list: _list,
        });
      } else if (id) {
        disabled.push(id)
      }
    }
  });
  
  results = sortByStringField(results, "title");

  return {
    results,
    disabled
  }
};

const _checkDesiredOrg = (org, type) => org?.guid && org?.makeUp && (type === org?.makeUp) && org?.websiteUrl

export const _createSampling = (organizations = []) => {
  let sampling = {}

  Object.entries(organizationMappings).every(([key = "", categorizedOrgs = []]) => {
    if(!sampling[key]) {
      sampling[key] = []
    }
    if(sampling[key].length >= samplingThreshold) {
      return false
    }

    categorizedOrgs.every(orgType => {
      if(sampling[key].length >= samplingThreshold) {
        return false
      }

      const firstMatch = orgType && organizations.find(org => {
        const correctCategory = _checkDesiredOrg(org, orgType)
        const alreadyInSample = sampling[key].find(existing => org?.guid && existing?.guid === org?.guid)
        return correctCategory && !alreadyInSample
      })
      if(firstMatch) {
        sampling[key].push({
          ...firstMatch,
          id: firstMatch.guid
        })
      }
      
      return true
    })
    return true
  })
  
  return sampling;
};

function transformResponse(response = {}) {
  let orgData = {}
  const data = response?.data?.[CONSTANTS.get] || {}
  if (Array.isArray(data) && data?.length) {
      orgData.organizations = data
      const { filters } = _createStudentOrgFilters(data);
      orgData.filters = filters
      orgData.sampling = _createSampling(data)
      const { results, disabled } = createSearchResults(filters, data)
      orgData._searchResults = results
      orgData._disabled = disabled

    return {
      data: orgData
    }
  }
}

const source = {
  CONSTANTS,
  QUERIES: {
    get,
  },
  transformResponse
}

export default source;
