import { Mui, MuiStyles } from "@osu/react-ui";
import useGridSizing from "../../Dashboard/hooks/useGridSizing";
import { mergeSxStyles } from "../../styles/util";

export default function Toolbar({ children, sx, ...rest }) {
  const theme = MuiStyles.useTheme();
  const { padding } = useGridSizing();
  const mergedSxStyles = mergeSxStyles(
    {
      width: "100%",
      [theme.breakpoints.up("xl")]: {
        maxWidth: theme.breakpoints.values.xl,
        paddingX: theme.spacing(padding),
        margin: "0 auto",
      },
    },
    sx
  );
  return (
    <Mui.Toolbar disableGutters sx={mergedSxStyles} {...rest}>
      {children}
    </Mui.Toolbar>
  );
}
