import { useSelector } from "react-redux";
import { selectTimesheets } from "../../../services/graphql/slices/hrProfileData";
import Individual from "./Individual";
import { Mui } from "@osu/react-ui";
import { Fragment } from "react";
import DashAction from "../../../Dashboard/components/DashAction";
import { WORKDAY } from "../../../util/constants";

function Timesheet() {
  const timesheets = useSelector(selectTimesheets);
  if (!timesheets?.length) {
    return <Mui.Typography>No timesheet information available.</Mui.Typography>;
  }

  return (
    <Fragment>
      {timesheets.map((timesheet, idx) => (
        <section
          key={idx + "timesheet" + timesheet.weekOf}
          className="padding-bottom-3"
        >
          <Individual index={idx} {...timesheet} />
        </section>
      ))}
      <DashAction href={WORKDAY.TIMESHEET.href}>
        {WORKDAY.TIMESHEET.label}
      </DashAction>
    </Fragment>
  );
}

export default Timesheet;
