import { Fragment } from "react";
import { Mui } from '@osu/react-ui'
import DashAction from "../../Dashboard/components/DashAction";
import { WORKDAY } from "../../util/constants";
import { useSelector } from "react-redux";
import { selectNetPay } from "../../services/graphql/slices/hrProfileData";
import { createHighContrastDividerColor } from "../../util/functions";

function HRPay(props = {}) {
  const net = useSelector(selectNetPay)

  return (
    <Fragment>
        <Mui.Typography sx={(theme) => ({ 
            borderRadius: '.5rem',
            backgroundColor: theme?.palette?.action?.focus,
            padding: "1rem 0", 
            "textAlign": "center", 
            border: `solid 1px ${createHighContrastDividerColor(theme)}`
          })}>
          <Mui.Typography sx={{fontWeight: 'bold'}} component="span">
              Net Pay:
          </Mui.Typography>
          {" "}{net ?? "-"}
        </Mui.Typography>
      <Mui.Divider className="margin-y-2" />
      <DashAction href={WORKDAY.PAYSLIPS.href}>{WORKDAY.PAYSLIPS.label}</DashAction>
    </Fragment>
  );
}

export default HRPay;
