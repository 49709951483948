import React, { Fragment } from "react";
import { Mui, MuiIcons } from "@osu/react-ui";
import { formatMiniTimeLabel } from "../util/functions";
import { format, parseISO } from "date-fns";
import { capitalizeFirstLetter } from "@osu/react-ui";
import ReachableBy from "../../Search/components/Contact/ReachableBy";

const iconProps = {
  fontSize: "inherit",
  sx: {
    mt: 0.5,
    mr: 1,
  },
};

const IconSection = ({ Icon, action, children }) => {
  const child = action ? (
    <Mui.Box display="flex" alignItems="flex-start">
      <Mui.Box>{children}</Mui.Box>
      {action}
    </Mui.Box>
  ) : (
    children
  );
  return (
    <Mui.Box className="width-100" display="flex">
      <Icon {...iconProps} />
      <Mui.Typography component="div" variant="body2" className="width-100">
        {child}
        <Mui.Divider className="width-100 margin-y-1" />
      </Mui.Typography>
    </Mui.Box>
  );
};

const Description = ({ primary, action, secondary, tertiary }) => {
  return (
    <IconSection action={action} Icon={MuiIcons.Backpack}>
      {primary && (
        <Mui.Typography
          component="span"
          variant="subtitle1"
          className="display-flex width-100"
        >
          {primary}
        </Mui.Typography>
      )}
      {secondary && (
        <Mui.Typography
          className="display-flex width-100"
          component="span"
          variant="subtitle2"
        >
          {secondary}
        </Mui.Typography>
      )}
      {tertiary && (
        <Mui.Typography
          className="display-flex width-100"
          component="span"
          variant="caption"
        >
          {tertiary}
        </Mui.Typography>
      )}
    </IconSection>
  );
};

const CurrentTime = ({ startTime: st, endTime: et }) => {
  const startTime = parseISO(st);
  const endTime = parseISO(et);
  const date = format(startTime, `E, MMM d`);
  const formattedStartTime = formatMiniTimeLabel(startTime, true);
  const formattedEndTime = formatMiniTimeLabel(endTime, true);

  return (
    <IconSection Icon={MuiIcons.AccessTimeFilled}>
      <Mui.Typography component="time" dateTime={startTime} variant="body2">
        {date} {"\u00B7"} {formattedStartTime} - {formattedEndTime}
      </Mui.Typography>
    </IconSection>
  );
};

const Location = ({ isOnline, facilityDescription }) => {
  return (
    <IconSection Icon={MuiIcons.LocationOn}>
      {isOnline ? "Online" : facilityDescription}
    </IconSection>
  );
};

function Week({ days, ...rest }) {
  const mappedDays = Object.entries(days)
    .filter(([day, bool]) => bool)
    .map(([day]) => capitalizeFirstLetter(day));

  if (!mappedDays.length) {
    return false;
  }

  return (
    <Mui.Typography variant="body2" display="flex" {...rest}>
      <MuiIcons.EventNote {...iconProps} />
      {mappedDays.join(", ")}
    </Mui.Typography>
  );
}

const Instructors = ({ instructors }) => {
  if(!instructors?.length) {
    return false
  }
  return  <IconSection
    Icon={MuiIcons.Person}
  >
    <Mui.List dense disablePadding>
      {instructors.map(({ displayName, email , role, nameN }, index) => <Mui.ListItem key={(nameN ?? email) + index + "list-instructor-iten"}>
        <Mui.ListItemText 
          className="margin-top-0" 
          primary={role}
          primaryTypographyProps={{
            variant: 'caption'
          }}
          secondary={<Fragment>
            {displayName}&nbsp;
            {email && <ReachableBy email={email} />}
          </Fragment>}
          secondaryTypographyProps={{
            display:'flex',
            flexDirection: 'column',
            variant: 'body2',
            color: 'default',
          }}
        />
      </Mui.ListItem>)}
    </Mui.List>
  </IconSection>
}

function Details(props = {}) {
  const { id, open, anchorEl, onClose, meeting = {}, ...rest } = props;
  const {
    description,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
    startTime,
    endTime,
    mainTitle,
    facilityDescription,
    componentName,
    isOnline,
    instructors
  } = meeting;
  const days = {
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
  };

  return (
    <Mui.Popover
      anchorEl={anchorEl}
      id={id}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      {...rest}
    >
      <Mui.Box className="padding-2 width-100">
        <Description
          primary={mainTitle}
          secondary={description}
          tertiary={componentName}
          action={
            <Mui.IconButton className="margin-left-auto" onClick={onClose}>
              <MuiIcons.Close />
            </Mui.IconButton>
          }
        />
        <Location
          isOnline={isOnline}
          facilityDescription={facilityDescription}
        />
        <Instructors instructors={instructors} />
        <CurrentTime startTime={startTime} endTime={endTime} />
        <Week days={days} />
      </Mui.Box>
    </Mui.Popover>
  );
}

export default Details;
