import { Mui } from "@osu/react-ui";
import { Fragment } from "react";
import { selectToDoStatus, toDoStatuses } from "../../../services/graphql/slices/todos";
import List from "./List";
import DashAction from "../../../Dashboard/components/DashAction";
import { Link } from "react-router-dom";
import { linkablePathValues } from "../../../Routes/paths";
import { useSelector } from "react-redux";
import { STATUSES } from "../../../util/constants";
import Warning from "../Warning";

function ToDos() {
  const showWarning = useSelector(state => {
    const status = selectToDoStatus(state)
    return [STATUSES.ERROR, STATUSES.LOADING].includes(status)
  });
  if(showWarning) {
    return <Warning />
  }

  return (
    <Fragment>
      <Mui.Typography variant="h4" className="padding-bottom-1">
        {toDoStatuses.labels[toDoStatuses.keys.incomplete]}
      </Mui.Typography>
      <List headingLevel={"h5"} />
      <Mui.Divider sx={{ my: 2 }} />
      <DashAction to={linkablePathValues.toDos} component={Link}>
        View My <span aria-label="To - Do's">To-Dos</span>
      </DashAction>
    </Fragment>
  );
}

export default ToDos;
