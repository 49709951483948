import React, { Fragment } from "react";
import { Mui, MuiIcons } from "@osu/react-ui";
import { formatMiniTimeLabel } from "./util/functions";
import Details from "./Details/"
import useMeetingClasses from "./styles/useMeetingClasses";
import { format, isValid } from "date-fns"

const variants = {
  text: "text",
  contained: "contained",
  outlined: "outlined",
};

function TimeBlock({ meeting = {} }) {  
  const { mainTitle, facilityDescription } = meeting
  const startTime = meeting.startTime && (isValid(meeting.startTime) ? meeting.startTime : new Date(meeting.startTime))
  let miniTime = formatMiniTimeLabel(startTime, true)
  const fullDate  =startTime && format(startTime, "MMMM do, yyyy")
  const { palette } = Mui.useTheme()

  return <Fragment>
      <Mui.Typography variant="subtitle2" component="span">{mainTitle}</Mui.Typography>
      {facilityDescription && <Mui.Typography color={palette.mode !== "dark" && "secondary"} variant={"caption"} >
        {facilityDescription}
      </Mui.Typography>}
      <Mui.Typography color="secondary" variant={"overline"}>
        {miniTime} 
        <Mui.Typography variant="span" aria-label="to">{" - "}</Mui.Typography>
        {formatMiniTimeLabel(meeting.endTime, true)} 
      </Mui.Typography>
      {fullDate && <Mui.Typography variant="srOnly">{fullDate}</Mui.Typography>}
  </Fragment>
}

function Meeting(props = {}) {
  const { meeting = {}, minutesAfterStartHour, differenceInMinutes } = props;
  const sx = useMeetingClasses({ color: meeting?.color })
  const [anchorEl, setAnchorEl] = React.useState();
  const [buttonVariant, alterButtonVariant] = React.useState(variants.contained);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let timeAfterStart = minutesAfterStartHour / 60;
  return (
    <Fragment>
      <Mui.Paper
        id={meeting.id}
        elevation={0}
        className="display-flex flex-direction-column justify-content-flex-start"
        sx={[
          sx.meeting(Math.abs(differenceInMinutes / 60)),
          sx.absolute(Math.abs(timeAfterStart)),
        ]}
        component={Mui.Button}
        variant={buttonVariant}
        onMouseEnter={() => {
          alterButtonVariant(variants.outlined);
        }}
        onMouseLeave={() => {
          alterButtonVariant(variants.contained);
        }}
        onClick={handleClick}
      > 
        <TimeBlock secondary={sx.secondary} meeting={meeting}/>
        <MuiIcons.OpenInFull sx={(theme) => ({
          fontSize: theme.typography.caption.fontSize,
          ml: "auto"
        })} />
      </Mui.Paper>
      <Details
        anchorEl={anchorEl}
        id={id}
        open={open}
        meeting={meeting}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 3,
            sx: {
              maxWidth: "30rem",
              display: "flex",
              alignItems: "flex-start",
              borderWidth: "0",
              borderBottomWidth: `calc(${sx.colorTheming.borderLeftWidth}*.5)`,
              borderColor: sx.colorTheming.borderColor,
              borderStyle: sx.colorTheming.borderStyle,
            }
          }
        }}
      />
    </Fragment>
  );
}

Meeting.defaultProps = {
  meeting: {},
};

export default Meeting;
