const fallback = [
  {
    identifier: "b",
    role: "body",
    text: "If you or someone you know is an imminent danger to themselves or someone else, go to the nearest emergency department or call 9-1-1.",
  },
  {
    identifier: "d",
    role: "divider",
    text: null,
  },
  {
    identifier: "e",
    role: "body",
    text: "If you are thinking of harming yourself or if you are worried about someone else and need advice about what to do, call the suicide prevention lifeline at 614-221-5445 or 988 or reach the Crisis Text Line by texting 988.",
  },
];

export { fallback };
