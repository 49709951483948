import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectColorMapping, setColors } from "../../services/graphql/slices/colors";
import {Mui} from "@osu/react-ui"
import { COLORS } from "../../util/constants";
import { initializeColorList, selectClasssesFetched, selectColorReferences, selectColorsInitialized } from "../../services/graphql/slices/dashboardCalendar";
import { selectEnrollmentCourses, selectEnrollmentsHasBeenFetched } from "../../services/graphql/slices/enrollments";
import { selectAssignmentCourses } from "../../services/graphql/slices/assignments";

export const KEYS = {
  COLORID: 'colorId',
  ASSIGNMENTCID: 'assignmentCourseId',
  CLASSCOURSEID: 'classCourseId'
}

const convertStringsToColor = (palette= {}, map = {}) => {
  let mapping = {}
  Object.entries(map).forEach(([identifier, colorString]) => {
    mapping[identifier] = palette[colorString ?? COLORS.gray]
  })

  return mapping
}

const checkIfDataHasFinished = (state) => {
  const assignmentCourses = selectAssignmentCourses(state)
  const enrollmentCourses = selectEnrollmentCourses(state)
  const enrollmentsFetched = selectEnrollmentsHasBeenFetched(state)
  const enrollmentsHasLoaded = enrollmentsFetched && (enrollmentCourses?.length === assignmentCourses?.length)
  const classesFetched = selectClasssesFetched(state)
  return enrollmentsHasLoaded && classesFetched
}

const useColorizeListByKey = (options = {}) => {
  const dispatch = useDispatch()
  const theme = Mui.useTheme()
  const palette = theme.palette.colors
  const colorMap = useSelector((state) => {
    const mapping = selectColorMapping(state)
    return convertStringsToColor(palette, mapping)
  })
  const items = useSelector(selectColorReferences)
  const forceInitialization = useSelector((state) => {
    const alreadyConfigured = selectColorsInitialized(state)
    let _forceInitialization = false
    if(!alreadyConfigured && !items?.length && options?.enforceInitializeColors) {
      _forceInitialization = checkIfDataHasFinished(state)
    }
    
    return _forceInitialization
  })

  useEffect(() => {
    dispatch(setColors({ items  }))
  }, [dispatch, items])

  useEffect(() => {
    if(forceInitialization) {
      dispatch(initializeColorList())
    }
  }, [dispatch, forceInitialization])

  return {
    map: colorMap,
    references: items
  }
};

export default useColorizeListByKey;