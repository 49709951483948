import { createSlice } from "@reduxjs/toolkit";
import { getUniqueStamp, sections } from "../../../Dashboard/util/accordionSections";
import { ASSIGNMENTS, CLASSES, LEAVE, PAY, TODOS } from "../../data-sources/enums";
import { selectAssignmentsChips } from "./assignments";
import { selectEnrollments } from "./enrollments";
import { selectClassCalChips } from "./dashboardCalendar";
import { selectToDoChips } from "./todos";
import { selectHrStatus, selectLeaveChips, selectPayChips } from "./hrProfileData";
import { keys } from "../../../Dashboard/util/enums";
import { STATUSES } from "../../../util/constants";

let initialState = {
};

sections.forEach(({ query, ...rest }) => {
  if(query) {
    initialState[query] = {
      data: null,
      chips: []
    }
  }
})

const getDashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboard(state, action) {
      const res = action?.payload?.data
      if(Object.keys(res ?? {}).length) {
        Object.entries(res).forEach(([query, value]) => {
          if(query) {
            Object.assign(state[query], value)
          }
        })
      }
    }
  },
});

export const { setDashboard } = getDashboardSlice.actions

const reducers = {
  getDashboard: getDashboardSlice.reducer,
};

export const selectDashboardData = (state = {}) => {
  return state?.dashboard
};

export const selectDashboardResponseByKey = (state = {}, key) => {
  const matchingSection = sections.find(({ key: sectionKey }) => sectionKey === key)
  let data = matchingSection?.query && state?.dashboard?.[matchingSection.query]
  let chips
  if(key === ASSIGNMENTS) {
    chips = selectAssignmentsChips(state)
  }
  if(key === CLASSES) {
    chips = selectClassCalChips(state)
  }
  if(key === PAY) {
    chips = selectPayChips(state)
  }
  if(key === LEAVE) {
    chips = selectLeaveChips(state)
  }
  if(key === TODOS) {
    chips = selectToDoChips(state)
  }

  return {
    section: matchingSection,
    ...data ?? {},
    chips: chips ?? data?.chips,
    key
  }
};

export const selectDashboardResponseDataByKey = (state = {}, key) => {
  return {
    data: selectDashboardResponseByKey(state, key)?.data,
    key
  }
}

export const selectDashboardResponseChipsByKey = (state = {}, key) => {
  return {
    chips: selectDashboardResponseByKey(state, key)?.chips,
    key
  }
}

export const selectDashboardSectionByKey = (state = {}, key) => {
  return {
    section: selectDashboardResponseByKey(state, key)?.section,
    key
  }
}

export const selectEnrollmentData = (state = {}, courseId) => {
  const enrollmentData = (selectEnrollments(state) ?? []).find(o => o.courseId === courseId)
  return enrollmentData
}

export const compareData = (a = {}, b = {}) => {
  const noData = !a?.data && !b?.data
  if(noData) {
    return true
  }
  const aNotB = a?.data && !b?.data
  const bNotA = !a?.data && b?.data
  if(aNotB || bNotA) {
    return false
  }
  const stampA = getUniqueStamp(a?.key, a?.data)
  const stampB = getUniqueStamp(b?.key, b?.data)

  return stampA === stampB
}


export const compareChips = (a, b) => {
  const stringifyChips = (chips = []) => {
      return chips.map(({ title, snippet }) => title + snippet).join()
  }
  const noAChips = !a?.chips?.length
  const noBChips = !b?.chips?.length
  if(noAChips && noBChips) {
      return true
  }
  if((noAChips && !noBChips) || (!noAChips && noBChips)) {
      return false
  }
  const stringA = stringifyChips(a?.chips)
  const stringB = stringifyChips(b?.chips)
  return stringA === stringB
}
export const selectDashLoading = (state, key) =>{
  const hrDataLoading = selectHrStatus(state) === STATUSES.LOADING

  return {
    [keys.PAY]: hrDataLoading,
    [keys.LEAVE]: hrDataLoading,
    [keys.TIMESHEET]: hrDataLoading,
  }
}

export default reducers;
