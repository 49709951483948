import { setInfo } from "../../graphql/slices/finalGrades";
import { getFinalGrades } from "../enums";

const CONSTANTS = {
  get: getFinalGrades,
};

const get = `
    ${CONSTANTS.get} {
      terms {
        active
        gpa {
          cumulative
          cumulativeHours
          current
          totalEarnedHours
        }
        grades {
          title
          subtitle
          grade
        }
        termName
        termCode
      }
    }
`;

const transformResponse =  (response, { dispatch }) => {
  const { terms  = []} = response?.data?.[getFinalGrades] ?? {}
  terms?.length && terms.forEach(term => {
    const { grades, active } = term ?? {}
    const termGradeNotAvailable = grades.every(({ grade }) => !grade)
    
    dispatch(setInfo({
      term,
      pending: active && termGradeNotAvailable
    }))
  })

  return {
    data: {
      terms
    }
  }
}
const source = {
  CONSTANTS,
  QUERIES: {
    get,
  },
  transformResponse
};
export default source;
