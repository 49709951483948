import { Mui, MuiIcons } from "@osu/react-ui";

const Base = ({ label, Icon, ...rest }) => (
  <Mui.Link
    underline="hover"
    variant="subtitle2"
    {...rest}
  >
    <Icon fontSize="inherit"/>&nbsp;
    {label}
  </Mui.Link>
);

function ReachableBy({ email, phone }) {
  return (
    <Mui.Box>
      {email && (
        <Base
          className={phone?.formatted ? "margin-right-2" : undefined}
          label={email}
          href={`mailto:${email}`}
          Icon={MuiIcons.Email}
        />
      )}
      {phone?.formatted && (
        <Base
          label={phone.formatted}
          Icon={MuiIcons.Phone}
          href={`tel:${phone.formatted}`}
        />
      )}
    </Mui.Box>
  );
}

export default ReachableBy;
