import { createSlice } from "@reduxjs/toolkit";
import { INTENTS } from "../../../Chatbot/util/constants";
import { createTimeStamp } from "../../../Chatbot/util/functions";

const initialState = {
  messages: []
};

const getLexMessaging = createSlice({
  name: "lex",
  initialState,
  reducers: {
    appendMessage(state, action) {
      let message = action?.payload?.message
      const error = action?.payload?.error
      const input = action?.payload?.input
      const isWelcome = !state?.messages?.length && input === INTENTS.WELCOME
      
      if(error) {
        let suggestionButtons = []
        if(input) {
          suggestionButtons.push({
            "text": 'Try again',
            "value": input
          })
        }
        suggestionButtons.push({
          "text": "Help",
          "value": "Help"
        })
        const errorRes = {
          content: 'Something went wrong! Would you like to try again?',
          hideFeedback: true,
          hideHelp: true,
          type: "response",
          timestamp: createTimeStamp(),
          suggestions: [
            {
                "title": "Suggestions",
                "buttons": suggestionButtons
            }
          ]
        }
        state.messages.push(errorRes)
      } else if(message?.content) {
        if(isWelcome) {
          message.hideHelp = true
          message.hideFeedback = true
          message.isWelcome = true
        }

        message.timestamp = createTimeStamp()

        state.messages.push(message)
      }

    },
    clear(state) {
      state.messages = []
    }
  }
});

export const { appendMessage, clear } = getLexMessaging.actions
const reducers = {
  getLexMessaging: getLexMessaging.reducer,
};

export default reducers

export const selectLexMessages = (state = {}) => {
  return state?.lex?.messages ?? []
}