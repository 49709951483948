import ToDoIcon from "../../../Assets/Images/ToDo";

const StyledToDo = (props) => (
  <ToDoIcon
    {...props}
    sx={(theme) =>  ({
      " *": {
        fill: theme.palette.colors.yellow,
      },
      "& .to-do-icon": {
        fill: theme.palette.getContrastText(theme.palette.colors.yellow),
      },
    })}
  />
);


export default StyledToDo;
