import { MuiX, Mui, DateFns } from "@osu/react-ui";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCalendarLimits,
  selectDashboardCalendarDateRange,
  selectShowWeekend,
  setViewByOptions,
} from "../services/graphql/slices/dashboardCalendar";
import { addDays, format } from "date-fns";
import { getDates } from "./util/functions";
import useTableDisplay from "../Balances/hooks/useTableDisplay";
import { useCallback, useEffect, useState } from "react";
import CustomDayPicker from "../Common/components/Dates/CustomDayPicker";

const formatDateForEquality = (date) => {
  const formatStr = "MMddyyyy"
  return date && format(date, formatStr)
}

function Toolbar({ className, setForceAdjustTableScroll }) {
  const dispatch = useDispatch();
  const mobile = Mui.useMediaQuery((theme) => theme.breakpoints.down("sm")) 
  const betweenSmMd = Mui.useMediaQuery((theme) => theme.breakpoints.between("sm", "lg")) 
  const fullScreen = useTableDisplay()
  const xl = Mui.useMediaQuery((theme) => theme.breakpoints.up("xl"))
  const [allowance, setAllowance] = useState(4)
  const { start: startDate, end: endDate } = useSelector(
    selectDashboardCalendarDateRange,
    (a, b) => {
      const matchingStart = (formatDateForEquality(a.start) === formatDateForEquality(b.start))
      const matchingEnd = (formatDateForEquality(a.end) === formatDateForEquality(b.end))

      return matchingEnd && matchingStart
    }
  );
  const showWeekend = useSelector(selectShowWeekend)
  const calendarLimits = useSelector(selectCalendarLimits, (a,b) => {
    const matchingStart = (formatDateForEquality(a.earliest) === formatDateForEquality(b.earliest))
    const matchingEnd = (formatDateForEquality(a.latest) === formatDateForEquality(b.latest))
    return matchingStart && matchingEnd
  })
  const setDateRange = useCallback(({ startDate: sd = startDate, daysOut = allowance }) => {
    let options = {};
    if (sd) {
      options.startDate = sd.toISOString();
      options.endDate = addDays(sd, daysOut).toISOString()
    }
    if (startDate) dispatch(setViewByOptions(options));
    setForceAdjustTableScroll(false)
  }, [allowance, dispatch, setForceAdjustTableScroll, startDate])

  useEffect(() => {
    setAllowance(current => {
      let allowance = 4
      if (showWeekend && fullScreen) allowance = 6
      if(mobile || betweenSmMd) allowance = 2
      if(current !== allowance) {
        setForceAdjustTableScroll(true)
        setDateRange({
          daysOut: allowance
        })
      }
      return allowance
    })
  }, [fullScreen, betweenSmMd, mobile, setDateRange, showWeekend, xl, setForceAdjustTableScroll])

  let selectedDays = getDates(startDate, endDate);

  return (
    <DateFns.LocalizationProvider>
      <MuiX.DatePicker
        label="Starting Date"
        onChange={(startDate) => setDateRange({ startDate })}
        className={className}
        slots={{ day: CustomDayPicker }}
        value={startDate}
        minDate={calendarLimits?.earliest}
        maxDate={calendarLimits?.latest}
        slotProps={{
          day: { selectedDays, startDate, endDate },
        }}
      />
    </DateFns.LocalizationProvider>
  );
}

export default Toolbar;
