import { getClasses } from "../enums";
import { chips } from "./shared";

const CONSTANTS = {
  get: getClasses,
};

const get = `
  ${CONSTANTS.get} {
    ${chips}
    termNames
    classes {
      buildingNumber
      catalogNumber
      component
      componentName
      courseId
      dates
      description
      endDate
      endTime
      faciltyId
      facilityDescription
      friday
      isInPerson
      isOnline
      monday
      room
      saturday
      section
      startDate
      startTime
      wednesday
      tuesday
      thursday
      sunday
      subject
      start
      end
      instructors {
        displayName
        email
        emplid
        nameN
        role
      }
    }
  }
`;

const source = {
  CONSTANTS,
  QUERIES: {
      get
  }
}

export default source