
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import {  Amplify, Auth } from "aws-amplify";
import reportWebVitals from "./reportWebVitals";
import App from "./App/components";
import AppleTouchIcon from "./DocumentHead/AppleTouchIcon";
import "./styles/index.scss";
import awsExports from "./aws-exports";
import apiConfig from "./api-config";
import CustomAuthStorage from './util/CustomAuthStorage';
import { createRoot } from "react-dom/client";
import { store } from "./store";
import { StrictMode } from "react";
import { withGoogleAnalyticsListener } from "@osu/wm-react-analytics";
import hasOwn from 'object.hasown'

if (!Object.hasOwn) {
	hasOwn.shim();
}

Auth.configure({ storage: new CustomAuthStorage(apiConfig.customAuthStorage) });
Amplify.configure({ 
    ...awsExports, 
    ...apiConfig,
    correctClockSkew: true,
    maxRetries: 5,
    retryDelayOptions: {
      base: 1000
    }
  });

const root = createRoot(document.getElementById("root"));
let RenderableApp = App
const useAnalytics = process.env.REACT_APP_ENABLE_GA === "true"
if(useAnalytics) {
  RenderableApp = withGoogleAnalyticsListener(App)
}

root.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <AppleTouchIcon />
        <RenderableApp />
      </BrowserRouter>
    </Provider>
  </StrictMode>,
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
