import { sortAssignments } from "../../../Assignments/util/transform";
import { selectDueTodayCount, setAssignments, setChips } from "../../graphql/slices/assignments";
import { initializeColorList, setAssignmentCourses } from "../../graphql/slices/dashboardCalendar";
import { removeEnrollmentFromQueue, selectEnrollmentName } from "../../graphql/slices/enrollments";
import { getAssignments } from "../enums";
import { chips } from "./shared";

const CONSTANTS = {
  get: getAssignments,
};

const get = `
    ${CONSTANTS.get}(courseId: $courseId)  {
      ${chips}
      data {
      id
      description
      dueAt
      unlockAt
      lockAt
      pointsPossible
      gradingType
      createdAt
      updatedAt
      submittedAt
      courseId
      name
      hasSubmittedSubmissions
      htmlURL
      lockedForUser
      lockExplanation
      submissionsDownloadURL
      enrollmentName
    }
  
  }
`;

const transformResponse = (response, { getState, dispatch, parameters }) => {
  dispatch(removeEnrollmentFromQueue({
    ...parameters
  }))
  const state = getState()

  let assignments = (response?.data?.[getAssignments]?.data ?? [])
    .filter(o => o.courseId === parameters?.courseId)
    .map(o => {
      o.enrollmentName = selectEnrollmentName(state, parameters?.courseId)
      return o
    })

  assignments = sortAssignments(assignments)
  dispatch(setAssignmentCourses({ assignmentsDue: assignments.filter(a => a.dueAt) }))
  dispatch(setAssignments({
    assignments, 
    courseId: parameters?.courseId,
  }))

  const newDueTodayCount = selectDueTodayCount(getState())
  dispatch(setChips({
    chips: [{
      title: "Due Today",
      snippet: `${newDueTodayCount ?? 0}`,
      description: "Assignments due today",
    }]
  }))
  const { enrollments } = getState()
  const assignQ = enrollments?.queue ?? []
  if(!assignQ?.length) {
    dispatch(initializeColorList())
  }

  return response
}

const source = {
  CONSTANTS,
  transformResponse,
  QUERIES: {
    get,
  }};
  

export default source;
