import { Mui } from "@osu/react-ui"
import { useSelector } from "react-redux"
import { selectHeightPerHour, selectMaxHeight } from "../../services/graphql/slices/dashboardCalendar"

const useStylingValues = () => {
    const theme = Mui.useTheme()
    const maxHeight = useSelector(selectMaxHeight)
    const heightPerHour = useSelector(selectHeightPerHour)
    const xsHeight = `${heightPerHour}*1.5`
    const baseZ = theme.zIndex.appBar
    const isDarkMode = theme.palette.mode === "dark"
    return {
        theme,
        heightPerHour,
        maxHeight,
        xsHeight,
        baseZ,
        isDarkMode
    }
}

export default useStylingValues