
import { Mui, MuiIcons } from "@osu/react-ui"
import { labels } from "../../util/enums";
import A11yIcon from "../../../Common/components/A11yIcon";
import { mappings } from "../../../styles/icon_colors";
import { accordionSummaryContentSx } from "./styles";
import ChipListSummary from "./ChipListSummary";
import { useSelector } from "react-redux";
import { compareChips, selectDashboardResponseByKey, selectDashboardSectionByKey } from "../../../services/graphql/slices/dashboard";

const AccordionSummary = (props = {}) => {
  const { type: key, expanded, ariaControls, buttonId, expandIcon } = props
  const color = mappings[key]
  const idPrefix = key + "-panel-"
  const { section } = useSelector((state) => selectDashboardSectionByKey(state, key), (a, b) => {
    const keyMatches = (a?.key === b?.key)
    const queryMatch = a?.section?.query === b?.section?.query
    return keyMatches && queryMatch
  }) 
  const { chips = [] } = useSelector((state) =>
    selectDashboardResponseByKey(state, key),
    compareChips);
  const { Icon } = section ?? {}
  const headingId = idPrefix + "header"
  const chipList = headingId + "chip-list"
  const label = labels[key]
  const hasChips = !!(chips?.length)
  const padding = '1rem'
  const boxSx=(theme) => ({
    display: "flex",
    flexDirection: 'column',
    minHeight: '4rem',
    position: 'relative',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
    }})

  return ( 
    <Mui.Box sx={boxSx}>
      <Mui.Typography className="display-flex" sx={{
        flexShrink: 0,
        marginLeft: padding,
        display: 'flex',
        alignItems: 'center'
      }} component="h3" variant={expanded ? "h3" : "subtitle1"} id={headingId} >
        {Icon && <A11yIcon component="span" className="margin-right-1" color={color} >
          <Icon fontSize="small" aria-hidden="true" />
        </A11yIcon>}
        <span className="padding-y-1">
          {label}
        </span>
      </Mui.Typography>
      {hasChips && <ChipListSummary 
        id={chipList} 
        expanded={expanded} 
        type={key} 
        chips={chips} 
        aria-labelledby={headingId}
      />}
      <Mui.AccordionSummary
        aria-controls={ariaControls}
        id={buttonId}
        sx={theme => accordionSummaryContentSx((theme, expanded))}
        aria-label={expanded ? "Collapse" : "Expand"}
        aria-describedby={headingId}
      >
        {expandIcon ??  <MuiIcons.ExpandMore sx={{
          ml: 'auto',
          marginRight: padding
        }} />}
      </Mui.AccordionSummary>
    </Mui.Box>
  );
};

export default AccordionSummary