import React from 'react'
import { Mui } from '@osu/react-ui';

const Informational = ({info = "", infoComponent="h2", children = false, childrenFirst = false, direction = "row", ...rest}) => {
  const classesObj = {display: "flex", flexDirection: {direction}, ...rest}
  if(direction === "column"){ classesObj.alignItems = 'flex-start'}
  return (
    <Mui.Container sx={classesObj}>
        {childrenFirst && children}
        <Mui.Typography component={infoComponent} variant={infoComponent}>
            {info}
        </Mui.Typography>
        {!childrenFirst && children}
    </Mui.Container>
  )
}

export default Informational