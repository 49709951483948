const ALL = "All";
const BUCK_ID = "BuckID";
const DINING_DOLLARS = "Dining Dollars";
const GRAY_10 = "Gray 10";
const SCARLET = "Scarlet 14";
const DECBAL = "Declining Balance";
const CARMEN_1 = "Carmen 1";
const CARMEN_2 = "Carmen 2";
const ACCOUNT_TYPES = [BUCK_ID, DINING_DOLLARS, GRAY_10,SCARLET, DECBAL, CARMEN_1, CARMEN_2]

const TRANSACTION_DISPLAY_INFO = {Date: "15%", Time: "20%", Description: "45%", Amount: "20%"};
const MOBILE_TRANSACTION_DISPLAY_INFO = {Description: "65%", Amount: "35%"};  

export {ALL, BUCK_ID, DINING_DOLLARS, ACCOUNT_TYPES, TRANSACTION_DISPLAY_INFO, MOBILE_TRANSACTION_DISPLAY_INFO};