import { RESOURCE_TYPES } from "../../util/constants";
import { LINK_TYPES } from "./enums";
import { MuiIcons } from "@osu/react-ui";

const organizationMappings = {
  [RESOURCE_TYPES.ACADEMIC]: [
    "Honoraries/Honor Societies",
    "Technology",
    "Academic/College"
  ],
  [RESOURCE_TYPES.WELLNESS]: [],
  [RESOURCE_TYPES.FINANCIAL]: [],
  [RESOURCE_TYPES.SOCIAL]: [
    "Creative and Performing Arts",
    "Social Fraternities/Sororities",
    "Media, Journalism, and Creative Writing",
    "Governance Organizations",
    "Social/Programming",
    "Special Interest",
  ],
  [RESOURCE_TYPES.STUDENTS]: [
    "Honoraries/Honor Societies",
    "Community Service/Service Learning",
    "Creative and Performing Arts"
  ],
};

export const linkTypeMappings = {
  [LINK_TYPES.EMAIL]: {
    Icon: MuiIcons.Email,
    label: "Email"
  },
  [LINK_TYPES.WEBSITE]: {
    Icon: MuiIcons.Link,
    label: "Website"
  },
  [LINK_TYPES.FACEBOOK]: {
    Icon: MuiIcons.Facebook,
    label: "Facebook"
  },
  [LINK_TYPES.CONSTITUTION]: {
    Icon: MuiIcons.HistoryEdu,
    label: "Constitution"
  },
};

export default organizationMappings