import React from "react";
import { Mui } from "@osu/react-ui";

function Time(props = {}) {
  const { label, children, sx } = props;

  return (
    <Mui.TableRow sx={sx}>
      <Mui.Typography
        component={(p = {}) => <Mui.TableCell {...p} component="th" />}
        scope="row"
        className="border-none"
        sx={{
          textAlign: "right",
          marginTop: "-1.2em",
          paddingRight: "0.5em"
        }}
      >
        {label}
      </Mui.Typography>
      {children}
    </Mui.TableRow>
  );
}

export default Time;
