import * as React from "react";
import { Mui } from "@osu/react-ui";
import { Link, useLocation } from "react-router-dom";

export default function Breadrumb(props = {}) {
  const { showHome, links, setBreadcrumbLinks, ...rest } = props;
  const { pathname } = useLocation() ?? {};

  const lastPath = links?.[links.length - 1]?.to;

  React.useEffect(() => {
    if (lastPath && pathname !== lastPath) {
      setBreadcrumbLinks([]);
    }
  }, [lastPath, pathname, setBreadcrumbLinks]);

  if (links?.length < 1) {
    return false;
  }

  let renderableLinks = [];
  if (showHome) {
    renderableLinks.push({
      to: "/",
      "aria-current": !pathname || pathname === "/",
      label: "Home",
    });
  }
  renderableLinks = renderableLinks.concat(links);
  return (
    <nav aria-label="breadcrumb" {...rest}>
      <Mui.Breadcrumbs className="padding-left-0" component="ol">
        {renderableLinks.map(({ to, label, ...rest }, index) => {
          const current = to === pathname;

          return (
              <Mui.Link
                  underline={current ? "none" : "hover"}
                  key={index + to}
                  aria-current={current ? "page" : undefined}
                  component={Link}
                  color={current ? "secondary" : "primary"}
                  sx={{ fontWeight: current ? "semiBold" : "bold" }}
                  to={to}
                  {...rest}
                >
                {label}
              </Mui.Link>
          );
        })}
      </Mui.Breadcrumbs>
    </nav>
  );
}

Breadrumb.defaultProps = {
  showHome: true,
};
