import { setAnnouncements } from "../../graphql/slices/announcements";
import { getAnnouncements, dismissAnnouncements, getAllAnnouncements } from "../enums";

const CONSTANTS = {
  get: getAnnouncements,
  dismiss: dismissAnnouncements,
  getAll: getAllAnnouncements
};

const get = `
  ${CONSTANTS.get} {
      id 
      title
      message
      url
      updatedDate
  }
`;

const dismiss = `
  ${CONSTANTS.dismiss} (
    id: $id
  ) {
    id 
    title
    message
    url
    updatedDate
  }
`;

const getAll = `
  ${CONSTANTS.getAll} {
    current {
      id 
      title
      message
      url
      updatedDate
    }
    previous {
      id 
      title
      message
      url
      updatedDate
    } 
  }
`;

const transformResponse = (response = {}, { dispatch, getState }) => {
  const dismissedResults = response?.data?.[CONSTANTS.dismiss]
  let announcements = {current: [], previous: []}
  if(dismissedResults) {
    const state = getState()
    const announcementsState = state?.announcements ?? {}
    announcements = { ...announcementsState?._announcements, current: dismissedResults }
  } else {
    announcements = (response?.data?.[CONSTANTS.get] || response?.data?.[CONSTANTS.getAll]) ?? []
  }
  dispatch(setAnnouncements({announcements}))
  
  return {
    data: {
      announcements
    }
  }
};

const source = {
  CONSTANTS,
  QUERIES: {
    get,
    dismiss,
    getAll
  },
  transformResponse
};

export default source;