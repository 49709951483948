import React, { Fragment } from 'react'
import { Mui } from "@osu/react-ui";
import useTableDisplay from '../hooks/useTableDisplay';
import { TRANSACTION_DISPLAY_INFO, MOBILE_TRANSACTION_DISPLAY_INFO } from './constants';
import useColorStyles from '../hooks/useColorStyles';


const DynamicTableCells = ({transaction: {account, date, description}}) => {
    const enhanced = useTableDisplay();
    const columnInfo = enhanced ? TRANSACTION_DISPLAY_INFO : MOBILE_TRANSACTION_DISPLAY_INFO;
    
    let dateTime = date && new Date(date);
    let day = dateTime ? dateTime.toLocaleDateString() : "-";
    let time = dateTime ? dateTime.toLocaleTimeString() : "-";

    const accountChipSx = useColorStyles(account);

    const AccountChip = () => (
        <Mui.Chip 
            sx={accountChipSx}
            label={account} 
        />
    )

    let EnhancedView = () => (
        <Fragment>
            <Mui.TableCell width={columnInfo.Date}>{day}</Mui.TableCell>
            <Mui.TableCell width={columnInfo.Time}>{time}</Mui.TableCell>
            <Mui.TableCell width={columnInfo.Description}>
                <span className="margin-right-2">{description}</span>&nbsp;
                {account && <AccountChip />}
            </Mui.TableCell>
        </Fragment>
    )

    let SimpleView = () => (
        <Mui.TableCell width={columnInfo.Description}>
            <div>{description}</div>
            <div>{day}</div>
            {account && <AccountChip />}
        </Mui.TableCell>
    )

    if(enhanced){
        return <EnhancedView />;
    }
    return <SimpleView />;

}

export default DynamicTableCells