import { MuiStyles } from "@osu/react-ui";
import PropTypes from 'prop-types';
import Hold from "./Hold"

function Holds({ data }) {
  const holds = data ?? [];
  const theme = MuiStyles.useTheme();
  const descriptionList = buildDescriptionList(holds, theme);

  return (<dl>{descriptionList}</dl>)
}

function buildDescriptionList(holds, theme) {
  const verifiedHolds = (holds ?? []).filter(h => !!h)

  return verifiedHolds.map((hold, index) => {
    const backgroundColor = index % 2 === 0 ? theme?.palette?.row?.even : theme?.palette?.row?.odd
    return (
      <Hold key={hold.reason + "." + index} hold={hold} backgroundColor={backgroundColor} />
    );
  })
}

Holds.defaultProps = {
  data: []
}

Holds.propTypes = {
  data: PropTypes.array
}

export default Holds;
