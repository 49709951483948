import { useGetEmergencyAlertsQuery } from "../../services/graphql/api";
import { getEmergencyAlerts } from "../../services/data-sources/enums";
import { Mui, MuiIcons } from "@osu/react-ui";
import { Alerts } from "./Alerts";
import { Links } from "./Links";
import { useStyles } from "../hooks/useStyles";

export const ErrorAlert = () => {
  const { data } = useGetEmergencyAlertsQuery();

  const emergencyAlerts = data?.[getEmergencyAlerts]?.list ?? [];
  const { background } = useStyles()

  if (!emergencyAlerts?.length) {
    return false;
  }
  const links = emergencyAlerts.map(({ links = [] }) => links).flat(1);
  return (
    <Mui.Alert
      severity="error"
      role="none"
      iconMapping={{
        error: <MuiIcons.Notifications fontSize="inherit" />,
      }}
      sx={background}
    >
      <Mui.Typography variant="srOnly" component="h2">
        Buckeye Alert Message
      </Mui.Typography>
      <Alerts alerts={emergencyAlerts} />
      <Links links={links} />
    </Mui.Alert>
  );
};
