import { createSlice, isDraft, original } from "@reduxjs/toolkit";
import { shallowEqual } from 'react-redux'
import {
  createSearchResults,
} from "../../data-sources/content-server/student-orgs";
import { graphqlApi } from "../api";
import { RESOURCE_TYPES, STATUSES } from "../../../util/constants";
import * as JsSearch from 'js-search'

export const searchStudentOrganizations = (query, organizations) => {
  const indexesToSearch = ["keywords", "advisorName", "campus", "career", "leaderName", "makeUp", "displayName", "name","purposeStatement"];
  const documents = isDraft(organizations) ? original(organizations) :organizations
  let search = new JsSearch.Search('guid')
  indexesToSearch.map(o => search.addIndex(o))
  search.addDocuments(documents)

  return search.search(query)
};

const initialState = {
  _filters: [],
  _searchResults: [],
  searchResults: [],
  activeFilters: [],
  _disabled: [],
  disabled: [],
  query: "",
  status: STATUSES.IDLE,
  sampling: {
    [RESOURCE_TYPES.ACADEMIC]: [],
    [RESOURCE_TYPES.FINANCIAL]: [],
    [RESOURCE_TYPES.SOCIAL]: [],
    [RESOURCE_TYPES.WELLNESS]: []
  },
  cache: {},
  show: []
};

const getStudentOrgsSlice = createSlice({
  name: "studentOrgs",
  initialState,
  reducers: {
    initializeFilters(state, action) {
      const { active = [] } = action.payload;
      if(active?.length && !state.activeFilters?.length) {
        state.activeFilters = active
      }
    },
    cacheIndividualOrg(state, action) {      
      const { id, guid, list } = action.payload;
      if(list?.length) {
        list.forEach(item => {
          const exists = item?.guid && state.cache?.[item?.guid]
          if(!exists) {
            state.cache[item.guid] = item
          }
        })
      } else if(guid && !state?.cache?.[id]) {
        state.cache[id] = action.payload
      } else if(state?._organizations?.length) {
        const organizations = state?._organizations ?? []
        const matchingOrg = organizations.find(({ guid }) => {
          return id && (id === guid)
        })
        if(matchingOrg) {
          if(!state.cache) state.cache = { [id]: {} }
          state.cache[id] = matchingOrg
        }
      }
    },
    filterById(state, action) {
      const { id } = action.payload;
      const newActiveFilters = state.activeFilters.filter(filter => filter !== id)
      const showFilter = newActiveFilters.length === state.activeFilters.length
      if(showFilter) {
        state.activeFilters.push(id)
      } else {
        state.activeFilters = newActiveFilters
      }
    },
    searchByQuery(state, action) {
      const { query } = action.payload;
      const currentQuery = state.query
      if(!query) {
        state.query = ""
        state.disabled = state._disabled ?? []
        state.searchResults = state._searchResults
      } else if(currentQuery !== query) {
        const organizations = state?._organizations ?? []
        const filters = state?._filters ?? []
        const updatedOrganizations = searchStudentOrganizations(
          query,
          organizations
        );

        const equal  = shallowEqual(updatedOrganizations, organizations)
        if(!equal) {
          state.query = query
          const { results, disabled } = createSearchResults(filters, updatedOrganizations)
          state.disabled = disabled
          state.searchResults = results
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      graphqlApi.endpoints.getStudentOrgs.matchFulfilled,
      (state, { payload }) => {
        if (Array.isArray(payload?.organizations) && payload?.organizations?.length) {
          state._filters = payload?.filters
          state._organizations = payload?.organizations
          state.disabled = payload?.disabled
          state.searchResults = payload?._searchResults
          state._disabled = payload?.disabled ?? []
          state._searchResults = payload?._searchResults
          state.sampling = payload?.sampling
        }
      }
    );
  },
});

export const { searchByQuery, filterById, initializeFilters, cacheIndividualOrg } = getStudentOrgsSlice.actions


export const selectStudentOrgFiltration = (state = {}) => {
  let {  disabled, activeFilters } = state.studentOrgs ?? {};
  return {
    activeFilters,
    disabled
  };
};

export const selectStudentOrgSearch = (state = {}, options = {}) => {
  const { searchResults, _searchResults } = state.studentOrgs ?? {};
  let results = !options.query ? _searchResults : searchResults
  const slice = options?.slice
  if(slice) {
    results = results.map(result => ({
      ...result,
      list: (result?.list || []).slice(0, 5),
      count: result?.list?.length
    }))
  }
  return results;
};

export const selectOrganization = (state = {}, options = {}) => {
  const { id } = options
  const organizations = state?.studentOrgs?._organizations
  if(state?.studentOrgs?.cache[id]) {
    return {
      organization: state.studentOrgs.cache[id],
      cache: true
    }
  }
  const match = organizations.find(({ guid }) => guid && (guid === id))
  return {
    organization: match
  }
};

export const selectStudOrgsSample = (state = {}, resourceType) => {
  const sample = state.studentOrgs?.sampling?.[resourceType]

  return {
    sample,
    isLoading: state.studentOrgs?.status === STATUSES.LOADING,
    status: state.studentOrgs?.status
  }
};

const reducers = {
  getStudentOrgs: getStudentOrgsSlice.reducer,
};

export default reducers;
