import {  useEffect, useRef, useState } from "react";
import DataBot from "./Bot";
import "../styles/index.scss";
import Fab from "./Fab";
import Window from "./Window";
import Header from "./Header";
import { useLocation, useNavigate } from "react-router-dom";
import{ usePrevious, Mui } from '@osu/react-ui'

export const OSU_CHATBOT_ID = 'OSU_CHATBOT'
const headingId = OSU_CHATBOT_ID + "-h1"

function Chatbot() {
  const [anchorEl, setAnchorEl] = useState(null);
  const loc = useLocation() ?? ''
  const headingRef = useRef()
  const boxRef = useRef()
  const inputRef = useRef()
  const { hash } = loc
  const fabRef = useRef()
  const fabId = (fabRef?.current?.id ?? "")
  const fabNeedsFocused = hash && fabId && (hash === `#${fabRef.current.id}`)
  const navigate = useNavigate()
  const previous = usePrevious(anchorEl)
  const windowJustOpened = !previous && Boolean(anchorEl)
  const fabHasFocus = (document.activeElement === fabRef.current)
  const focusInput = fabHasFocus && windowJustOpened

  useEffect(() => {
    if(fabNeedsFocused) {
      fabRef.current.focus()
      navigate({
        hash: ''
      }, {
        replace: true
      })
    }
  }, [navigate, fabNeedsFocused, setAnchorEl])

  useEffect(() => {
    if(focusInput && inputRef.current) {
      inputRef.current.focus()
    }
  }, [focusInput])

  return (
    <Mui.Box ref={boxRef} role="region" aria-labelledby={headingId}>
      <Mui.Typography id={headingId} component="h1" variant="srOnly">Buckeye Chat</Mui.Typography>
      {!anchorEl && <Fab ref={fabRef} anchorEl={anchorEl} onClick={setAnchorEl} />}
      <Window anchorEl={anchorEl}>
        <Header ref={headingRef} setAnchorEl={setAnchorEl} />
        {anchorEl && <DataBot inputProps={{ ref: inputRef }} />}
      </Window>
    </Mui.Box>
  );
}
export default Chatbot;
