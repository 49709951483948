const _component = `
    articleIdentifier
    identifier
    role
    text
`

const component = `
    articleIdentifier
    identifier
    role
    text
`

const article = `
    authorEmail
    byline
    components {
        ${_component}
        components {
            ${_component}
            components {
                ${_component}
                components {
                    ${_component}
                }
            }
        }
    }
    identifier
    lastModified
    metadata {
        excerpt
        thumbnailAccessibilityCaption
        thumbnailURL
    }
    webImage
    subtitle
    title
`

const articleSection = `
    articles {
        ${article}
    }
    sectionId
    title
`

export { component, article, articleSection }

const dataItem = `
    title
    snippet
    description
`
const item = `
    title
    data {
        ${dataItem}
    }
`
export const items = `
    items {
        ${item}
    }
`
export const chips = `
    chips {
        ${dataItem}
    }
`
