
import { GRADES, ASSIGNMENTS, ARTICLES, BALANCES, BUILDINGS, CARMEN, CLASSES, HOLDS, SEARCH_CONTENT_SERVER, SOA, STUDENT_ORGS, WELLNESS, PERSONS, EMERGENCY_ALERTS, FINAL_GRADES, INSTRUCTORS, ENROLLMENTS, ACTIVE_TERMS, TODOS } from "../enums"
import _BALANCES from "./balances"
import _CARMEN from "./carmen"
import _CLASES from "./classes"
import _HOLDS from "./holds"
import _SEARCH from "./search"
import _SOA from "./statement-of-account"
import _STUD_ORGS from "./student-orgs"
import _ARTICLES from "./articles"
import _ASSIGNMENTS from "./assignments"
import _WELLNESS from "./wellness"
import _BUILDINGS from "./buildings"
import _PERSONS from "./persons"
import _EMERGENCY_ALERTS from "./emergency-alerts"
import _GRADES from "./grades"
import _ENROLLMENTS from "./enrollments"
import _FINAL_GRADES from "./finalGrades"
import _ACTIVE_TERMS from "./activeTerms"
import _INSTRUCTORS from "./instructors"
import _TODOS from "./to-do-list"

const CONTENT_SERVER = {
    [BALANCES]: _BALANCES,
    [CARMEN]: _CARMEN,
    [CLASSES]: _CLASES,
    [HOLDS]: _HOLDS,
    [SEARCH_CONTENT_SERVER]: _SEARCH,
    [SOA]: _SOA,
    [STUDENT_ORGS]: _STUD_ORGS,
    [ARTICLES]: _ARTICLES,
    [ASSIGNMENTS]: _ASSIGNMENTS,
    [WELLNESS]: _WELLNESS,
    [BUILDINGS]: _BUILDINGS,
    [PERSONS]: _PERSONS,
    [EMERGENCY_ALERTS]: _EMERGENCY_ALERTS,
    [GRADES]: _GRADES,
    [ENROLLMENTS]: _ENROLLMENTS,
    [FINAL_GRADES]: _FINAL_GRADES,
    [ACTIVE_TERMS]: _ACTIVE_TERMS,
    [INSTRUCTORS]: _INSTRUCTORS,
    [TODOS]: _TODOS
}

export default CONTENT_SERVER