import { Fragment, useEffect, useState } from "react";
import { Mui } from "@osu/react-ui";
import Columns, { createHeadingCellBg, headingCell } from "./Columns";
import TableBody from "./TableBody";
import Table from "./Table"
import { StyledTableHead, StyledTableRow } from "./styles/components";
import Header from "./Header";
import useStylingValues from "./styles/useStylingValues";
export const TIME_INDICATOR_ID = "time-indicator";

function ClassesCalendar({ expanded }) {
  const { baseZ } = useStylingValues()
  const [forceAdjustTableScroll, setForceAdjustTableScroll] = useState(expanded)

  useEffect(() => {
    setForceAdjustTableScroll(expanded)
  }, [expanded])

  return (
    <Fragment>
      <Header setForceAdjustTableScroll={setForceAdjustTableScroll} />
      <Table forceAdjustTableScroll={forceAdjustTableScroll}>
        <StyledTableHead>
          <StyledTableRow  sx={[createHeadingCellBg, {
            zIndex: baseZ + 100
          }]}>
            <Mui.Typography
              component={(p = {}) => <Mui.TableCell
              {...p} sx={(theme) => headingCell(theme)(false)} component="th" />}
              className={`border-none`}
            />
            <Columns />
          </StyledTableRow>
        </StyledTableHead>
        <TableBody />
      </Table>
    </Fragment>
  );
}

export default ClassesCalendar;
