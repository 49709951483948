const compareAuthentication = (a, b) => {
    return (a?.user?.osuid === b?.user?.osuid) 
        && (a?.roles === b?.roles) 
        && (a?.status === b?.status) 
}

const compareSample = (a, b) => {
    const noSampleCreated = !a?.sample?.length && !b?.sample?.length
    if(noSampleCreated) {
        return noSampleCreated
    }
    const createIdHash = (obj) => {
        return (obj?.sample || []).map(sample => sample?.guid).join(" ")
    }
    const aHash = createIdHash(a)
    const bHash = createIdHash(b)
    
    return aHash === bHash
}

const compareOrganizations = (a, b) => {
    if(!a?.guid && !b?.guid) {
        return true
    }
    return a?.guid === b?.guid
}

export {
    compareAuthentication,
    compareSample,
    compareOrganizations
}
