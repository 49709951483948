import { searchOptions } from "../../services/graphql/constants";
import { ROLES } from "../../util/constants";

export const googleId = "google-search";
export const personalDataId = "personal-data";
export const getPerson = "find-people-search";
export const aboutYouLabel = "About You";

const categories = [
  {
    query: searchOptions.getPersonal,
    id: personalDataId,
    title: aboutYouLabel,
    roles: [ROLES.STUDENT],
  },
  {
    query: searchOptions.getPerson,
    id: getPerson,
    title: "Find People",
  },
  // {
  //   query: searchOptions.getGoogle,
  //   id: googleId,
  //   title: "Additional Results",
  //   titleProps: {
  //     variant: 'h3',
  //     component: 'h2'
  //   }
  // },
];

export { categories };
