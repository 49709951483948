const classes = {
  halfBox: (theme) => {
    return {
      flexBasis: "50%",
      [theme.breakpoints.down("lg")]: {
        flexBasis: "100%",
      },
    };
  },
};

export { classes };
const _concatOrAdd = (styles) => {
    let sx = []
    if(Array.isArray(styles)) {
        sx = sx.concat(styles)
    } else if (styles) {
        sx.push(styles)
    }
    return sx
}


const mergeSxStyles = (originalStyles, newStyles) => {
    let sx = _concatOrAdd(originalStyles)
    sx = sx.concat(_concatOrAdd(newStyles))
    return sx
}

const focusStyles = {
  outline: `-webkit-focus-ring-color auto 5px`
}

export {
    mergeSxStyles,
    focusStyles
}
