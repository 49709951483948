import { ErrorAlert } from "./ErrorAlert";

function EmergencyAlert() {
  return (
    <div aria-live="polite" role="status">
      <ErrorAlert />
    </div>
  );
}
export default EmergencyAlert;
