import AssignmentsListView from "./AssignmentsListView";
import DashAction from "../../Dashboard/components/DashAction";
import { Mui } from "@osu/react-ui"
import { pageTitles } from "../../Routes/paths";
import { CARMEN } from "../../util/constants";

function Assignments({ expanded }) {
  return (
    <>
      <AssignmentsListView expanded={expanded} />
      <Mui.Divider className="margin-bottom-2" />
      <DashAction href="/carmen-class/">
        {pageTitles.carmenClass}
      </DashAction>
      <DashAction href={CARMEN.DASHBOARD.href}>
        {CARMEN.DASHBOARD.label}
      </DashAction>
    </>
  );
}

export default Assignments;
