export const formatLinks = ({ links = [] }) => {
  const baseLink = {
    label: "Crime & Safety Notices",
    href: "https://dps.osu.edu/alert-notices",
  };
  const match = links.find((link) => link === baseLink.href);
  let formattedLinks = !match ? [baseLink] : [];
  formattedLinks = formattedLinks.concat(links);
  formattedLinks = formattedLinks.map((link) => {
    if (typeof link === "string" && link.startsWith("http")) {
      let label = link;
      label = label.replace("https://www.", "");
      label = label.replace("http://www.", "");
      label = label.replace("https://", "");
      label = label.replace("http://", "");
      label = label.replace("mailto:", "");
      label = label.replace("tel:", "");
      return {
        label,
        href: link,
      };
    } else if (link?.href) {
      return {
        label: link.label ?? link.href,
        href: link.href,
      };
    }
    return false;
  });

  return formattedLinks;
};
