import { TIME_INDICATOR_ID } from "../Times"
import useStylingValues from "./useStylingValues"

const useTableBodyClasses = ({ repeat }) => {
    const { theme, heightPerHour, xsHeight } = useStylingValues()
    const alternatingColor = theme.palette.rows.odd

    return {
        overflowY: "auto",
        overflowX: "hidden",
        display: "block",
        "& tr": {
          position: "relative",
          height: heightPerHour,
          marginRight: "3rem",
          border: "none",
          [theme.breakpoints.only("xs")]: {
            height: `calc(${xsHeight})`,
          },
        },
        "& tr td": {
          backgroundColor: theme.palette.rows.even,
          border: "none"
        },
        [`& tr td#${TIME_INDICATOR_ID}`]: {
          backgroundColor: "transparent"
        },
        [`& tr:nth-of-type( odd ) td:not(${TIME_INDICATOR_ID})`]: {
          backgroundColor: alternatingColor,
          color: theme.palette.getContrastText(alternatingColor),
          border: "none"
        }
      }
}

export default useTableBodyClasses