import { getHolds } from "../enums";
import { chips } from "./shared";

const CONSTANTS = {
  get: getHolds,
};

const get = `
    ${CONSTANTS.get} {
      ${chips}
        data {
          amount
          amountFormatted
          currency
          department
          instruction
          reason
          startDate
          summary
        }
  }
`;

const source = {
  CONSTANTS,
  QUERIES: {
    get,
  },
};

export default source;
