import React, { Fragment, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { PageHeadingContext, Helmet } from "@osu/react-ui";
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

export function RenderableView(props = {}) {
    const location = useLocation()
    const params = useParams()
    const [searchParams] = useSearchParams()
    const { Component, noIndex, title: pathTitle, titleProps, authRequired, ...rest } = props
    const { setHeading, setOptions } = useContext(PageHeadingContext)
    const title = typeof pathTitle === 'function'
         ? pathTitle({ location, params, searchParams }) 
         : pathTitle

    useEffect(() => {   
        title && setHeading(title)
        return () => {
            setHeading("")
        }
    }, [setHeading, title])

    useEffect(() => {    
        if(titleProps) {
            setOptions(titleProps)
        }
    }, [setOptions, titleProps])
    
    return <Fragment>
        {(noIndex || authRequired) && <Helmet>
            <meta id="meta-hidden" name="robots" content="noindex" />
        </Helmet>}
        <Component title={title} {...rest} />
    </Fragment>
}

RenderableView.defaultProps = {
    noIndex: false, 
    authRequired: false
}

RenderableView.propTypes = {
    path: PropTypes.string.isRequired, 
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired, 
    titleProps: PropTypes.object, 
    Component: PropTypes.oneOfType([PropTypes.element, PropTypes.object]).isRequired, 
    noIndex: PropTypes.bool, 
    authRequired: PropTypes.bool
}

export default RenderableView