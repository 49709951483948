import { useDispatch } from "react-redux";
import { clear } from "../../services/graphql/slices/lex_messages";
import { Hub } from "aws-amplify";
import { EVENTS } from "../../util/hub";

const useMessagingCleanup = () => {
  const dispatch = useDispatch();
  Hub.listen("auth", (data) => {
    const event = data?.payload?.event;
    if ([EVENTS.signIn, EVENTS.signOut, EVENTS.signIn_failure].includes(event)) {
      return dispatch(clear({ event: event }));
    }
  });
};
export default useMessagingCleanup;
