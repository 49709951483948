import { Mui } from "@osu/react-ui";

import { Fragment, PureComponent, createContext } from "react";
import ErrorAlert from "../components/ErrorAlert";

const expectedProps = {
  title: undefined,
  message: undefined,
  onClick: undefined,
  action: undefined,
};

export const ErrorBoundaryContext = createContext(expectedProps);

function withErrorBoundary(Component) {
  class ErrorBoundary extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        hasError: false,
        errorAlert: {},
        open: true,
      };
      this.updateErrorAlert = this.updateErrorAlert.bind(this);
      
    }

    componentDidCatch(error, info) {
      if(this.props.setHasError) {
        this.props.setHasError(true)
      }
      if(this.props.setHeading) {
        this.props.setHeading("Something went wrong!")
      }
      this.setState({ hasError: true });
      console.error(error);
    }

    componentWillUnmount() {
      this.setState({
        errorAlert: {},
      });
      if(this.props.setHasError) {
        this.props.setHasError(false)
      }
    }

    componentDidUpdate(prevProps) {
      const prevLocation = prevProps?.location;
      const nextLocation = this.props?.location;

      let previousPath = `${prevLocation?.pathname || ""}${prevLocation?.search || ""}${prevLocation?.hash || ""}`;
      let nextPath = `${nextLocation?.pathname || ""}${nextLocation?.search || ""}${nextLocation?.hash || ""}`;

      if (!previousPath) previousPath = "/";
      if (!nextPath) nextPath = "/";

      if (previousPath !== nextPath) {
        this.setState({
          errorAlert: {},
        });
        if(this.props.setHasError) {
          this.props.setHasError(false)
        }
      }
    }

    updateErrorAlert(values) {
      if (values?.message !== this.state.errorAlert?.message || values?.status !== this.state.errorAlert?.status) {
        this.setState({
          errorAlert: Object.assign({}, values),
        });
      }
    }

    render() {
      if (this.state.hasError) {
        return (
          <p>
            To report an issue with this site please contact the <Mui.Link href="mailto:oasis-support@lists.osu.edu">Web and Mobile Team</Mui.Link> for
            assistance.
          </p>
        );
      }
      let ErrorWrapper = Fragment;
      let errorAlerts = Object.assign({}, this.state.errorAlert);
      if (errorAlerts?.clearable) {
        errorAlerts.onClick = () => this.setState(({ open }) => ({ open: !open }));
        errorAlerts.action = {};
        errorAlerts.action.startIcon = null;
        errorAlerts.action.text = "Dismiss";
        ErrorWrapper = (props = {}) => <Mui.Collapse {...props} in={this.state.open} />;
      }

      return (
        <ErrorBoundaryContext.Provider
          value={{
            setErrorAlert: this.updateErrorAlert,
          }}
        >
          <ErrorWrapper>
            <ErrorAlert {...errorAlerts} />
          </ErrorWrapper>
          <Component {...this.props} />
        </ErrorBoundaryContext.Provider>
      );
    }
  }
  return ErrorBoundary;
}

export default withErrorBoundary;
