import { Mui } from "@osu/react-ui";
import { useSelector } from "react-redux";
import { selectToDoQuery, selectToDoStatus } from "../../services/graphql/slices/todos";
import { STATUSES, todos } from "../../util/constants";
import { TODOERRROR } from "./Warning";

const NoToDoItem = ({ status }) => {
  const query = useSelector(selectToDoQuery)
  const apiStatus = useSelector(selectToDoStatus);
  if(apiStatus === STATUSES.ERROR) {
    return <Mui.ListItem>{TODOERRROR}</Mui.ListItem>;
  }

  
  return <Mui.ListItem>
    You do not have any{status && ` ${status}`} {todos.individual}s
    {query 
      ? ` regarding "${query}".`
      :  ' at this time.'
    }
  </Mui.ListItem>;
};

export default NoToDoItem;
