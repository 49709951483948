import { setRoles } from "../../authorization/slices";
import { setStandardHours } from "../../graphql/slices/hrProfileData";
import { getAffiliations } from "../enums";

const CONSTANTS = {
  get: getAffiliations,
};

const get = `
    ${CONSTANTS.get} {
      affiliations
      standardHours
    }
`;

const transformResponse = (response = {}, { dispatch }) => {
  const getAff = response?.data?.[getAffiliations]
  const roles = getAff?.affiliations ?? []
  if(roles?.length) {
    dispatch(setRoles({ roles }))
  }
  if(getAff?.standardHours) {
    dispatch(setStandardHours(getAff.standardHours))
  }
  return {
    data: {
      getAffiliations: roles
    }
  }
}


const source = {
  CONSTANTS,
  QUERIES: {
    get,
  },
  transformResponse
};
  

export default source;
