import { getInstructors } from "../enums";
import { chips } from "./shared";

const CONSTANTS = {
  get: getInstructors,
};

const get = `
    ${CONSTANTS.get} {
      ${chips}
      data {
        displayName
        emplid
        seiAvailability {
          available
          showComments
          status
          taskId
          taskUrl
        }
        role
        nameN
        course {
          name
          location
          schedule
        }
      }
    }
`;

const transformResponse = (response = {},  { dispatch }) => {
    return response
}
const source = {
  CONSTANTS,
  transformResponse,
  QUERIES: {
    get,
  }};
  

export default source;
