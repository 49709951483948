import { Mui } from "@osu/react-ui";
import PropTypes from 'prop-types';
import { useState } from "react";
import { ALL, ACCOUNT_TYPES } from "./constants";
import AccountFilter from "./AccountFilter";
import TransactionsTable from "./TransactionsTable";
import DashAction from "../../Dashboard/components/DashAction";


function Balances({data}) {

  const buckId = data;
  const accounts = [{name: ALL}, ...(buckId?.accounts || []) ];
  const transactions = buckId?.transactions || [];
  const theme = Mui.useTheme()
  const [transactionType, setTransactionType] = useState("")
  const sm = Mui.useMediaQuery(theme.breakpoints.down("sm"));
  const mdLg = Mui.useMediaQuery(theme.breakpoints.between("md", "lg"));

  let [filteredTransactions, setFilteredTransactions] = useState(transactions)

  const filterTransactions = (accountName, transactions) => {
    return transactions.filter( transaction => transaction.account === accountName);
  }

  const handleOnClick = (chipId) => {
    const shouldFilterTransactionsByChipId = ACCOUNT_TYPES.some( type => type === chipId);
    setTransactionType(chipId)
    
    let resultTransactions = shouldFilterTransactionsByChipId ? filterTransactions(chipId, transactions) : transactions;
    setFilteredTransactions(resultTransactions);
  }

  const h4 = (transactionType && (ACCOUNT_TYPES.some( type => type === transactionType)))
    ? `Latest ${transactionType} Transactions`
    : "Latest Transactions"

  return ( 
    <Mui.Container>
      <AccountFilter accounts={accounts} handleOnClick={handleOnClick} />
      <Mui.Typography variant="h4" className="margin-bottom-1">
        {h4}
      </Mui.Typography>
      <Mui.TableContainer component={Mui.Paper} sx={{ maxHeight: 480 }}>
        <TransactionsTable filteredTransactions={filteredTransactions} />
      </Mui.TableContainer> 
      <Mui.Box display="flex" flexDirection={(sm || mdLg) ? "column" : "row"} className="margin-top-2">
        <DashAction href="https://buckid.osu.edu/deposit/">Make a Deposit</DashAction>
        <DashAction href="https://buckid.osu.edu/secure/mybuckid">Full Account Activity</DashAction>
        <DashAction href="https://buckid.osu.edu/secure/mybuckid/lostcard/">Report Card Lost / Stolen</DashAction>
      </Mui.Box>
    </Mui.Container>  
  )
}


Balances.defaultProps = {
  data: {accounts: [], transactions: []}
}

Balances.propTypes = {
  data: PropTypes.shape({accounts: PropTypes.arrayOf(PropTypes.object), transactions: PropTypes.arrayOf(PropTypes.object)})
}

export default Balances;