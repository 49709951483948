import { Mui } from "@osu/react-ui";
import { dashCalComparators, selectDashboardCalendarMeetingData } from "../services/graphql/slices/dashboardCalendar";
import { useSelector } from "react-redux";
import Times from "./Times";
import useTableBodyClasses from "./styles/useTableBodyClasses";

export const TIME_INDICATOR_ID = "time-indicator";

function TableBody() {
  const { meetings: events } = useSelector(selectDashboardCalendarMeetingData, (a,b) => {
    const meetingHash = dashCalComparators.meetings(a?.meetings, b?.meetings)
    return meetingHash
  })
  const classes = useTableBodyClasses({ repeat: events?.length });


  return (
    <Mui.TableBody
      sx={classes}
    >
      <Times />
    </Mui.TableBody>
  );
}

export default TableBody;
