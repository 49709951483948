
import React, { Fragment } from "react";
import { Mui } from "@osu/react-ui";

const BuckeyeWhite = Mui.createSvgIcon(
  <Fragment>
    <defs>
      <polygon
        id="path-1"
        points="0 0 19.5404097 0 19.5404097 24 0 24"
      ></polygon>
    </defs>
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group" transform="translate(0.060000, 0.637000)">
        <g id="Group-3">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1"></use>
          </mask>
          <g id="Clip-2"></g>
          <path
            d="M10.3177846,11.0614618 C12.1679409,9.98456618 13.2887837,11.7746995 15.6277809,10.5928824 C18.2789741,9.25335014 17.2254789,7.10479067 19.5404097,5.93533965 C19.5404097,5.93533965 16.5500054,4.52332315 14.2330769,5.69391565 C11.8453764,6.90036979 11.672061,9.8635688 10.1311518,10.656425 C10.0121521,8.50377522 12.0841369,8.27956861 12.0841369,5.63094375 C12.0841369,2.62474881 9.72002711,2.62474881 9.72002711,0 C9.72002711,0 7.14236454,2.08844128 7.14236454,4.71556819 C7.14236454,7.42431124 9.68083614,8.90224849 9.69120462,10.6569006 C9.69196561,10.6636544 9.69091925,10.6685057 9.6874948,10.6703131 C9.68445084,10.6720253 9.68007515,10.6716448 9.67170426,10.6676496 C7.69379674,9.86071509 8.38277785,7.85893152 6.02009489,6.72781536 C3.34150605,5.44545249 2.33300436,7.61617579 -0.00570742322,6.4965696 C-0.00570742322,6.4965696 0.755662834,9.75398628 3.09637222,10.8746388 C5.50661704,12.0283944 7.90297381,10.3347166 9.47089809,11.0659326 C7.83201151,12.4514096 6.42322921,10.8849122 4.3206145,12.4594 C1.93747993,14.2440161 3.34103043,16.175313 1.26019905,17.7336298 C1.26019905,17.7336298 4.44598756,18.5994459 6.52862629,17.0398925 C8.67975411,15.4289723 8.34301614,12.4536926 9.73619814,11.408378 C9.74732762,11.3998169 9.75940833,11.3923021 9.77205979,11.3856435 C9.72297595,13.736436 9.38928193,20.7643667 6.59131283,24.0057074 L8.29003222,24.0057074 C8.53069524,23.4557972 10.4118619,19.3284741 10.0755996,11.4153221 C11.8579327,12.5584238 10.8515238,14.4170462 12.9778243,15.9398818 C15.3975815,17.673036 16.7605141,15.7119654 18.8734022,17.2252886 C18.8734022,17.2252886 18.6783035,13.8832119 16.5637032,12.368652 C14.3862261,10.8091937 11.7376012,12.0569315 10.3177846,11.0614618 Z"
            id="Fill-1"
            fill="#FFFFFF"
            mask="url(#mask-2)"
          ></path>
        </g>
        <polyline
          id="Fill-4"
          fill="#FFFFFF"
          points="15.1276204 23.5767946 15.0238404 23.3172971 14.9541147 23.3172971 14.9541147 23.6741061 14.9993936 23.6741061 14.9993936 23.3789372 15.1212471 23.6741061 15.1341839 23.6741061 15.2559423 23.3789372 15.2559423 23.6741061 15.301126 23.6741061 15.301126 23.3172971 15.2315906 23.3172971 15.1276204 23.5767946"
        ></polyline>
        <polyline
          id="Fill-5"
          fill="#FFFFFF"
          points="14.6490529 23.3593417 14.7496938 23.3593417 14.7496938 23.6741061 14.7948776 23.6741061 14.7948776 23.3593417 14.8957087 23.3593417 14.8957087 23.3172971 14.6490529 23.3172971 14.6490529 23.3593417"
        ></polyline>
        <path
          d="M12.1505333,19.8460423 C12.3587591,19.7412159 12.9956124,19.7714653 13.2381779,19.8717257 C13.4810288,19.9713202 13.6210509,20.0135551 13.7858052,20.2692477 C13.9507497,20.5248451 14.1253968,20.7540933 14.1120795,20.8509292 C14.0984768,20.9478603 14.0051605,21.0494524 14.0051605,21.0494524 C13.946374,21.1711157 13.8033079,21.1992723 13.6519661,21.2186775 C13.500434,21.2380828 12.8073626,21.2938253 12.6074125,21.2219117 C12.4072722,21.1506641 12.2250152,20.8765175 12.1689873,20.5727875 C12.1129594,20.2693428 11.941927,19.9512491 12.1505333,19.8460423 Z M10.0114862,21.2670004 C10.0775972,21.8975755 10.3351922,22.4904817 10.703321,23.0031985 C11.0694522,23.5131568 11.6286846,23.9070641 12.2701038,23.9430209 C12.6031319,23.9616651 12.9298819,23.878527 13.2358949,23.7522978 C13.5914674,23.6056171 14.010202,23.412611 14.311554,23.171187 C15.5634772,22.1682976 14.97742,20.8819396 14.7917385,20.3276537 C14.6679825,19.9569565 14.1146479,19.3215301 13.3099963,19.1816982 C13.1216513,19.1486903 12.9080035,19.1739932 12.5183767,19.1906398 C12.1277036,19.2075718 11.5965327,19.2177501 11.020844,19.4033365 C10.445726,19.5878765 9.91436487,20.3416368 10.0114862,21.2670004 L10.0114862,21.2670004 Z"
          id="Fill-6"
          fill="#FFFFFF"
        ></path>
      </g>
    </g>
  </Fragment>,
  "buckeye leaf"
);

const BuckeyeWhiteIcon = (props) => {
  return <BuckeyeWhite {...props} viewBox="0 0 19.54 24" />;
};

export default BuckeyeWhiteIcon