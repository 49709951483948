import { Mui } from "@osu/react-ui";
import { mergeSxStyles } from "../../styles/util";

const DashAction = ({ sx = {}, href, ...rest }) => {
  return (
    <Mui.Link
      size="small"
      variant="outlined"
      underline="hover" 
      sx={mergeSxStyles(sx, {
        mr: 2,
        my: 1
      })}
      href={href}
      {...rest}
    />
  );
};

export default DashAction;
