import { Link as RouterLink, useParams } from "react-router-dom";
import { Mui, PageHeadingContext } from "@osu/react-ui";
import { useGetBuckeyeLinkDataQuery } from "../../services/graphql/api";
import { useContext, useEffect } from "react";

const Link = (props) => {
  return <Mui.Link component={RouterLink} {...props} />;
};

const heading = "Page Not Found";

function NoComponentFound() {
  const { setHeading, setPageTitle } = useContext(PageHeadingContext);
  const darkMode = Mui.useTheme()?.palette?.mode === "dark";
  const parms = useParams();
  const parsedStr = (parms?.["*"] ?? "").split("/");
  let query = parsedStr?.length && parsedStr[parsedStr.length - 1];
  query = query.replaceAll("-", " ");
  query = decodeURIComponent(query);
  const { data } = useGetBuckeyeLinkDataQuery();
  const quickLinks = data?.quickLinks ?? [];
  let alertProps = {
    severity: "info",
  };
  if (darkMode) alertProps.variant = "outlined";
  useEffect(() => {
    setHeading(heading);
    setPageTitle(heading);
    return () => {
      setHeading("");
      setPageTitle("");
    };
  }, [setHeading, setPageTitle]);

  return (
    <Mui.Box>
      <Mui.Alert className="margin-bottom-1" {...alertProps}>
        Were you searching for <strong>{query}</strong>? Try{" "}
        <Mui.Link>
          {" "}
          <Link to={`/search?q=${query}`}>searching directly for {query}</Link>
        </Mui.Link>
      </Mui.Alert>
      <Mui.Typography variant="body1" className="padding-y-1">
        Buckeye Link has a new look! As a result, some changes have been made to
        the pages you might be familiar with. Let's get you to the right place.
      </Mui.Typography>
      <Mui.Typography variant="h2">Buckeye Link Pages</Mui.Typography>
      <Mui.List dense>
        <Mui.ListItem>
          <Link to="/">Home</Link>
        </Mui.ListItem>
        <Mui.ListItem>
          <Link to="/search">Search</Link>
        </Mui.ListItem>
      </Mui.List>
      <Mui.Typography variant="h2">Quick Links</Mui.Typography>
      <Mui.List dense>
        {quickLinks.map(({ link, title }) => {
          return (
            <Mui.ListItem>
              <Mui.Link href={link}>{title}</Mui.Link>
            </Mui.ListItem>
          );
        })}
      </Mui.List>
    </Mui.Box>
  );
}

export default NoComponentFound;
