import { Mui } from '@osu/react-ui';

function useTableDisplay(options = {}) {
    const { disableInline } = options ?? {}
    const lgUp = Mui.useMediaQuery((theme) => theme.breakpoints.up("lg"));
    const betweenSmMd = Mui.useMediaQuery((theme) => theme.breakpoints.between("sm", "md")) 

    return disableInline ? false : (lgUp || betweenSmMd);
}

export default useTableDisplay;