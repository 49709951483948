import { setBuildings } from "../../graphql/slices/buildings";
import { getBuildings } from "../enums";

const CONSTANTS = {
  get: getBuildings,
};

const get = `
    ${CONSTANTS.get} {
      address
      buildingAbbreviation
      buildingCode
      campus
      buildingNumber
      city
      departments
      entrances {
        accessible
        buildingNumber
        entranceId
        hasAccessibleRamp
        images
        isButtonActivated
        isFullyAutomated
        latitude
        longitude
        description
      }
      genderInclusiveRestrooms {
        comments
        number
        accessControlled
        spaceID
      }
      lactationRooms {
        comments
        hasWater
        isSignUpRequired
        hasRefrigerator
        images
        number
        spaceID
        accessControlled
      }
      latitude
      longitude
      name
      state
      type
      zip
      wellnessSpaceRooms {
        comments
        hasWater
        isSignUpRequired
        images
        hasRefrigerator
        number
        spaceID
        accessControlled
      }
    }
`;

const transformResponse = (response = {}, { dispatch }) => {
  const buildings = response?.data?.[CONSTANTS.get] ?? []

  dispatch(setBuildings({ buildings }))

  return {
    data: {
      buildings
    }
  }
};


const source = {
  CONSTANTS,
  QUERIES: {
    get,
  },
  transformResponse
};
export default source;
