import React from "react";
import { Mui } from '@osu/react-ui'
import { useLogoutQuery } from "../../services/authorization/api";
import Informational from "../../Common/components/Informational";
import { APPLICATION_NAME, STATUSES } from "../../util/constants";
import { useSelector } from "react-redux";
import { selectAuthenticationStatus } from "../../services/authorization/slices";

const LogOut = () => {
  useLogoutQuery();
  const authStatus = useSelector(selectAuthenticationStatus);
  const isLoading = authStatus === STATUSES.LOADING

  let info = `Signing out of ${APPLICATION_NAME}`
  if(!isLoading){
    info = `Signed out of ${APPLICATION_NAME}`
  }
  return (
    <Informational info={info} childrenFirst={true} infoComponent="h2">
      {isLoading && 
        <Mui.Box className="margin-right-4">
           <Mui.CircularProgress />
        </Mui.Box>
      }
    </Informational>
  );
};

export default LogOut;
