import { useEffect, useRef } from "react";
import { Navigate, Outlet, useLocation  } from "react-router-dom";
import { Mui } from "@osu/react-ui";
import useRoleAuthentication from "../../Authentication/hooks/useRoleAuthentication";
import { authorizationRoutes } from "../paths";
import { useGetAffiliationsQuery } from "../../services/graphql/api";
import Informational from "../../Common/components/Informational";

const ProtectedRoute = ({ force, allowedRoles }) => {
    const { compareUserToRoles, isSuccess, isLoggedIn } = useRoleAuthentication()
    const {isSuccess: affiliationsSuccess, isLoading: affilitationsLoading, isFetching: affilitionsFetching } = useGetAffiliationsQuery() 
    const authorized = compareUserToRoles(allowedRoles)
    const location = useLocation()
        const pathname = location?.pathname
    let constructedPath = `${pathname}${location?.search || ""}${location?.hash || ""}`
    const storedOffPath = useRef()
    const currentPath = !storedOffPath.current ? constructedPath : storedOffPath.current

    useEffect(() => {
      if(constructedPath && storedOffPath.current !== constructedPath) {
        storedOffPath.current = constructedPath
      }
    }, [constructedPath])

    let renderedComponent = false;
    
    if(!force && authorized) {
        renderedComponent = ( <Outlet /> )
    } else if(affilitationsLoading || affilitionsFetching){
        renderedComponent = (
            <Informational info="Loading" childrenFirst>
                <Mui.Box className="margin-right-4">
                    <Mui.CircularProgress />
                </Mui.Box>
            </Informational>
        );
    } else {
        renderedComponent = (
            <Navigate 
                to={(isSuccess && affiliationsSuccess && isLoggedIn)
                    ? authorizationRoutes.unauthorized 
                    : authorizationRoutes.login} 
                state={{ from: location, redirectPath: currentPath }}
                replace
            />
        );
    }

    return renderedComponent
}

export default ProtectedRoute