import { Mui } from "@osu/react-ui";
import useStylingValues from "./useStylingValues";
import useColorTileSx from "../../Common/hooks/useColorTilesSx";

const useMeetingClasses = ({ color }) => {
  const { theme, heightPerHour, xsHeight, isDarkMode } = useStylingValues();
  const colorCode = color ?? theme.palette.secondary.main
  const colorTheming = useColorTileSx({ backgroundColor: colorCode })
  const overline = { lineHeight: "normal" };
  const getContrastText = theme.palette.getContrastText

  const baseZ = theme.zIndex.appBar;
  const zIndex = {
    meeting: baseZ - 4,
    tableHeader: baseZ - 1,
    timeIndicator: baseZ - 3,
    timeCaption: baseZ - 2,
  };
  const bg = colorTheming.backgroundColor
  if (bg && getContrastText) {
    overline.color = getContrastText(bg);
    overline.color = Mui.alpha(overline.color, 1)
  }
  const hoverBg = isDarkMode
    ? Mui.lighten(colorTheming.borderColor, .1)
    : Mui.darken(colorTheming.borderColor, .1);

    const contrastTextHover =  (hoverBg && theme?.palette?.getContrastText)
      ? getContrastText(hoverBg)
      : "";
  return {
    colorTheming,
    meeting: (duration) => {
      return {
        ...colorTheming,
        minWidth: "auto",
        minHeight: "auto",
        height: `calc(${duration}*${heightPerHour})`,
        zIndex: zIndex.meeting,
        display: "flex",
        alignItems: "flex-start",
        textAlign: "left",
        justifyContent: "space-between",
        overflowY: "auto",
        overflowX: "hidden",
        padding: theme.spacing(),
        textTransform: "none",
        "&:hover": {
          border: colorTheming.border,
          borderLeft: colorTheming.borderLeft,
          backgroundColor: hoverBg,
          color: theme.palette.getContrastText(hoverBg),
          "*": {
            color: theme.palette.getContrastText(hoverBg),
          }
        },
        [`&:hover *, &:hover .${Mui.typographyClasses.root}, &:hover .${Mui.svgIconClasses.root}`]:
          {
            fontWeight: "bold",
            color: contrastTextHover,
          },
        [theme.breakpoints.only("xs")]: {
          height: `calc(${duration}*${xsHeight})`,
        },
        "msOverflowStyle": "none",
        "scrollbarWidth": "none",
        "::-webkit-scrollbar": {
          display: "none",
        },
        [`& .${Mui.typographyClasses.root}.${Mui.typographyClasses.overline}`]: {
          lineHeight: overline.lineHeight,
          color: overline.color
        },
        [`&:hover .${Mui.typographyClasses.root}.${Mui.typographyClasses.overline}`]: {
          color: contrastTextHover
        },
        width: "98%",
        margin: "0 auto"
      };
    },
    absolute: (offset) => {
      return {
        position: "absolute",
        left: 0,
        right: 0,
        top: `calc(${offset}*${heightPerHour})`,
        [theme.breakpoints.only("xs")]: {
          top: `calc(${offset}*${xsHeight})`,
        },
      };
    },
    secondary: {
      fontSize: ".875em",
    },
  };
};

export default useMeetingClasses;
