import { setContent } from "../../graphql/slices/content";
import { getArticles } from "../enums";
import { articleSection } from "./shared";

const CONSTANTS = {
  get: getArticles,
};

const get = `
    ${CONSTANTS.get} {
      ${articleSection}
  }
`;

const transformResponse = (response = {}, { dispatch }) => {
    const sections = response?.data?.[CONSTANTS.get] ?? []
    dispatch(setContent({ sections }))
    return {
    data: {
        sections,
        featured: sections.find(({ title }) => title === "University News") ?? sections[0]
      }
    }
};

const source = {
  CONSTANTS,
  QUERIES: {
    get,
  },
  transformResponse
};
  

export default source;
