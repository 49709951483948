import { createSlice } from "@reduxjs/toolkit";
import { isBefore, isAfter } from 'date-fns'
import { sortByStringField } from '../../../util/functions'
import { format } from 'date-fns'
import { STATUSES } from "../../../util/constants";
import { todos } from "../../util/extraReducers";

export const toDoStatuses = {
  keys: {
    incomplete: 'incomplete',
    received: 'received',
    notified: 'notified',
    completed: 'completed',
  },
  labels: {},
  sortBy: {
    date: 'date',
    name: 'name'
  },
  count: 0
}

toDoStatuses.labels = {
  [toDoStatuses.keys.incomplete]: 'Incomplete',
  [toDoStatuses.keys.received]: 'Received',
  [toDoStatuses.keys.notified]: 'Notified',
  [toDoStatuses.keys.completed]: 'Completed',
}

const initialState = {
  incomplete: [],
  all: [],
  groupedList: [],
  filters: [],
  sortBy: toDoStatuses.sortBy.date,
  query: ""
};

initialState.filters = Object.entries(toDoStatuses.labels).map(([key, label]) => {
  return {
    key,
    label,
    checked: key === toDoStatuses.keys.incomplete
  }
})

const checkStatus = (statusName) => {
  if (statusName === toDoStatuses.keys.incomplete) return "dueDate";
  return "statusDate";
};

const sortToDosByDate = (array = []) => {
  const sorted = [...array].sort(function (a, b) {
    const fieldA = checkStatus((a.statusName ?? "").toLowerCase());
    const fieldB = checkStatus((b.statusName ?? "").toLowerCase());
    const constructDate = (item, field) => {
      return item[field]
        ? new Date(item[field])
        : item.statusDate
        ? new Date(item.statusDate)
        : ''
    };
    const dateA = constructDate(a, fieldA)
    const dateB = constructDate(b, fieldB)

    return isAfter(dateA, dateB) ? -1 : isBefore(dateA, dateB) ? 1 : 0;
  });

  return sorted;
};

const formatIncomplete = (arr = []) => {
  let hasDueDate = []
  let noDueDate = []
  arr.forEach(item => {
    if(item.dueDate) {
      hasDueDate.push(item)
    } else {
      noDueDate.push(item)
    }
  })

  return hasDueDate.concat(noDueDate)
}

const validateStatus = (status = '') => [
  toDoStatuses.keys.completed, 
  toDoStatuses.keys.received, 
  toDoStatuses.keys.notified].includes(status)

const initializeStateFields = (todoItems = []) => {
  const sorted = sortToDosByDate(todoItems);

  const incomplete = formatIncomplete(sorted.filter(o => (o.statusName ?? '').toLowerCase() === toDoStatuses.keys.incomplete))

  const todos = {
    [toDoStatuses.keys.incomplete]: incomplete,
    [toDoStatuses.keys.received]: [],
    [toDoStatuses.keys.notified]: [],
    [toDoStatuses.keys.completed]: [],
  }
  sorted.forEach((item) => {
    const { statusName } = item
    const lcStatus = (statusName ?? '').toLowerCase()
    if(lcStatus && validateStatus(lcStatus)) {
      todos[lcStatus].push(item)
    }
  })
  let count = 0
  const grouped = Object.entries(todos).map(([status, items]) => {
    count += (items.length ?? 0)
    return {
      label: toDoStatuses.labels[status],
      key: status,
      items
    }
  })
  return {
    sorted,
    incomplete,
    count,
    grouped
  }
}

const toDoSlice = createSlice({
  name: "todos",
  initialState,
  reducers: {
    setToDoList(state, action) {
      let lastModified = action?.payload?.lastModified && new Date(action?.payload?.lastModified)
      const { sorted, incomplete, count, grouped } = initializeStateFields(action?.payload?.todos)

      state.all = sorted;
      state.incomplete = incomplete;
      state.groupedList = grouped
      lastModified = lastModified && format(lastModified, 'MMM d, yyy h:mm aa')
      state.lastModified = lastModified
      if(incomplete?.length) {
        state.chips = [{
          title: 'Incomplete',
          snippet: incomplete.length
        }]
      }
      state.status = STATUSES.SUCCESS
      state.count = count
    },
    updateFilters(state,action) {
      const { checked, key } = action?.payload ?? {}
      const match = state.filters.find(({ key: filterKey }) => filterKey === key)
      match.checked = Boolean(checked)
    },
    updateSortKey(state, action) {
      const sortKey = toDoStatuses.sortBy[action.payload]
      if(sortKey) {
        state.sortBy = sortKey
        const groupedList = [...state.groupedList]
        const sorted = groupedList.map(group => {
          let sortedItems = group.items ?? []
          if (sortKey === toDoStatuses.sortBy.date) {
            sortedItems = sortToDosByDate(group.items);
            if (group.key === toDoStatuses.keys.incomplete) {
              sortedItems = formatIncomplete(group.items);
            }
          } else if (sortKey === toDoStatuses.sortBy.name) {
            sortedItems = sortByStringField(group.items, 'title')
          }
    
          return {
            ...group,
            items: sortedItems
          }
        })
        state.groupedList = sorted
      }
    },
    setStatus(state, action) {
      state.status = action.payload
    },
    setQuery(state, action) {
      const query = action.payload ?? ""
      state.query = query 
      const allItems = Array.from(state.all) ?? []
      let items = [...allItems]
      
      if(query) {
        const filteredResults = items.filter(({ title, summary }) => {
          const lcTitle = (title ?? '').toLowerCase()
          const lcSummary = (summary ?? '').toLowerCase()
          const lcQuery = (query ?? '').toLowerCase()
          return `${lcTitle} ${lcSummary}`.includes(lcQuery)
        })
        items = filteredResults
      }
      const { incomplete, count, grouped } = initializeStateFields(items)

      state.incomplete = incomplete;
      state.groupedList = grouped
      state.count = count
    }
  },
  extraReducers: todos
});

export const { setToDoList, updateFilters, updateSortKey,setStatus, setQuery } = toDoSlice.actions;
const reducers = {
  todos: toDoSlice.reducer,
};

export const selectIncompleteToDos = (state = {}) => {
  return state.todos.incomplete ?? []
}
export const selectGroupedToDos = (state = {}) => {
  const groupings = state.todos.groupedList ?? []
  const filters = selectFilters(state) ?? []
  const hasFiltersEnabled = filters.find(o => o.checked)

  const filtered = groupings.filter(({ key }) => {
    if(hasFiltersEnabled) {
      const match = filters.find(filter => filter.key === key)
      
      return match?.checked
    }
    return true
  })

  return filtered
}

export const selectSpecificTodos = (state = {}, type = '') => {
  const groupings = state.todos.groupedList ?? []

  return groupings.find(({ key = '' }) => {
    return (key.toLowerCase() === type.toLowerCase())
  })?.items
}

export const selectFilters = (state = {}) => {
  return state.todos.filters
}


export const selectSortKey = (state = {}) => {
  return state.todos.sortBy
}


export const selectToDoModifiedDate = (state = {}) => {
  return state.todos.lastModified
}

export const selectToDoChips = (state = {}) => {
  return state.todos.chips ?? []
}

export const selectToDoStatus = (state = {}) => {
  return state.todos.status ?? ''
}

export const selectToDoQuery = (state = {}) => {
  return state.todos.query
}

export default reducers;
