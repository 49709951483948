import { createSlice } from "@reduxjs/toolkit";
import { COLORS } from "../../../styles/icon_colors";
import { Cache } from 'aws-amplify'
import { KEYS } from "../../../Common/hooks/useColorizeListByKey";

const updateColorIndex = (colorIndex, colors) => {
  return (colorIndex + 1) % colors.length;
};

const initialState = {
  mapping: Cache.getItem('colors') ?? {},
  options: [
    COLORS.purple,
    COLORS.green,
    COLORS.brown,
    COLORS.pink,
    COLORS.orange,
    COLORS.teal,
    COLORS.yellow
  ],
};

const colorSlice = createSlice({
  name: "colors",
  initialState,
  reducers: {
    setColors(state, action) {
      const { items = [], key = KEYS.COLORID } = action.payload;
      const colors = state.options;
      const colorMap = state.mapping;

      let colorIndex = 0;

      items.forEach((item) => {
          let colorMapEntryKey = item[key];
          const existingColor = colorMap[colorMapEntryKey]
          if (!existingColor) {
            colorMap[colorMapEntryKey] = colors[colorIndex];
            colorIndex = updateColorIndex(colorIndex, colors);
          }
      });

      Cache.setItem('colors', colorMap)
    },
  },
});

export const { setColors } = colorSlice.actions;
const reducers = {
  colors: colorSlice.reducer,
};

export const selectColorMapping = (state = {}) => {
  return state.colors.mapping;
};

export default reducers;
