import { Mui } from "@osu/react-ui";
const { paperClasses = {}, accordionClasses = {} } = Mui
let accordionClassName = [
    paperClasses.root,
    paperClasses.elevation,
    paperClasses.rounded,
    paperClasses.elevation1,
    accordionClasses.root,
    accordionClasses.rounded,
    accordionClasses.gutter,
];
accordionClassName = accordionClassName.filter((cls) => !!cls);
accordionClassName = accordionClassName.join(" ");

const accordionHeadingSx = (theme) => ({
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  borderBottom: `solid 1px ${theme.palette.action.focus}`
})

const positioning = (expanded) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  alignItems: expanded ? 'start' : 'center',
  minHeight: '4rem',
  margin: '2px',
})
const accordionSummaryContentSx = (theme, expanded) => ({
  ...positioning(expanded),
  ["." + Mui.accordionSummaryClasses.content]: {
      justifyContent: 'flex-end',
      ...positioning(expanded),
    },
    [`.${Mui.accordionSummaryClasses.content}.${Mui.accordionSummaryClasses.expanded}`]: {
        marginY: 0
    }
})

const accordionSummaryContentHeading = ((theme) => {
    return { 
      flexShrink: 0,
      lineHeight: "unset",
      display: "flex",
      alignItems: "center"
    }
})

export { accordionClassName, accordionHeadingSx, accordionSummaryContentSx, accordionSummaryContentHeading };
