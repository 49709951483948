export function formatInteractionResponse(response) {
    const { message, responseCard = {}, sessionAttributes } = response ?? {}
    let formattedResponse = {
        content: message
    }

    if (sessionAttributes?.appContext) {
        let appContext = sessionAttributes.appContext
        if (!!appContext?.altMessages && !!appContext.altMessages?.markdown) {
            formattedResponse.content = appContext.altMessages.markdown
        }
    }

    if (Array.isArray(responseCard?.genericAttachments) && responseCard.genericAttachments.length > 0) {
        const { genericAttachments } = responseCard

        formattedResponse.suggestions = genericAttachments
            .filter(a => a?.title && (a?.imageUrl || a?.buttons?.length))
            .map(attachment => ({
                title: attachment.title,
                subTitle: attachment.subTitle ?? "",
                imageUrl: attachment.imageUrl ?? "",
                buttons: Array.isArray(attachment.buttons) ? attachment.buttons : []
            }))
            .filter(attachment => attachment.buttons.length > 0)
    }

    formattedResponse.type = "response"
    return formattedResponse
}