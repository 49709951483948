
import { MuiIcons } from '@osu/react-ui';
import Toilet from '../../Assets/Images/Toilet';
import Wellness from '../../Assets/Images/Wellness';
import Lactation from '../../Assets/Images/Lactation';
import DepartmentsIcon from '../../Assets/Images/Departments';

const BUILDING_KEYS = {
    DEPARTMENTS: 'departments',
    LACTATION_ROOMS: 'lactationRooms',
    SANCTUARIES: 'sanctuaries',
    WELLNESS_ROOMS: 'wellnessSpaceRooms',
    GENDER_RESTROOMS: 'genderInclusiveRestrooms',
    ENTRANCES: 'entrances',
}

const BUILDING_LABELS = {
    [BUILDING_KEYS.DEPARTMENTS]: 'Departments',
    [BUILDING_KEYS.LACTATION_ROOMS]: 'Lactation Rooms',
    [BUILDING_KEYS.SANCTUARIES]: 'Sanctuary Spaces',
    [BUILDING_KEYS.WELLNESS_ROOMS]: 'Wellness Spaces',
    [BUILDING_KEYS.GENDER_RESTROOMS]: 'Gender Inclusive Restrooms',
    [BUILDING_KEYS.ENTRANCES]: 'Accessible Entrances',
}

const BUILDING_ICONS = {
    [BUILDING_KEYS.DEPARTMENTS]: DepartmentsIcon,
    [BUILDING_KEYS.LACTATION_ROOMS]: Lactation,
    [BUILDING_KEYS.SANCTUARIES]: MuiIcons.SelfImprovement,
    [BUILDING_KEYS.WELLNESS_ROOMS]: Wellness,
    [BUILDING_KEYS.GENDER_RESTROOMS]: Toilet,
    [BUILDING_KEYS.ENTRANCES]: MuiIcons.AccessibleForward,
}

const BUILDING_COLORS = {
    [BUILDING_KEYS.DEPARTMENTS]: "gray",
    [BUILDING_KEYS.LACTATION_ROOMS]: "purple",
    [BUILDING_KEYS.SANCTUARIES]: "purple",
    [BUILDING_KEYS.WELLNESS_ROOMS]: "purple",
    [BUILDING_KEYS.GENDER_RESTROOMS]: "purple",
    [BUILDING_KEYS.ENTRANCES]: "blue",
}


export {
    BUILDING_KEYS,
    BUILDING_ICONS,
    BUILDING_LABELS,
    BUILDING_COLORS
}