import { Mui } from "@osu/react-ui";

const ListItemAction = ({ url, label, ...rest }) =>
  url && (
    <Mui.Button
      component={Mui.Link}
      variant="outlined"
      href={url}
      size="small"
      {...rest}
    >
      {label ?? "Take Action"}
    </Mui.Button>
  );

export default ListItemAction;
