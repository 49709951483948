import { Auth } from "aws-amplify";
import { selectAuthenticationStatus, setAuthenticationStatus, setAuthenticationUser } from "./slices";
import { STATUSES } from "../../util/constants";
import { graphqlApi } from "../graphql/api";
import { isUnauthenticated } from "../../util/functions";
import { redirectToLogIn } from "../../util/login";

export const buildLoginQuery = {
  queryFn: async (parameters = {}, { dispatch, getState }) => {
    const { redirectPath, doNotRedirect, force } = parameters;
    const status = selectAuthenticationStatus(getState())
    
    if(status === STATUSES.COGNITO_FAILURE && !force) {
      return {
        error: {
          status: STATUSES.ERROR
        }
      }
    }

    try {
      dispatch(setAuthenticationStatus(STATUSES.LOADING))
      const data = await Auth.currentAuthenticatedUser();
      const payload = data?.signInUserSession?.idToken?.payload;
      if (payload?.osuid) {
        dispatch(graphqlApi.endpoints.getAffiliations.initiate());
        dispatch(setAuthenticationUser(payload));
        return {
          data: payload,
        };
      } 
      throw Error("Reached unexpected error")
    } catch (error) {
      const shouldRedirect = !doNotRedirect;
      if (isUnauthenticated(error) && shouldRedirect) {
          redirectToLogIn(redirectPath);
      } 
      return {
        data: {
          status: STATUSES.IDLE
        }
      }
    }
  },
};

export const buildLogoutQuery = {
  queryFn: async (parameters, { dispatch }) => {
    dispatch(setAuthenticationStatus(STATUSES.LOADING))
    await Auth.signOut();
    (await Auth.currentAuthenticatedUser()).signOut({ global: true });
    dispatch(setAuthenticationStatus(STATUSES.CLEARED))
    return {
        data: {
            status: STATUSES.CLEARED
        }
    }
  },
};
