
import { MuiIcons } from "@osu/react-ui";

function ExternalLinkIcon({ Icon, ...rest }) {
  return (
    <Icon
    sx={(theme) => {
      return {
        fontSize: ".8em",
        position: "relative",
        top: "-0.5em",
        left: ".2em",
        color: theme.palette.text.secondary,
      };
    }}
      aria-label="opens in a new window"
      {...rest}
    />
  );
}

ExternalLinkIcon.defaultProps = {
  Icon: MuiIcons.OpenInNew
}

export default ExternalLinkIcon;
