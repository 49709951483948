import { Mui } from "@osu/react-ui";

const useChatbotTheming = () => {
  const theme = Mui.useTheme();
  const darkMode = theme.palette.mode === "dark";
  const red = theme.palette.brand.scarlet.main;
  const redColors = theme.palette.brand.scarlet ?? {};
  const focusStates = darkMode ? theme.palette.brand.gray.darker : redColors;
  const darkenedRedForImprovedContrast = Mui.darken(red, 0.1);

  const accent = darkMode
  ? theme.palette.brand.gray.main
  : darkenedRedForImprovedContrast

  const cbTheme = {
    type: theme.palette.mode ?? "light",
    primary: theme.palette.secondary.main,
    secondary: theme.palette.secondary.main,
    background: darkMode
      ? theme.palette.grey[800]
      : theme.palette.background.paper,
    action: theme.palette.primary.main,
    sendAction: accent,
  };

  return {
    chatbotProps: cbTheme,
    accent: {
        main: accent,
        text: theme.palette.getContrastText(accent),
        hover: focusStates[40],
        focus: focusStates[60],
        active: Mui.darken(focusStates[60],.2)
    },
  };
};

export default useChatbotTheming;
