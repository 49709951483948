import { combineReducers } from 'redux';
import searchReducer from "./services/graphql/slices/search"
import studesntOrgReducer from "./services/graphql/slices/student-orgs"
import blReducer from "./services/graphql/slices/buckeye-link"
import wellness from "./services/graphql/slices/wellness"
import content from "./services/graphql/slices/content"
import dashboard from "./services/graphql/slices/dashboard"
import auth from "./services/authorization/slices"
import buildings from "./services/graphql/slices/buildings"
import dashboardCalendar from "./services/graphql/slices/dashboardCalendar"
import announcements from "./services/graphql/slices/announcements"
import colors from "./services/graphql/slices/colors"
import assignments from "./services/graphql/slices/assignments"
import { graphqlApi } from './services/graphql/api.js';
import { authorizationApi } from './services/authorization/api';
import finalGrades from "./services/graphql/slices/finalGrades"
import enrollments from "./services/graphql/slices/enrollments"
import lex from "./services/graphql/slices/lex_messages"
import hrProfileData from "./services/graphql/slices/hrProfileData.js"
import todos from "./services/graphql/slices/todos.js"

export default combineReducers({ 
    search: searchReducer.getSearchResults,
    studentOrgs: studesntOrgReducer.getStudentOrgs,
    buckeyeLink: blReducer.getBuckeyeLink,
    wellness: wellness.wellness,
    content: content.content,
    dashboard: dashboard.getDashboard,
    authentication: auth,
    [graphqlApi.reducerPath]: graphqlApi.reducer,
    [authorizationApi.reducerPath]: authorizationApi.reducer,
    buildings: buildings.getBuildings,
    dashboardCalendar: dashboardCalendar.getDashboardCalendarDisplay,
    announcements: announcements.getAnnouncements,
    [graphqlApi.reducerPath]: graphqlApi.reducer,
    colors: colors.colors,
    assignments: assignments.getAssignments,
    finalGrades: finalGrades.reducer,
    enrollments: enrollments.getEnrollments,
    lex: lex.getLexMessaging,
    hr: hrProfileData.data,
    todos: todos.todos
})