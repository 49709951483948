import { forwardRef } from "react";
import { Mui, MuiIcons } from "@osu/react-ui";

const Header = forwardRef (({ setAnchorEl }, headingRef) => {
  const theme = Mui.useTheme();
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Mui.Typography
      component="span"
      className="buckeye-chat-heading"
      sx={{
        display: 'flex'
      }}
      >
      <Mui.Button
        onClick={handleClose}
        ref={headingRef}
        type="button"
        color="secondary"
        variant="contained" 
        disableElevation
        id="buckeye-chat-action-header"
        sx={{
          fontSize: theme.typography.subtitle1.fontSize,
          fontWeight: theme.typography.subtitle1.fontWeight,
          borderBottomLeftRadius: 'unset',
          borderBottomRightRadius: 'unset',
          textTransform: 'none'
        }}
      >
        <span className="full-heading">I'm BuckeyeBot. Let's chat!</span>
        <MuiIcons.KeyboardArrowDown
          className="margin-left-auto"
        />
      </Mui.Button>
    </Mui.Typography>
  );
})
export default Header;
