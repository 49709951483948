import { getStatementOfAccount } from "../enums";
import { chips } from "./shared";

const CONSTANTS = {
  get: getStatementOfAccount,
};

const get = `
    ${CONSTANTS.get} {
      ${chips}
      data {
        lastModified
        pastDue
        currentDue
        futureDue
        balanceDue
        transactions {
            category
            type
            title
            transactionDate
            sortOrder
            dueDate
            amount
            amountDue
            termCode
            termName
        }
      }
  }`;

const source = {
  CONSTANTS,
  QUERIES: {
    get,
  },
};

export default source;
