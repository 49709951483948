import { getActiveTerms } from "../enums";
import { formatClasses } from '../../../Calendar/util/transform'
import { setClasses } from "../../graphql/slices/dashboardCalendar";

const CONSTANTS = {
  get: getActiveTerms,
};

const get = `
    ${CONSTANTS.get} {
      terms {
          active
          classes {
            catalogNumber
            courseId
            description
            sections {
              classNumber
              component
              componentName
              meetings {
                buildingNumber
                buildingCode
                dates
                endDate
                endTime
                facilityDescription
                facilityDescriptionShort
                faciltyId
                friday
                instructors {
                  displayName
                  nameN
                  emplid
                  email
                  role
                }
                isInPerson
                isOnline
                isTBA
                monday
                room
                saturday
                startDate
                startTime
                sunday
                thursday
                tuesday
                wednesday
              }
              section
            }
            subject
          }
          endDate
          startDate
          termCode
          termName
        }
      }
`;
const transformResponse =  (response, { dispatch }) => {
  const terms = response?.data?.[getActiveTerms]?.terms
  let termNames = []
  const flattenedClasses = terms?.map(term => {
    if(term.termName) {
        termNames.push(term.termName)
    }
    return term;
  })
  const classes = formatClasses(flattenedClasses ?? []) ?? []

  dispatch(setClasses({ classes: classes?.data, chips: classes?.chips, termNames  }))

  return response
}
const source = {
  CONSTANTS,
  QUERIES: {
    get,
  },
  transformResponse
};

export default source;
