import { getCarmen } from "../enums";

const CONSTANTS = {
  get: getCarmen,
};

const get = `
    ${CONSTANTS.get} {
      }
`;

const source =  {
  CONSTANTS,
  QUERIES: {
      get
  }
}
export default source