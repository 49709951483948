import { Markdown as Md, Mui } from "@osu/react-ui";

const a = ({ href = "", children }) => {
  return (
    <Mui.Link
      underline="always" 
      href={href}
    >
      {children}
    </Mui.Link>
  );
};

const Markdown = ({ markdown, components, ...rest }) => {
  let md = markdown;
  const mdComponents = Object.assign({ a }, components ?? {})
  if(!md) {
    return false
  }
  const telephoneRegex = /(\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4})/gm 
  md = md.replace(telephoneRegex, `[$1](tel:$1)`);
  //Converts a specifically formatted number, such as 614-123-4567, and converts it to a markdown link
  const specialNumbersToLink = [
    "9-1-1",
    "911",
    "9 1 1",
    "844-OHIO-HELP",
    "844 OHIO HELP",
    "844 OHIO HELP",
    "9-8-8",
    "988",
    "9 8 8",
  ];

  specialNumbersToLink.forEach((number) => {
    if (md.includes(number)) {
      md = md.replaceAll(number, `[${number}](tel:${number})`);
    }
  });

  return <Md components={mdComponents} {...rest} markdown={md}></Md>
};

Markdown.defaultProps = {
  markdown: "",
};

export default Markdown;
