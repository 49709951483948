import { createApi } from "@reduxjs/toolkit/query/react";
import { buildLoginQuery, buildLogoutQuery } from "./buildQuery";

export const authorizationEndpoints = {
  login: 'login',
  logout: 'logout'
}

export const authorizationApi = createApi({
  reducerPath: "authorizationApi",
  endpoints: (builder) => ({
    [authorizationEndpoints.login]: builder.query(buildLoginQuery),
    [authorizationEndpoints.logout]: builder.query(buildLogoutQuery),
  }),
});

export const { 
  useLogoutQuery,
  useLazyLoginQuery
} =
authorizationApi;