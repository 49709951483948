const LIST_ITEM_TYPES = {
  MD: "markdown",
  URL: "url",
  EMAIL: "email",
  ADDRESS: "address",
};

const LINK_TYPES = {
  EMAIL: LIST_ITEM_TYPES.EMAIL,
  WEBSITE: LIST_ITEM_TYPES.URL,
  FACEBOOK: "FACEBOOK",
  CONSTITUTION: "CONSTITUTION",
};

export { LIST_ITEM_TYPES, LINK_TYPES };
