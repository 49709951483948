import { mappings } from "../../../styles/icon_colors";
import { WELLNESS, getWellness } from "../enums";
import { article, articleSection } from "./shared";
import { linkablePathValues } from "../../../Routes/paths";
import { fallback } from "../../../Wellness/util/fallback";
import { setWellness } from "../../graphql/slices/wellness";

const CONSTANTS = {
  get: getWellness,
};

const get = `
    ${CONSTANTS.get} {
        articles {
            ${articleSection}
        }
        crisis {
            ${article}
        }
        resources {
            ${article}
        }
    }
`;

const transformResponse = async (response = {},  { dispatch }) => {
  try {
    const wellness = response?.data?.[CONSTANTS.get] ?? [];
    const { articles = [], resources = [], crisis = {} } = wellness;
    const { components = [] } = crisis;
    let filteredComponents = [...components];
    if (Array.isArray(components) && crisis?.title) {
      filteredComponents = filteredComponents.filter(
        ({ text }) => text !== crisis.title
      );
    }

    let featured = { articles: [], resources: [] };
    featured.articles = (articles ?? {}).slice(0, 1);
    featured.articles = featured.articles.map(({ articles = [], ...rest }) => ({
      ...rest,
      articles: (articles ?? []).slice(0, 3),
    }));

    featured.resources = resources.map((resource) => {
      const color = resource?.webImage && mappings?.[WELLNESS]?.[resource.webImage];
      let to = linkablePathValues?.wellness;
      to += `/${encodeURIComponent(resource?.title)}`

      return {
        ...resource,
        color,
        to,
        state: resource,
      };
    });

    let res = {
      data: {
        ...(wellness ?? {}),
        featured,
        resources: featured.resources,
        crisis: {
          ...crisis,
          components: filteredComponents,
        },
      },
    };

    dispatch(setWellness(res.data))

    return res;
  } catch (error) {
    console.error(error);
    return {
      data: {
        crisis: {
          title: "How to Get Help in a Crisis or Emergency",
          subtitle: "Get help now.",
          components: fallback,
        },
      },
    };
  }
};

const source = {
  CONSTANTS,
  QUERIES: {
    get,
  },
  transformResponse,
};

export default source;
