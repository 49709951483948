import { Fragment } from "react";
import { Mui } from "@osu/react-ui";
import { format } from "date-fns";
import DashAction from "../../Dashboard/components/DashAction";
import { buildResponsiveStyle } from "../../util/functions";

function BuckeyeLearn({ data = [] }) {
    const minScrollHeight = (data?.length > 3 ? "300px" : "50px");
    return (
        <Fragment>
            <Mui.Box marginLeft={2} style={buildResponsiveStyle(minScrollHeight)}>
                {!data?.length ?
                    (<Mui.Box marginBottom={2}>
                        You have completed all BuckeyeLearn training(s) assigned to you. 
                        Visit <Mui.Link underline="hover" href="https://buckeyeLearn.osu.edu">BuckeyeLearn</Mui.Link> to review your completed trainings.
                    </Mui.Box>) :
                    (<Mui.Box component="ul" marginTop="0rem" paddingLeft="0rem">
                        {data.map((item, index) => {
                            const { dueDate, name, url } = item;
                            const lastItem = (index === (data.length - 1));
                            const formattedDueDate = ((typeof dueDate === "string" && dueDate.length > 0) ? format(new Date(dueDate), "MMMM d yyyy") : null);
                            return (
                                <Mui.Box key={url} component="li" marginBottom={2}>
                                    <Mui.Box>
                                        <DashAction href={url}>{name}</DashAction>
                                        {formattedDueDate && 
                                            <time dateTime={dueDate}>
                                                <Mui.Typography color="secondary" variant="body2">Due {formattedDueDate}</Mui.Typography>
                                            </time>
                                        }
                                    </Mui.Box>
                                    {!lastItem && <Mui.Divider className="margin-top-1" />}
                                </Mui.Box>
                            );
                        })}
                    </Mui.Box>)
                }
            </Mui.Box>
            <Mui.Divider className="margin-bottom-2" />
            <DashAction href="https://ohiostate.csod.com/samldefault.aspx?returnurl=%252fDeepLink%252fProcessRedirect.aspx%253fmodule%253dtranscript">BuckeyeLearn Transcript</DashAction>
        </Fragment>
    );          
}

export default BuckeyeLearn;