import { createSlice } from '@reduxjs/toolkit'
import { STATUSES } from '../../util/constants'
import { transformUser } from '../../Authentication/transform'
import {  graphqlApi } from '../graphql/api'

const initialState = { 
  status: STATUSES.IDLE,
  user: {
  },
  roles: undefined
}

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    status(state, action) {
      state.status = action?.payload
      if(action?.payload === STATUSES.CLEARED) {
        state.user = {}
      }
    },
    user(state, action) {
      const newOSUId = action?.payload?.osuid 
      const existingOSUId = state?.user?.osuid
      let allowUserToBeSet = newOSUId 
        && state.status !== STATUSES.CLEARED

      if(allowUserToBeSet && (newOSUId !== existingOSUId)) {
        state.user = transformUser(action?.payload)
        state.status = STATUSES.SUCCESS
      }
    },
    setRoles(state, action) {
      if(action?.payload?.roles && state.user) {
        state.roles = action?.payload?.roles ?? []
      }
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      graphqlApi.endpoints.getAffiliations.matchPending,
      (state) => {
        state.status = STATUSES.LOADING;
      }
    );
    builder.addMatcher(graphqlApi.endpoints.getAffiliations.matchFulfilled,
      (state) => {
        state.status = STATUSES.SUCCESS;
      }
    );
  }
})

const selectAuthentication = (state)  => {
  const {user = {}, status,roles} = state?.authentication || {}

  return {
    user,
    status,
    cognitoGroups: user.cognitoGroups ?? [],
    isLoading: STATUSES.LOADING === status,
    isError: STATUSES.ERROR === status,
    isSuccess: STATUSES.SUCCESS === status,
    isCleared: STATUSES.CLEARED === status,
    isIdle: STATUSES.IDLE === status,
    roles: roles?.length ? roles : user.cognitoGroups,
    isLoggedIn: !!user?.osuid
  }
  
}

const selectAuthenticationStatus = (state)  => {
  return state?.authentication?.status
}

const { status: setAuthenticationStatus, user: setAuthenticationUser, setRoles } = authenticationSlice.actions
const { reducer } = authenticationSlice
export { 
    setAuthenticationStatus,
    setAuthenticationUser,
    selectAuthentication,
    setRoles,
    selectAuthenticationStatus,
    reducer as default
}