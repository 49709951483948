import { Mui, MuiIcons } from "@osu/react-ui";
import React from "react";
import { getTime } from "../util/time";

const SubmissionSection = ({ assignment, alignment }) => {
  const lockText = assignment.lockExplanation ? "Locked" : "";
  const isSubmitted = assignment.submittedAt ? true : false;
  const submissionString = isSubmitted ? "Submitted" : "Not Yet Submitted";
  const points = assignment.pointsPossible ? assignment.pointsPossible + " points" : "";
  const lockAt = assignment.lockAt
    ? new Date(assignment.lockAt).getMonth() + 1 + "/" + new Date(assignment.lockAt).getDate() + " at " + getTime(new Date(assignment.lockAt))
    : "";
  const lockAtLeadText = assignment.lockAt ? "Available until " : "";
  return (
    <>
      <dd style={{ margin: "1rem", textAlign: alignment }}>
        <Mui.Typography
          sx={{
            fontSize: "1rem",
            mr: "1rem",
            fontWeight: "bold",
            mt: "1rem"
          }}>
          {submissionString}
        </Mui.Typography>
        <Mui.Typography
          sx={{
            fontSize: ".8rem",
            mr: "1rem",
            mt: "1rem"
          }}>
          {lockAtLeadText}
          <time dateTime={lockAt}>{lockAt}</time>
        </Mui.Typography>
      </dd>
      <dd style={{ margin: "1rem", textAlign: alignment }}>
        <Mui.Typography
          sx={{
            fontSize: "1rem",
            mr: "1rem",
            mt: "1rem",
            fontWeight: "bold"
          }}>
          {points}
        </Mui.Typography>
      </dd>

      {assignment.lockedForUser && (
        <dd style={{ margin: "1rem",  }}>

            <Mui.Typography
              sx={{
                fontSize: "1rem",
                textAlign: alignment,
                mr: "1rem",
              }}>
              <MuiIcons.Lock sx={{ maxHeight: "1rem"}} />
              {lockText}
            </Mui.Typography>{" "}
        </dd>
      )}
    </>
  );
};

export default SubmissionSection;
