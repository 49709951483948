
import { Mui } from "@osu/react-ui";

const Buckeye = Mui.createSvgIcon(
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g transform="translate(0.054530, 0.637000)">
      <path
        d="M15.8752432,11.8504733 C13.7889905,10.3563478 11.2513284,11.5518123 9.89099432,10.5980473 C11.6636393,9.56626764 12.7375249,11.2814042 14.9785309,10.1490987 C17.5186537,8.86568554 16.5092942,6.80723006 18.7272421,5.68668149 C18.7272421,5.68668149 15.8621192,4.33382073 13.6422574,5.45537183 C11.3545884,6.61128216 11.188534,9.45033939 9.71218047,10.2099793 C9.59816613,8.1475137 11.5833462,7.93270012 11.5833462,5.3950381 C11.5833462,2.51478624 9.31827962,2.51478624 9.31827962,0 C9.31827962,0 6.84860697,2.00094705 6.84860697,4.51801175 C6.84860697,7.11327334 9.28073053,8.52929312 9.29066464,10.210435 C9.29139375,10.2169058 9.29039122,10.2215539 9.28711023,10.2232855 C9.2841938,10.224926 9.28000143,10.2245614 9.27198123,10.2207336 C7.37693719,9.44760524 8.03705377,7.52968541 5.77335426,6.44595682 C3.20698359,5.21731791 2.2407326,7.29709981 0,6.22439898 C0,6.22439898 0.729473021,9.34534777 2.97211953,10.4190511 C5.28138832,11.5244707 7.57735088,9.90174867 9.07958774,10.6023308 C7.50936151,11.9297639 6.15959947,10.4288941 4.14507277,11.9374195 C1.86177847,13.64727 3.2065279,15.4977473 1.21287193,16.990688 C1.21287193,16.990688 4.26519339,17.8202312 6.26058099,16.3260145 C8.32158835,14.782583 7.99895785,11.9319512 9.33377318,10.9304296 C9.34443639,10.9222271 9.35601098,10.9150272 9.36813241,10.9086475 C9.32110492,13.1609547 9.00139085,19.8944536 6.3206413,23 L7.94819367,23 C8.17877423,22.473128 9.98113036,18.5187171 9.65895555,10.9370827 C11.3666187,12.0322948 10.4023728,13.813051 12.439593,15.2720882 C14.7579756,16.9326328 16.0638089,15.0537202 18.0881785,16.5036436 C18.0881785,16.5036436 17.9012534,13.3015815 15.8752432,11.8504733"
        id="Fill-1"
        fill="#5D9446"
      ></path>
      <polyline
        id="Fill-4"
        fill="#5D9446"
        points="14.4882966 22.5893297 14.3887733 22.3407037 14.3219688 22.3407037 14.3219688 22.6825644 14.3654418 22.6825644 14.3654418 22.3997615 14.4821903 22.6825644 14.4945852 22.6825644 14.6112425 22.3997615 14.6112425 22.6825644 14.6545334 22.6825644 14.6545334 22.3407037 14.5878199 22.3407037 14.4882966 22.5893297"
      ></polyline>
      <polyline
        id="Fill-5"
        fill="#5D9446"
        points="14.0297785 22.3810781 14.126112 22.3810781 14.126112 22.6825644 14.1694028 22.6825644 14.1694028 22.3810781 14.2661008 22.3810781 14.2661008 22.3407037 14.0297785 22.3407037 14.0297785 22.3810781"
      ></polyline>
      <path
        d="M14.1664864,19.4764011 C14.0479151,19.121143 13.5177621,18.5124285 12.746821,18.3783637 C12.5662756,18.3468298 12.3616695,18.3710726 11.9882748,18.3869307 C11.6140599,18.4032445 11.1050511,18.4129964 10.5535716,18.5907165 C10.0025479,18.7676165 9.49344793,19.4897984 9.58640926,20.3763032 C9.64975056,20.9804607 9.89655377,21.5486185 10.24926,22.0398553 C10.6000523,22.5284491 11.135947,22.9057627 11.7504032,22.9403042 C12.0695704,22.9581674 12.3825402,22.8785123 12.675733,22.7574803 C13.0165,22.6169446 13.417692,22.4320245 13.7064189,22.200806 C14.9058024,21.239841 14.3443888,20.0073743 14.1664864,19.4764011"
        id="Fill-6"
        fill="#735238"
      ></path>
      <path
        d="M13.4127705,20.1679604 C13.3564469,20.2844355 13.2193745,20.3114125 13.074373,20.3300959 C12.9292804,20.3485971 12.2651538,20.4020043 12.0735805,20.3331946 C11.8819161,20.2648407 11.7072947,20.0021794 11.653614,19.711174 C11.5999334,19.4205331 11.4359752,19.1156746 11.635842,19.0149665 C11.8353443,18.9144407 12.445517,18.9434228 12.6780114,19.0394828 C12.910597,19.1349049 13.044753,19.1753704 13.2026961,19.4203508 C13.3606392,19.6653313 13.5280608,19.8848841 13.5153014,19.9777543 C13.5022686,20.0705333 13.4127705,20.1679604 13.4127705,20.1679604"
        id="Fill-7"
        fill="#D0B39C"
      ></path>
    </g>
  </g>,
  "buckeye with nut"
);

const BuckeyeIcon = (props) => {
  return <Buckeye {...props} viewBox="0 0 19.54 24" />;
};

export default BuckeyeIcon;
