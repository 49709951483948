
import { Mui } from "@osu/react-ui";

function Heading(props = {}) {
  const { children, Component, headingSize="2", ...rest } = props;

  let prps = { ...rest}
  if(Component) {
    prps.component = (p = {}) => {
      return <Component {...p} component={`h${headingSize}`}  />
    }
  }

  return (
    <Mui.Typography  variant={`h${headingSize}`} {...prps}>
      {children}
    </Mui.Typography>
  );
}

export default Heading;
