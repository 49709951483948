import { createFeaturedLinks } from "../../../Search/util/functions";
import { RESOURCE_TYPES } from "../../../util/constants";
import {  search, updateReduxStore } from "../../graphql/slices/buckeye-link";
import { getBuckeyeLinkData } from "../enums";

const CONSTANTS = {
  get: getBuckeyeLinkData,
};

const get = `
    ${CONSTANTS.get} {
        items {
            title
            link
            snippet
            category
            subcategory
            tags
            identifier
        }
        featured {
            ${RESOURCE_TYPES.ACADEMIC}
            ${RESOURCE_TYPES.FINANCIAL}
            ${RESOURCE_TYPES.SOCIAL}
            ${RESOURCE_TYPES.WELLNESS}
            ${RESOURCE_TYPES.STUDENTS}
            ${RESOURCE_TYPES.STAFF_FAC}
            ${RESOURCE_TYPES.PARENTS_FAM}
          }
    }
`;

async function transformResponse(response = {}, { getState, dispatch }) {
  let res = {
    data: {
      items: [],
      featured: [],
      quickLinks: [],
    }
  }
  const responseObj = response?.data?.[CONSTANTS.get];
  if (responseObj?.items?.length) {
    res.data.featured = createFeaturedLinks(
      responseObj?.featured ?? {},
      responseObj?.items ?? []
    );

    res.data.quickLinks = responseObj.items.filter(
      ({ tags = [], link, title }) =>
        title && link && tags?.includes("Quick Links")
        );
    res.data.items = responseObj.items
  }
  dispatch(updateReduxStore(res))
  const query = getState()?.buckeyeLink?.query
  dispatch(search({ query }))

  return res
}

const source = {
  CONSTANTS,
  QUERIES: {
    get,
  },
  transformResponse,
};

export default source;
