import { Fragment } from "react";
import { Mui } from '@osu/react-ui'
import DashAction from "../../../Dashboard/components/DashAction";
import Widget from './Widget'
import { HR, WORKDAY } from "../../../util/constants";
import { useSelector } from "react-redux";
import { selectBalances } from "../../../services/graphql/slices/hrProfileData";

function HRLeave() {
  const balances = useSelector(selectBalances)
  const theme = Mui.useTheme()
  const sm = Mui.useMediaQuery(theme.breakpoints.down("sm"));
  const mdLg = Mui.useMediaQuery(theme.breakpoints.between("md", "lg"));

  return (
    <Fragment>
      {
        balances?.length 
         ? <Mui.Grid spacing={2} container>
          {balances.map((balance, idx) => <Widget index={idx} key={idx + 'hr-data' + (balance?.description ?? '')} {...balance ?? {}} />)}
        </Mui.Grid>
         : <Mui.Typography>
          No balances available to show.
         </Mui.Typography>
      }
      <Mui.Divider className="margin-y-2" />
      <Mui.Box display="flex" flexDirection={(sm || mdLg) ? "column" : "row"} className="margin-top-2">
        <DashAction href={WORKDAY.CALENDAR.href}>{WORKDAY.CALENDAR.label}</DashAction>
        <DashAction href={HR.ACCRUAL.href}>{HR.ACCRUAL.label}</DashAction>
      </Mui.Box>
    </Fragment>
  );
}

export default HRLeave;