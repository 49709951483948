import React, { Fragment, Suspense, useEffect } from "react";
import { useNavigate, Route, Routes as Switch } from "react-router-dom";
import { Mui } from "@osu/react-ui";
import NoComponentFound from "../views/NoComponentFound";
import PATHS, { authenticatedPaths, authorizationRoutes } from "../paths";
import withErrorBoundary from "../../Common/hocs/withErrorBoundary";
import { convertPathsToViews } from "../util";
import ProtectedRoute from "./ProtectedRoute";
import { Hub } from "aws-amplify";
import { CHANNELS, EVENTS, handleOAuthStateRedirect } from "../../util/hub"
import LogIn from "../../Authentication/components/LogIn";
import Informational from "../../Common/components/Informational";

function Routes(props = {}) {
  const { setBreadcrumbLinks } = props
  const navigate = useNavigate()

  useEffect(() => {
    let isMounted = true;
    const onAuthEvent = (payload) => {
      const path = handleOAuthStateRedirect(payload)
      if (!isMounted) {
        return
      }
      if(payload?.event === EVENTS.customOAuthState && path){
        navigate(path)
      }
    };

    const removeListener = Hub.listen(CHANNELS.auth, (data) => {
      onAuthEvent(data?.payload);
    });

    return () => {
      removeListener()
      isMounted = false;
    };
  }, [navigate]);

  const LoadingFallBack = () => {
    return (
    <Informational info="Loading" childrenFirst>
      <Mui.Box className="margin-right-4">
          <Mui.CircularProgress />
      </Mui.Box>
    </Informational>
  );}

  return (
    <Fragment>
      <Suspense fallback={<LoadingFallBack/>}>
        <Switch>
          <Route path={authorizationRoutes.login} element={ <LogIn />} />
          {authenticatedPaths.map(({ path, Component }, index) => (
            <Route
              key={`route${encodeURIComponent(path)}#${index}`}
              path={path}
              element={
                <Component />
              }
            />
          ))}
          {
            PATHS.map(({ allowedRoles, paths }, index) => {
              if (allowedRoles?.length) {
                return (
                  <Route
                    key={`mapped-route-${index}`}
                    element={<ProtectedRoute allowedRoles={allowedRoles} />}
                  >
                    {convertPathsToViews(paths, { setBreadcrumbLinks })}
                  </Route>
                );
              }
              return convertPathsToViews(paths, { setBreadcrumbLinks })
            })
          }
          <Route path="*" element={<NoComponentFound />} />
        </Switch>
      </Suspense>
    </Fragment>
  );
}

export default withErrorBoundary(Routes);
