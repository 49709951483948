import { Fragment } from "react";
import { Mui } from "@osu/react-ui";
import Records from "./Records";

function Individual({ index, positionTitle = "", records = [] }) {
  if (!positionTitle) {
    return false;
  }
  return (
    <Fragment>
      <Mui.Typography variant="h4" className="padding-bottom-1">
        {positionTitle}
      </Mui.Typography>
      <Mui.Grid spacing={2} container>
        <Records index={index} items={records} />
      </Mui.Grid>
    </Fragment>
  );
}

export default Individual;
