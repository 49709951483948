import AuthButton from "./AuthButton";
import { APPLICATION_NAME } from "../../util/constants";
import InternalLink from "./Link";
import AppBar from "./AppBar";
import Toolbar from "./Toolbar";

const AppLink = (props = {}) => (
  <InternalLink to="/" disableHighlightCurrent {...props}>
    {APPLICATION_NAME}
  </InternalLink>
);

export default function Navbar() {
  return (
    <AppBar>
      <Toolbar>
        <AppLink />
        <AuthButton className="margin-left-auto" />
      </Toolbar>
    </AppBar>
  );
}
