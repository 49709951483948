import { Fragment } from "react";
import { formatLinks } from "../util";
import { Mui } from "@osu/react-ui";
import { useStyles } from "../hooks/useStyles";

export const Links = ({ links = [] }) => {
  const formattedLinks = formatLinks({ links });
  const { link } = useStyles()
  return (
    <Fragment>
      <Mui.Divider className="margin-y-1" />
      {formattedLinks.map(({ href, label }, idx) => {
        return (
          href && (
            <Mui.Link
              key={"emergency-alert" + idx}
              className="display-inline-block margin-right-2"
              underline="always"
              href={href}
              sx={link}
            >
              {label ?? href}
            </Mui.Link>
          )
        );
      })}
    </Fragment>
  );
};
