import React, { Fragment } from "react";
import { INTENTS } from "../util/constants";
import Bot, { IntentsProvider, ThemeProvider } from "@osu/chatbot";
import { Mui, MuiIcons } from "@osu/react-ui";
import useCustomizedInteractions from "../hooks/useCustomizedInteractions";
import useChatbotTheming from "../hooks/useChatbotTheming";
import Markdown from "./Markdown";

export const intents = {
  welcome: INTENTS.WELCOME,
  positive: INTENTS.POSITIVE,
  negative: INTENTS.NEGATIVE,
  help: INTENTS.HELP,
};

const A11yIcon = ({ Icon, label }) => <Fragment>
  <Icon />
  <Mui.Typography variant="srOnly">{label}</Mui.Typography>
</Fragment>

const Positive = () => <A11yIcon Icon={MuiIcons.ThumbUpAlt} label="agree" />
const Negative = () => <A11yIcon Icon={MuiIcons.ThumbDownAlt} label="disagree" />

export const Icons = {
  positive: Positive,
  negative: Negative,
  help: MuiIcons.QuestionMark,
  send: MuiIcons.Send,
};

const DataBot = ({ inputProps }) => {
  const { isLoading, handleInput, messages } = useCustomizedInteractions();
  const { chatbotProps: chatbotTheme } = useChatbotTheming();

  return (
    <ThemeProvider theme={chatbotTheme}>
      <IntentsProvider intents={intents} Icons={Icons}>
        <Bot
          className="buckeye-chat-container dark-mode-enabled"
          loading={isLoading}
          messages={messages}
          handleInput={handleInput}
          ResponseProps={{
            Markdown
          }}
          inputProps={inputProps}
        />
      </IntentsProvider>
    </ThemeProvider>
  );
};

Bot.defaultProps = {
  className: "",
  headingStart: 1,
  component: "div",
};

export default DataBot;
