import { createSlice } from "@reduxjs/toolkit";

let initialState = {
};

const finalGrades = createSlice({
  name: "finalGrades",
  initialState,
  reducers: {
    setInfo(state, action) {
      const { termCode } = action.payload?.term ?? {}
      if(termCode) {
        state[termCode] = action.payload.term
        state[termCode].pending = action.payload.pending
      }
    }
  }
});

export const { setInfo } = finalGrades.actions

const reducers = {
  reducer: finalGrades.reducer,
};

export const selectFinalGradesForTerm = (state = {}, { termCode }) => {
  const finalGrades = state?.finalGrades ?? {}
  return finalGrades[termCode] ?? {}
}

export const selectGpaForTerm = (state = {}, { termCode }) => {
  const term = selectFinalGradesForTerm(state, { termCode })
  return term?.gpa ??{}
}

export const selectTermIsPending = (state = {}, { termCode }) => {
  const term = selectFinalGradesForTerm(state, { termCode })
  return term?.pending ??{}
}

export default reducers