import { Mui } from "@osu/react-ui";
import { NAVBAR_WRAPPER_ID } from "../../util/constants";
import usePaperStyleProps from "../../Common/hooks/usePaperStyleProps";
import { useClasses } from "../hooks/useClasses";

export default function AppBar({ children }) {
  const { zIndex, dashboard } = usePaperStyleProps();
  const classes = useClasses();

  return (
    <Mui.AppBar
      elevation={dashboard.elevation - 1 || 1}
      id={NAVBAR_WRAPPER_ID}
      color="inherit"
      sx={[
        classes.appBar(zIndex.navbar),
        {
          clipPath: `inset(.5px -2rem -2rem -2rem)`,
        },
      ]}
      position="sticky"
    >
      {children}
    </Mui.AppBar>
  );
}
