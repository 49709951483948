import { Mui, omitReduxProps } from "@osu/react-ui";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { STATUSES } from "../../util/constants";
import { useSelector } from "react-redux";
import { selectAuthenticationStatus } from "../../services/authorization/slices";
import { useState } from "react";
import { authorizationRoutes } from "../../Routes/paths";

function AuthButton(props = {}) {
  const { name, ...rest } = props;
  const remainingProps = omitReduxProps(rest);
  const status = useSelector(selectAuthenticationStatus)
  const [inOrOut, setInOrOut] = useState("In")
  const { pathname } = useLocation()
  const onAuthPage = [authorizationRoutes.login, authorizationRoutes.unauthorized].includes(pathname)

  if(onAuthPage) {
    return false
  }

  if (status === STATUSES.LOADING) {
    return (
        <Mui.Box {...remainingProps} sx={{display: "flex", flexDirection: "row"}}>
          <Mui.CircularProgress className="margin-right-1"/>
          <Mui.Typography variant="body1" className="margin-top-1">
            {Boolean(inOrOut) && `Signing ${inOrOut}`}
          </Mui.Typography>
        </Mui.Box>
    );
  }

  if (status === STATUSES.SUCCESS) {
    return (
      <Mui.Button onClick={() => setInOrOut("Out")} variant="text" color="secondary" component={RouterLink} to="/logout" {...remainingProps}>
        Sign Out
      </Mui.Button>
    );
  }

  return (
    <Mui.Button onClick={() => setInOrOut("In")} variant="outlined" component={RouterLink} to="/login" {...remainingProps}>
      Sign In
    </Mui.Button>
  );
}

export default AuthButton;