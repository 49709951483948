import React, { Fragment } from "react";
import { Mui } from "@osu/react-ui";
import { DISPLAY } from "./util/enums";
import { dashCalComparators, selectDashboardCalendarMeetingData, selectDashboardCalendarViewBy } from "../services/graphql/slices/dashboardCalendar";
import { useSelector } from "react-redux";
import { mappings } from "../styles/icon_colors";
import { CLASSES } from "../services/data-sources/enums";

const colorOfClasses = mappings[CLASSES] ?? 'blue'

const WeekDay = ({ viewingDay, month, dayOfMonth, year, dayOfWeekFull, isSameDay, dayOfWeek }) => {
  let dayToRender = []
  if(viewingDay) {
    let remainingString = ""
    if(month) remainingString += (month + " ")
    if(dayOfMonth) remainingString += (dayOfMonth + " ")
    if(year) remainingString += (year)
    if(dayOfWeekFull) dayToRender.push(<Mui.Typography sx={{ 
        fontWeight: "bold" 
      }} component="span">
       {`${dayOfWeekFull}${remainingString ? "," : ""}`}&nbsp;
    </Mui.Typography>)
    if(remainingString) {
      dayToRender.push(<Fragment>
        {remainingString}
      </Fragment>)
    }
  }
  if(!dayToRender.length) {
    return <Mui.Typography sx={isSameDay ? { fontWeight: "bold"} : {}} variant="overline" component="span">
        {dayOfWeek}
    </Mui.Typography>
  }
  return <Mui.Typography className="display-flex align-items-center" variant="subtitle1" component="span">
    {dayToRender}
  </Mui.Typography> 
}

export const createHeadingCellBg = (theme) => {
  let bg = theme.palette.background.paper
  if(theme.palette.mode === "dark") {
    bg = Mui.lighten(bg, theme.palette.tonalOffset)
  }
  return {
    backgroundColor: bg
  }
}

export const headingCell = (theme) => (isSameDay) => {
  const darkMode = theme.palette.mode === "dark"
  if(!isSameDay) {
    return {
      backgroundColor: darkMode ? "var(--elevation5)" : undefined

    }
  }
  let highlightedColor = theme.palette.colors[colorOfClasses]
  if(theme.palette.mode !== "dark") {
    highlightedColor = Mui.lighten(highlightedColor, .8)
  }
  const color = highlightedColor && theme.palette.getContrastText(highlightedColor)
  return {
    backgroundColor: highlightedColor,
    color,
    [`& .${Mui.typographyClasses.root}.${Mui.typographyClasses.overline}`]: {
      color
    }
  }
}

const dayOfMonthSx = (theme) => (isSameDay) => {
  let bg = isSameDay ? theme.palette.colors[colorOfClasses] : theme.palette.rows.even
  if(theme.palette.mode === "dark") {
      bg = Mui.lighten(bg, .8)
    }
    
    const color = isSameDay ? theme.palette.getContrastText(bg) : theme.palette.text.primary
return {
    backgroundColor: bg,
    color
  }
}

function Columns() {
  const { meetings: events } = useSelector(selectDashboardCalendarMeetingData, (a,b) => {
    const meetingHash = dashCalComparators.meetings(a?.meetings, b?.meetings)
    return meetingHash
  })
  const viewBy = useSelector(selectDashboardCalendarViewBy)

  let cls = "display-flex"
  const viewingDay = viewBy === DISPLAY.VIEW_BY_OPTIONS.DAY
  if(!viewingDay) cls += " flex-direction-column align-items-center"
  return events.map(({ dayOfWeekFull, dayOfWeek, month, dayOfMonth, year, isSameDay }, index) => {

    return <Mui.TableCell
      className={cls}
      component="th"
      scope="col"
      sx={(theme) => headingCell(theme)(isSameDay)}
      key={dayOfWeek + "-" + dayOfMonth + "-" + index}
    >
      <WeekDay 
        viewingDay={viewingDay}
        month={month} 
        dayOfMonth={dayOfMonth} 
        year={year} 
        dayOfWeekFull={dayOfWeekFull}
        isSameDay={isSameDay} 
        dayOfWeek={dayOfWeek}
       />
      {!viewingDay && <Mui.Avatar sx={(theme) => dayOfMonthSx(theme)(isSameDay)}>{dayOfMonth}</Mui.Avatar>}
    </Mui.TableCell>
  });
}

Columns.defaultProps = {
  events: [],
};

export default Columns