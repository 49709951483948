import { appendMessage } from "../../graphql/slices/lex_messages";
import { sendTextMessageToLex } from "../enums";
import { formatInteractionResponse } from "./transform";

const CONSTANTS = {
  send: sendTextMessageToLex,
};


const send = `
    ${CONSTANTS.send}(message: $message, sessionId: $sessionId) {
      responseCard {
        genericAttachments {
          attachmentLinkUrl
          subTitle
          title
          imageUrl
          buttons {
            value
            text
          }
        }
      }
      message
      sessionId
      sessionAttributes {
        appContext {
          altMessages {
            markdown
          }
        }
      }
    }
`;

const addError = ({ dispatch, input }) => {
  dispatch(appendMessage({
    error: true,
    input
  }))
}

const transformResponse = async (response = {}, { dispatch, parameters }) => {
  try {
    const res = response?.data?.[CONSTANTS.send]
    const appContext = res?.sessionAttributes?.appContext
    const formattedResponse = formatInteractionResponse(res)
    dispatch(appendMessage({
      message: formattedResponse,
      input: parameters?.message
    }))

    if(!res || !formattedResponse?.content) {
      throw new Error('Unexpected response, make sure to provide a message')
    }
    return {
      data: {
        sessionId: res?.sessionId,
        message: res?.message ?? "",
        responseCard: res?.responseCard ?? {},
        sessionAttributes: {
          appContext: appContext && JSON.stringify(appContext)
        }
      }
    }
  } 
   catch (error) {
    console.error(error)
    addError({
      dispatch,
      input: parameters?.message
    })
    return error?.error ? error : {
      error
    }
  }
}
const source = {
  CONSTANTS,
  QUERIES: {
    send,
  },
  transformResponse
};


export default source;
