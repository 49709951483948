import { format } from "date-fns";

const checkToday = (date) => {
  const today = format(new Date(), "yyyy-MM-dd");

  return date && today === date;
};

const createDateTime = (date, time) => {
  let dateString = date + " ";
  dateString += time ?? "00:00:00";
  const parsedDate =
    dateString &&
    new Date(dateString);
  const ISO = parsedDate && parsedDate.toISOString();

  return {
    epoch: parsedDate && parsedDate.getTime(),
    ISO,
  };
};

const filterByDate = (dates = [], classStart = "") => {
  let today = [];
  dates.forEach((date) => {
    const { epoch } = createDateTime(date, classStart ?? "00:00:00") ?? {};
    if (classStart && epoch) {
      const isToday = checkToday(date);

      if (isToday) {
        today.push(date);
      }
    }
  });

  return {
    datesWithinRange: dates,
    isToday: today?.length,
  };
};

function sortClasses(classes = []) {
  let newClasses = [];
  const dateSortKeys = classes.map((cl) => cl.dateSortKey).sort();
  dateSortKeys.forEach((dateSortKey) => {
    const match = classes.find((c) => c.dateSortKey === dateSortKey);
    newClasses.push(match);
  });

  if (newClasses.length === classes.length) {
    return newClasses;
  }
  return classes;
}

export const formatClasses = (classes) => {
  let relevantClasses = [];
  let today = [];
  let chips = [];
  const mappableClasses = classes.map(cls => {
    return (cls?.classes ?? []).map(c => {
      c.termCode = cls.termCode
      c.termName = cls.termName
      return c
    })
  }).flat(1)

  mappableClasses.forEach((currentClass) => {
    const { sections, ...restOfClass } = currentClass;
    let classData = restOfClass;
    sections.forEach(({ meetings, ...restOfSection }) => {
      Object.assign(classData, restOfSection);
      meetings.forEach((meeting) => {
        const { dates, startTime, endTime, buildingCode, ...restOfMeeting } =
          meeting;
        let todayTitle = [classData.subject, classData.catalogNumber]
          .filter((o) => !!o)
          .join(" ");
        if (todayTitle && classData.description) {
          todayTitle += ` - ${classData.description}`;
        }
        Object.assign(classData, restOfMeeting);
        classData.buildingNumber = buildingCode;
        const { datesWithinRange, isToday } = filterByDate(dates, startTime);
        if (isToday) {
          const splitStart = startTime ? startTime.split(":") : [];
          const [hour, minute, seconds] = splitStart ?? [];
          const secondsTimestamp = hour * 60 + minute + seconds || 0;
          if (todayTitle) {
            today.push({
              title: todayTitle,
              secondsTimestamp,
            });
          }
        }
        if (datesWithinRange?.length) {
          datesWithinRange.forEach((date, index) => {
            const start = startTime && createDateTime(date, startTime);
            const end = endTime && createDateTime(date, endTime);
            const now = new Date().getTime();
            const isAfterNow = start?.epoch && start.epoch > now;

            relevantClasses.push({
              ...classData,
              start: start?.ISO,
              end: end?.ISO,
              isAfterNow,
              isToday: !!today?.length,
              title: todayTitle,
              dateSortKey: start?.epoch,
            });
          });
        }
      });
    });
  });

  if (today?.length) {
    chips.push({
      title: "Today",
      snippet: today.length + " Class" + (today.length > 1 ? "es" : ""),
    });
  }
  relevantClasses = sortClasses(relevantClasses);
  const upNext = relevantClasses.find((o) => o.isAfterNow);
  const dateSort = upNext?.dateSortKey && new Date(upNext.dateSortKey);
  if (upNext?.title && dateSort) {
    let formatted = format(dateSort, "MM/dd");
    formatted += "@";
    formatted += format(dateSort, "h:mma");
    formatted = formatted.replace(":00", "");
    const title = upNext?.isToday ? "Up Next" : `Next on ${formatted}`;
    chips.push({
      title,
      snippet: upNext.title,
    });
  }

  return {
    data: relevantClasses,
    chips,
  };
};
