import CONTENT_SERVER from "../data-sources/content-server"
import { searchOptions } from "./constants"

const getGoogle = `
    ${searchOptions.getGoogle}(query: $query, startIndex: $startIndex) {
        items {
            title
            link
            snippet
        }
        queries {
            nextPage {
              count
              searchTerms
              startIndex
              totalResults
            }
            previousPage {
              totalResults
              startIndex
              searchTerms
              count
            }
        }
    }
`

export const getPersonal = `
    ${searchOptions.getPersonal}(query: $query) {
        items {
            title
            data {
                title
                snippet
                description
            }
        }
    }
`

export const searchQueries = {
    [searchOptions.getPersonal]: getPersonal,
    [searchOptions.getGoogle]: getGoogle,
    [searchOptions.getPerson]: CONTENT_SERVER.PERSONS.QUERIES.get,
}