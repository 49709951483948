import { createSlice } from "@reduxjs/toolkit";
import { createBuildingSections } from "../../../Buildings/util/functions";

const initialState = {
  _buildings: [],
  building: {}
};

const buildingsSlice = createSlice({
  name: "buildings",
  initialState,
  reducers: {
    setBuildings(state, action) {
      const payload = action?.payload
      if(Array.isArray(payload?.buildings) && payload?.buildings?.length) {
        state._buildings = payload.buildings
      }
    },
    searchByBuildingId(state, action) {
      const { buildingNumber } = action.payload;
      const currentBuilding = state.building[buildingNumber]
      
      if(!currentBuilding && state._buildings?.length) {
        state.building[buildingNumber] = {}
        const match = state._buildings.find(
          (building) =>
            building?.buildingNumber &&
            building?.buildingNumber === buildingNumber
        )
        if(match) {
          state.building[buildingNumber].data = match
          state.building[buildingNumber].sections = createBuildingSections(match)

        }
      }
    },
  }
});

export const { searchByBuildingId, setBuildings } = buildingsSlice.actions


export const selectBuildings = (state = {}, options = {}) => {
  const { buildingNumber } = options ?? {}
  const buildingInfo = state?.buildings?.building?.[buildingNumber]
  
  return buildingInfo
};

const reducers = {
  getBuildings: buildingsSlice.reducer,
};

export default reducers;
