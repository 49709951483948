import { Mui } from "@osu/react-ui";
import { createHighContrastDividerColor } from "../util/functions";

export const paperSx = (theme, elevation) => {
  const darkMode = theme?.palette?.mode === "dark";

  let sx = {
    [`&.${Mui.paperClasses.outlined}`]: {
      borderColor: createHighContrastDividerColor(theme),
    },
  };
  if (darkMode) {
    sx.backgroundColor = `var(--elevation${elevation})`;
  }

  return sx;
};
