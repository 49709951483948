import { getBuckeyeLearnTranscript } from "../enums";
import { chips } from "../content-server/shared";

const CONSTANTS = {
    get: getBuckeyeLearnTranscript
};

const get = `
  ${CONSTANTS.get} {
    ${chips}
    data {
        dueDate
        name
        url
    }
  }
`;

const source = {
    CONSTANTS,
    QUERIES: {
      get
    }
  };
  
  export default source;