import { createSlice } from "@reduxjs/toolkit";
import { sortByStringField } from "../../../util/functions";

let initialState = {
  list: [],
  courses: {},
  fetched: false
};

const getEnrollmentsSlice = createSlice({
  name: "enrollments",
  initialState,
  reducers: {
    setEnrollments(state, action) {
      if(action?.payload?.enrollments?.length) {
        state.list = action?.payload?.enrollments ?? []
      }
      state.fetched = true
    },
    setEnrollmentsAssignmentsQueue(state, action) {
      if(action?.payload?.queue?.length) {
        state.queue = action?.payload?.queue ?? []
      }
    },
    removeEnrollmentFromQueue(state, action) {
      const calledCourse = action.payload.courseId
      if(calledCourse) {
        state.queue = state.queue.filter(o => (o !== calledCourse))
      }
    },
    setEnrollmentNames(state, action) {
      const res = action?.payload?.courses
      if(Object.keys(res ?? {}).length) {
        Object.entries(res).forEach(([courseId, name]) => {
          if(courseId) {
            state.courses[courseId] = name
          }
        })
      }
    }
  }
});

export const { setEnrollments, setEnrollmentNames, setEnrollmentsAssignmentsQueue, removeEnrollmentFromQueue } = getEnrollmentsSlice.actions

const reducers = {
  getEnrollments: getEnrollmentsSlice.reducer,
};

export const selectEnrollments = (state = {}) => {
  return sortByStringField(state?.enrollments?.list ?? [], 'name')
};

export const selectEnrollmentsHasBeenFetched = (state = {}) => {
  return state?.enrollments?.fetched
}

export const selectEnrollmentName = (state = {}, courseId) => {
  return state?.enrollments?.courses?.[courseId]
};

export const selectEnrollmentCourses = (state = {}) => {
  return Object.keys(state?.enrollments?.courses ?? {})
};

export default reducers;
