import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  _announcements: { current: [], previous: [] },
  announcements: { current: [], previous: [] }
};

const announcementsSlice = createSlice({
  name: "announcements",
  initialState,
  reducers: {
    setAnnouncements(state, action) {
      const payload = action?.payload
      if(payload?.announcements) {
        state._announcements = payload.announcements
      }
    }
  }
});

export const { setAnnouncements } = announcementsSlice.actions

export const selectAnnouncements = (state = {}) => {
    return state.announcements?._announcements;
  };

const reducers = {
  getAnnouncements: announcementsSlice.reducer,
};

export default reducers;

