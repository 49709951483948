import { Mui } from "@osu/react-ui";

export const useStyles = () => {
  const theme = Mui.useTheme();
  const red = theme.palette.brand.scarlet.main;
  const darkenedRedForImprovedContrast = Mui.darken(red, 0.1);
  const textColor = theme.palette.getContrastText(
    darkenedRedForImprovedContrast
  );

  return {
    title: {
      [`&.${Mui.alertTitleClasses.root}`]: {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    background: {
      backgroundColor: darkenedRedForImprovedContrast,
      borderRadius: 0,
      color: textColor,
      " *, h2, h3, h4, h5, h6, time": {
        color: textColor,
      },
      [` .${Mui.dividerClasses.root}`]: {
        borderColor: textColor,
      },
      [` .${Mui.alertClasses.message}`]: {
        color: textColor,
        width: "100%"
      }
    },
    link: {
      color: textColor,
      textDecorationColor: textColor,
      fontWeight: theme.typography.fontWeightBold,
      [`&:hover`]: {
        backgroundColor: textColor,
        color: darkenedRedForImprovedContrast,
      },
    },
  };
};
