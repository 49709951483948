import { useLocation } from "react-router-dom";
import { authorizationRoutes } from "../../Routes/paths";
import { useCallback } from "react";
import { useLazyLoginQuery } from "../../services/authorization/api";


function confirmRedirectPath({ redirectPath = "/" }) {
  const redirectExclusions = Object.values(authorizationRoutes)
  const redirectParts = redirectPath.split('?')
  if(redirectParts[0] &&  !redirectExclusions.includes(redirectParts[0])) {
    return redirectPath
  }
}

function useSignIn() {
  const [callback] = useLazyLoginQuery()
  const { pathname, search } = useLocation();

  const handleSignIn = useCallback((options = {}) => {
    const { redirectPath, doNotRedirect, force } = options
    const redirect = redirectPath && confirmRedirectPath({
      pathname,
      redirectPath
    })

    if(!search.startsWith("?code=")){
      //no longer need useLazyLoginQuery to send a redirect path because the Router will take over in Routes/components/index.js
      callback({
        redirectPath: redirect,
        doNotRedirect,
        force
      })
    }

  }, [callback, pathname, search])

  return {
    handleSignIn,
  };
}

export default useSignIn;
