import React from 'react'
import { Mui } from '@osu/react-ui'
import Informational from '../Informational'
import { APPLICATION_NAME } from '../../../util/constants'

const LoadingLogin = () => {
  return (
    <Informational info={`Signing you in to ${APPLICATION_NAME}`} infoComponent='h2' childrenFirst={true} >
        <Mui.Box className="margin-right-4">
            <Mui.CircularProgress />
        </Mui.Box>
    </Informational>
  )
}

export default LoadingLogin