import { isValid, format, getDayOfYear, addDays, subDays, isSameDay, differenceInMinutes } from "date-fns";
import {DISPLAY} from "./enums";

const getDateStrings = (date) => {
  const month = format(date, 'MMMM')
  const year = format(date, 'yyyy')
  const dayOfWeek = format(date, 'EEEEEE')
  const dayOfWeekFull = format(date, 'EEEE')
  const dayOfMonth = format(date, 'd')

  return {
    isSameDay: isSameDay(new Date(), date),
    month,
    year,
    dayOfWeek,
    dayOfWeekFull,
    dayOfMonth
  }
}

const transformMeetings = (classes = [], options = {}) => {
  const { startDate, endDate } = options  
  let meetings = [];
  let scrollToEarliest = {}
  let latest = {}
  const dateArr = getDates(startDate, endDate)

  dateArr.forEach(date => {
    let event = {
      dayOfYear: getDayOfYear(date),
      ...getDateStrings(date),
      meetings: [],
    }

    event.id = "event-" + event.dayOfYear + "empty"
    const eventsOnThiDay = classes.filter(({ start }) => {
      return isSameDay(new Date(start), date)
    })

    if(eventsOnThiDay?.length) {
      event.meetings = eventsOnThiDay.map(({ start, end, ...meeting }, idx) => {
        const courseId = meeting.courseId
        const startTime = new Date(start)
        const endTime = new Date(end)
        meeting.startTime = startTime.toISOString()
        meeting.endTime = endTime.toISOString()
        meeting.startMinutes = startTime.getMinutes()
        meeting.startHour = startTime.getHours()
        meeting.endMinutes = endTime.getMinutes()
        meeting.endHour = endTime.getHours()
        meeting.differenceInMinutes = differenceInMinutes(endTime, startTime)
        meeting.meetingId = `${courseId}-${event.dayOfYear}-${meeting.startHour}-${meeting.startMinutes}`
        meeting.eventId = meeting.meetingId + "-event"

        const scrollToEarliestHours = meeting.startHour + (meeting.startMinutes / 60)
        const setNew = (!Object.keys(scrollToEarliest ?? {}).length) || (scrollToEarliestHours < scrollToEarliest.hours)
        if(idx === 0 && setNew) {
          scrollToEarliest.meetingId = meeting.meetingId
          scrollToEarliest.hours = scrollToEarliestHours
          scrollToEarliest.eventId = meeting.eventId
          scrollToEarliest.minutes = (meeting.startHour * 60) + (meeting.startMinutes)
        }

        const lastTimeInMinutes = (meeting.startHour * 60) + meeting?.differenceInMinutes
        const lastTimeInHours = (lastTimeInMinutes / 60)
        const setNewLatest = (!Object.keys(latest ?? {}).length) || (lastTimeInHours > (latest.hours ?? 0))
        if((idx === (eventsOnThiDay.length - 1)) && setNewLatest) {
          latest.minutes = lastTimeInMinutes
          latest.hours = lastTimeInHours
        }  
        const { subject, catalogNumber } = meeting
        const mainTitle = [subject, catalogNumber].filter(o => !!o).join(" ")
        const title = meeting.description
        const subtitle = mainTitle
        meeting.mainTitle = title
        meeting.description = subtitle
        
        return meeting
      })
    }
    meetings.push(event)
  })
  
  return {
    meetings,
    scrollToEarliest,
    latest
  };
};

export function getDates(startDate, stopDate) {
  let dateArray = [];
  let currentDate = startDate;
  while (currentDate <= stopDate || isSameDay(currentDate, stopDate)) {
    dateArray.push(new Date (currentDate));
    currentDate = addDays(currentDate, 1);
  }

  return dateArray;
}

const formatMiniTimeLabel = (time, showAmPm) => {
  if(!time) return ''
  let formattedTime 
  if(isValid(time)) {
    formattedTime = time 
  } else {
    formattedTime = new Date(time)
  }
  return isValid(formattedTime) ? format(formattedTime, "h:mm" + (showAmPm ? "aaa" : "")) : ''
};

const getMajority = (events = []) => {
  let monthKey = []
  events.forEach(({month, year, ...rest }) => {
    let match = monthKey.find(({ m, y }) => (m === month) && (year === y))
    if(!match) {
      monthKey.push({
        m: month,
        y: year,
        count: 1
      })
    } else {
      match.count = (match.count + 1)
    }
  })
  const max = monthKey?.length ? monthKey.reduce((prev, current) => (prev.count > current.count) ? prev : current) : {}

  return {
    month: max?.m,
    year: max?.y
  }
}

export const recalculateDates = (currentStart, currentEnd, viewBy) => {
  let middle
  
  const isSame = isSameDay(currentStart, currentEnd)
  if(isSame) {
    middle = currentStart
  } else {
    const dateRange = getDates(currentStart, currentEnd)
    middle = dateRange[Math.floor((dateRange.length - 1) / 2)]
  }

  if(viewBy === DISPLAY.VIEW_BY_OPTIONS.DAY) {
    const today = new Date()
    return {
      start: today,
      end: today
    }
  }
  
  return {
    start: subDays(middle, 2),
    end: addDays(middle, 2)
  }
}

export { formatMiniTimeLabel, transformMeetings, getMajority };

const day = {
  hours: 24,
  minutes: 60,
  seconds: 60,
  milliseconds: 1000,
}

const getOffsetMs = (numOfDays) => {
  return numOfDays * (day.hours * day.minutes * day.seconds * day.milliseconds)
}

const convertDate = (days = 0, subtract = true) => {
  let date = new Date() 
  const currentDate = date.getTime()
  const offset = subtract 
    ? currentDate - getOffsetMs(days)
    : currentDate + getOffsetMs(days)

  currentDate.setTime(offset);
  currentDate.setHours(subtract ? 0 : 59)
  currentDate.setSeconds(subtract ? 0 : 59)
  currentDate.setMilliseconds(subtract ? 0 : 59)

  return currentDate
}

export const filterByDate = (dates = []) => {
  const startTime = convertDate(7).getTime()
  const endTime = convertDate(31, false).getTime()

  const datesWithinRange = dates.filter(date => {
    const parsedDate = date && new Date(date)
    const isDate = parsedDate.toString() !== "Invalid Date"
    if(isDate) {
      const timestamp = parsedDate.getTime()
      return (timestamp >= startTime) && (timestamp <= endTime)

    }
    return false
  })

  return datesWithinRange
}

export const createGridTempColumns = (repeat) => {
  if(repeat === 3) {
    return `minmax(0, 1fr) repeat(3, minmax(0, 2fr))`
  }
  const minSize = '3rem'
  if(repeat > 1) {
    return `minmax(${minSize}, 1fr) repeat(${repeat}, minmax(${minSize}, 5fr))`
  }
  return `minmax(${minSize}, 1fr) repeat(${repeat}, minmax(${minSize}, 20fr))`
}
