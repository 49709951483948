import { Mui } from "@osu/react-ui";

function Window({ children, anchorEl }) {
  return (
    <Mui.Grow in={Boolean(anchorEl)} container={anchorEl}>
      <Mui.Paper
        className="buckeye-bot-paper"
        sx={{
          zIndex: 100000,
          bottom: 0,
          position: `fixed`,
          right: `20px`,
          transition: `all 0.3s ease-out`,
        }}
      >
        {children}
      </Mui.Paper>
    </Mui.Grow>
  );
}
export default Window;
