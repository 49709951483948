import { Mui } from '@osu/react-ui'
import Chip from "../../../Common/components/Chip";
import { mergeSxStyles } from "../../../styles/util";

const ChipListSummary = (props = {}) => {
  const { type: key, expanded, id, chips= [], ...rest } = props;

  if(!chips?.length) {
    return false
  }
  const idPrefix = key + "-chiplistsummary-";
  let chipProps =  {
    variant: "outlined"
  }
  if(expanded) chipProps.size = "large"
  chipProps.sx = mergeSxStyles((theme) => ({
    marginRight: theme.spacing(),
    marginY: theme.spacing(.5),
  }), chipProps.sx)

  return (
    <Mui.List id={id} className="padding-0 display-flex flex-wrap-wrap margin-1" sx={(theme) => ({
      marginRight: '4rem',
      alignItems: 'center',
      maxWidth: '85%',
      [theme.breakpoints.up('md')]: {
        maxWidth: '75%',
      }
    })} {...rest}>
      {chips.map(({ title, snippet }, index) => (
          <Chip
            key={idPrefix + index + "chippie"}
            component="li"
            {...chipProps}
            primary={title}
            secondary={snippet ?? undefined}
            className={`margin-right-1`}
            />
      ))}
    </Mui.List>
  );
};

export default ChipListSummary;
