import { Mui } from "@osu/react-ui";
import PropTypes from 'prop-types';
import { Fragment } from "react";
import Markdown from "../../Common/components/Markdown"

const Hold = ({ hold }) => {
  let title, subtitle, longformMessage;
  title = hold.reason ?? "";
  subtitle = hold.department ?? "";
  longformMessage = hold.instruction ?? "Placeholder description";
  return <Fragment>
    <dt style={{ width: "60%", margin: "1rem" }}>
      <Mui.Typography variant="subtitle" sx={{ width: "60%", "fontWeight": "bold" }}>
        {title} {" - " + subtitle}
      </Mui.Typography>
    </dt>
    <dd style={{ "marginLeft": "1rem","marginRight": "1rem", "marginBottom": "1rem" }}>
      <Mui.Box sx={{ "marginRight": "1rem", "marginBottom": "1rem" }}>
        <Markdown markdown={longformMessage}></Markdown>
      </Mui.Box>
    </dd>
    </Fragment>

};


Hold.defaultProps = {
  hold: {
    reason: "",
    department: "",
    instruction: "",
    amountFormatted: "$0.00",
    currency: "USD"
  },
  backgroundColor: "#FFFFFF"
}

Hold.propTypes = {
  hold: PropTypes.shape({
    reason: PropTypes.string,
    department: PropTypes.string,
    instruction: PropTypes.string,
    amountFormatted: PropTypes.string,
    currency: PropTypes.string
  }),
  backgroundColor: PropTypes.string
}

export default Hold;
