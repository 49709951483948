import { useState } from "react";

function useXichigan() {
  const [haveMsAndCrossesBeenSwapped, setHaveMsAndCrossesBeenSwapped] = useState(false);
  const defaultContainerAnimationClass = process.env.REACT_APP_ENABLE_EASTER_EGG_ANIMATION ? "font-icon-wrapper" : "";
  const [containerAnimationClass, setContainerAnimationClass] = useState(defaultContainerAnimationClass);

  function swapMsAndCrosses() {
    const elements = document.querySelectorAll("*");
    elements.forEach((element) => {
      if (element.childNodes.length > 0 && !isElementInHead(element)) {
        element.childNodes.forEach((node) => {
          if (node.nodeType === Node.TEXT_NODE) {
            const text = node.textContent;
            let replacedText;
            if (haveMsAndCrossesBeenSwapped) {
              replacedText = text.replace(/❌­/g, "M"); //note: there is an invisible soft hyphen after the cross
              replacedText = replacedText.replace(/❌/g, "m");
            } else {
              replacedText = text.replace(/M/g, "❌­"); //note: there is an invisible soft hyphen after the cross
              replacedText = replacedText.replace(/m/g, "❌");
            }
            if (replacedText !== text) {
              node.textContent = replacedText;
            }
          }
        });
      }
    });
    setHaveMsAndCrossesBeenSwapped(!haveMsAndCrossesBeenSwapped);
    setContainerAnimationClass("");
  }

  function isElementInHead(element) {
    let currentElement = element;
    while (currentElement.parentNode) {
      if (currentElement.parentNode.tagName === "HEAD") {
        return true;
      }
      currentElement = currentElement.parentNode;
    }
    return false;
  }

  return {
    className: containerAnimationClass,
    onClick: swapMsAndCrosses
  }
}

export default useXichigan;