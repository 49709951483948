import { STATUSES } from "../../util/constants";
import { graphqlApi } from "../graphql/api";

export const todos = (builder) => {
  builder.addMatcher(
    graphqlApi.endpoints.getToDoList.matchPending,
    (state) => {
      state.status = STATUSES.LOADING
    },
    graphqlApi.endpoints.getToDoList.matchRejected,
    (state) => {
      state.status = STATUSES.ERROR
    },
    graphqlApi.endpoints.getToDoList.matchFulfilled,
    (state) => {
      state.status = STATUSES.SUCCESS
    },
  );
}