import { Mui } from "@osu/react-ui";

const useColorTileSx = ({ backgroundColor }) => {
  const theme = Mui.useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const getContrastText = theme.palette.getContrastText;

  const color = isDarkMode
    ? Mui.lighten(backgroundColor, 14 / 20)
    : Mui.darken(backgroundColor, 14 / 20);
  const bg = isDarkMode
    ? Mui.darken(backgroundColor, 15 / 20)
    : Mui.lighten(backgroundColor, 19 / 20);
  const overline = { lineHeight: "normal" };
  
  if (bg && getContrastText) {
    overline.color = getContrastText(bg);
    overline.color = Mui.alpha(overline.color, 1)
  }
  return {
    borderWidth: ".1rem",
    borderStyle: "solid",
    borderColor: backgroundColor,
    borderLeftWidth: "0.5rem",
    backgroundColor: bg,
    color
  };
};

export default useColorTileSx
