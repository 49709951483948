import CONTENT_SERVER from "./content-server/"
import GOOGLE from "./google"
import CONTENT_BUCKET from "./content-bucket/"
import AWS_LAMBDA from "./aws-lambda/"
import { AFFILIATIONS, CONTENT_BUCKET_SERVICE, CONTENT_SERVER_SERVICE, GOOGLE_SERVICE, AWS_LAMBDA_SERVICE } from "./enums"

const mappings = {
    [CONTENT_BUCKET_SERVICE]: CONTENT_BUCKET,
    [CONTENT_SERVER_SERVICE]: CONTENT_SERVER,
    [GOOGLE_SERVICE]: GOOGLE,
    [AFFILIATIONS]: AFFILIATIONS,
    [AWS_LAMBDA_SERVICE]: AWS_LAMBDA
}

export default mappings