import { searchAcrossContentServer } from "../enums";
import { items } from "./shared";

const CONSTANTS = {
  get: searchAcrossContentServer,
};

const get = `
    ${CONSTANTS.get}(query: $query) {
        ${items}
    }
  `;

const source = {
  CONSTANTS,
  QUERIES: {
    get,
  },
}

export default source;
