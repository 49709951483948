import { Mui } from "@osu/react-ui";
import { useSelector } from "react-redux";
import {
  selectToDoModifiedDate,
  selectToDoStatus,
} from "../../services/graphql/slices/todos";
import { STATUSES, todos } from "../../util/constants";

export const TODOERRROR = `We are having trouble getting your ${todos.individual}s at this time please try again later.`

function Warning({ isError }) {
  const lastModified = useSelector(selectToDoModifiedDate);
  const status = useSelector(selectToDoStatus);
  const error = isError ?? status === STATUSES.ERROR;
  const loading = status === STATUSES.LOADING;
  const message = loading
    ? `Please wait, your ${todos.individual}s are loading...`
    : error
    ? `We are having trouble getting your ${todos.individual}s at this time please try again later.`
    : `Completed ${todos.individual}s may take a day or more to be updated.`;

  return (
    <Mui.Alert
      sx={{ bgcolor: "background.paper", mb: 2 }}
      variant="outlined"
      severity={error ? "error" : "info"}
    >
      {lastModified && (
        <Mui.Typography
          sx={{
            color: "inherit",
          }}
          variant="subtitle2"
          component="span"
        >
          Last updated: {lastModified} -{" "}
        </Mui.Typography>
      )}
      {message}
    </Mui.Alert>
  );
}

export default Warning;
