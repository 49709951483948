import { getPerson } from "../enums";

const CONSTANTS = {
  get: getPerson,
};

const get = `
    ${CONSTANTS.get}(query: $query, nextPageLink: $nextPageLink) {
      items {
        firstName
        address {
          building {
            name
            number
            url
          }
          city
          state
          roomNumber
          street1
          street2
          zip
        }
        appointments {
          jobTitle
          orgCode
          organization
          vpCollegeName
          workingTitle
        }
        displayName
        email
        lastName
        majors {
          college
          major
        }
        phone {
          areaCode
          exchange
          subscriber
          formatted
        }
        nameSuffix
        middleName
      }
      nextPageLink
      totalItems
  }
`;

const source = {
  CONSTANTS,
  QUERIES: {
    get,
  },
};

export default source;
