import { MuiX, Mui } from "@osu/react-ui";
import { isSameDay } from "date-fns";

function CustomDayPicker({ selectedDays, startDate, endDate, day, ...other }) {
    const isSelected = selectedDays.find((date) => isSameDay(date, day));
    if (!other.className) other.className = "";
    other.className += ` a11y-button-reset button`;
    if(other?.outsideCurrentMonth) {
      return  <MuiX.PickersDay {...other} day={day} outsideCurrentMonth />
    }
    if (isSelected) {
      other.selected = true;
      other["aria-selected"] = true;
      const leftHand = isSameDay(new Date(startDate), day);
      const rightHand = isSameDay(new Date(endDate), day);
      const boxProps = {
        sx: (theme) => {
          const highlightColor = Mui.alpha(theme.palette.primary.main, 0.8);
          const styles = {
            backgroundColor: highlightColor,
            paddingTop: "2px",
            paddingBottom: "2px",
          };
          const deselected = {
            backgroundColor: "transparent",
            color: theme.palette.getContrastText(highlightColor),
          };
          if (leftHand || other.isFirstVisibleCell) {
            styles.borderTopLeftRadius = "45%";
            styles.borderBottomLeftRadius = "45%";
          } else if (rightHand || other.isLastVisibleCell) {
            styles.borderTopRightRadius = "45%";
            styles.borderBottomRightRadius = "45%";
            styles[`& .${MuiX.pickersDayClasses.selected}`] = deselected;
          } else {
            styles[`& .${MuiX.pickersDayClasses.selected}`] = deselected;
          }
          return styles;
        },
      };
      return (
        <Mui.Box {...boxProps}>
          <MuiX.PickersDay {...other} day={day} />
        </Mui.Box>
      );
    }
    return <MuiX.PickersDay {...other} day={day} />;
  }

  export default CustomDayPicker