import { createSlice } from "@reduxjs/toolkit";
import { graphqlApi } from "../api";
import { searchOptions } from "../constants";
import { STATUSES } from "../../../util/constants";

const initialState = {
  query: "",
  cache: [],
  status: STATUSES.IDLE
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    clear(state) {
      if(state.query) {
        state.query = ""
      }
    },
    setQuery(state, action) {
      if(state.query !== action?.payload?.query) {
        state.query = action?.payload?.query ?? ""
      }
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      graphqlApi.endpoints.getSearchResults.matchFulfilled,
      (state, searchResults) => {
        state.status = STATUSES.SUCCESS;
        const queryString = searchResults?.queryString 
          ?? searchResults?.meta?.arg?.originalArgs?.queryString
        
        if(queryString) {
          let match = state.cache.find(({ query }) => {
            return query === queryString
          }) 
          if(!match) {
            state.cache.push({
              query: queryString,
              payload: searchResults?.payload
            })
          } else {
            match.payload = searchResults?.payload
          }
        }
      }

    );
    builder.addMatcher(
      graphqlApi.endpoints.getSearchResults.matchPending,
      (state) => {
        state.status = STATUSES.LOADING;
      }
    );
    builder.addMatcher(
      graphqlApi.endpoints.getSearchResults.matchRejected,
      (state) => {
        state.status = STATUSES.ERROR;
      }
    );
  },
});

export const { clear, setQuery } = searchSlice.actions
const reducers = {
  getSearchResults: searchSlice.reducer,
};

export const selectQuery = (state = {}) => {  
  return state.search?.query ?? undefined
};

export const selectAboutYouSearchResults = (state = {}, query) => { 
  if(!query) {
    return undefined
  }
  const cache = state?.search?.cache ?? []
  if(!cache?.length) {
    return undefined
  }
  
  return cache.find(({ query: q }) => q === query)?.payload?.[searchOptions.getPersonal]
};

export const selectSearchResults = (state = {}, query) => { 
  if(!query) {
    return undefined
  }
  const cache = state?.search?.cache ?? []
  if(!cache?.length) {
    return undefined
  }

  return cache.find(({ query: q }) => q === query)?.payload?.[searchOptions.getGoogle]
};


export const selectFindPeopleSearchResults = (state = {}, query) => { 
  if(!query) {
    return undefined
  }
  const cache = state?.search?.cache ?? []
  if(!cache?.length) {
    return undefined
  }
  
  return cache.find(({ query: q }) => q === query)?.payload?.[searchOptions.getPerson]
};

export const selectStatus = (state) => {
  const status =state.search.status
  return {
    status,
    isIdle: status === STATUSES.IDLE,
    isError: status === STATUSES.ERROR,
    isLoading: status === STATUSES.LOADING,
    isSuccess: status === STATUSES.SUCCESS,
  }
}

export const compareSearchResults = (a, b) => {
  return a?.query === b?.query
};
export default reducers;
