import { AFFILIATIONS, ANNOUNCEMENTS, BUCKEYELEARN, CHAT, HR_DATA } from "../enums.js"
import announcements from "./announcements.js"
import affiliations from "./affiliations.js"
import buckeyeLearn from "./buckeyelearn.js"
import sendText from "./sendText.js"
import hrData from "./getHRProfileData.js"

const AWS_LAMBDA = {
    [ANNOUNCEMENTS]: announcements,
    [AFFILIATIONS]: affiliations,
    [BUCKEYELEARN]: buckeyeLearn,
    [CHAT]: sendText,
    [HR_DATA]: hrData
}

export default AWS_LAMBDA