import { getGoogle } from "./enums";

const CONSTANTS = {
    get: getGoogle
}

const get = `
    ${CONSTANTS.get}(query: $query, startIndex: $startIndex) {
        items {
            title
            link
            snippet
        }
    }
`;

const source = {
    CONSTANTS,
    QUERIES: {
        get
    }
}
  
  export default source