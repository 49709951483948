import { MuiIcons } from "@osu/react-ui"
import { BUILDING_KEYS, BUILDING_LABELS, BUILDING_COLORS } from './constants';

export const createChips = (room = {}) => {
    const { accessControlled, hasRefrigerator, hasWater, isSignUpRequired } = room
    let chips = []
    if(accessControlled) {
        chips.push({
            label: 'Access Controlled',
            icon: <MuiIcons.Lock />
        })
    }
    if(hasRefrigerator) {
        chips.push({
            label: 'Refigerator Available',
            icon: <MuiIcons.Kitchen />
        })
    }
    if(hasWater) {
        chips.push({
            label: 'Water Available',
            icon: <MuiIcons.Water />
        })
    }
    if(isSignUpRequired) {
        chips.push({
            label: 'Sign Up Required',
            icon: <MuiIcons.Assignment />
        })
    }
    return chips
}

function createBuildingSections(building) {
    let accordions = []
    Object.values(BUILDING_KEYS).forEach(key => {
        let buildingInfo = {}
        const heading = BUILDING_LABELS[key] ?? ""

        buildingInfo.heading = heading
        buildingInfo.key = key
        buildingInfo.color = BUILDING_COLORS[key]
        let data = building[key]
        const isRoom = [
            BUILDING_KEYS.GENDER_RESTROOMS,
            BUILDING_KEYS.LACTATION_ROOMS,
            BUILDING_KEYS.SANCTUARIES,
            BUILDING_KEYS.WELLNESS_ROOMS,
        ].includes(key)
        if(key === BUILDING_KEYS.ENTRANCES) {
            data = [
                {
                    "accessible": true,
                    "buildingNumber": "113",
                    "entranceId": "1862",
                    "hasAccessibleRamp": true,
                    "images": [
                        "https://wmimages.cloud.osu.edu/entrance-images/gis/files/1862/photo1.jpg",
                        "https://wmimages.cloud.osu.edu/entrance-images/gis/files/1862/photo2.jpg"
                    ],
                    "isButtonActivated": true,
                    "isFullyAutomated": false,
                    "latitude": "39.995916557257516",
                    "longitude": "-83.01928407607801",
                    "description": "TEST 1 North Entrance"
                },
                {
                    "accessible": true,
                    "buildingNumber": "113",
                    "entranceId": "1862",
                    "hasAccessibleRamp": true,
                    "images": [
                        "https://wmimages.cloud.osu.edu/entrance-images/gis/files/1862/photo1.jpg",
                        "https://wmimages.cloud.osu.edu/entrance-images/gis/files/1862/photo2.jpg",
                        "https://wmimages.cloud.osu.edu/entrance-images/gis/files/1848/photo1.jpg"
                    ],
                    "isButtonActivated": true,
                    "isFullyAutomated": false,
                    "latitude": "39.995916557257516",
                    "longitude": "-83.01928407607801",
                    "description": "TEST 2 North Entrance"
                },
                {
                    "accessible": true,
                    "buildingNumber": "113",
                    "entranceId": "1862",
                    "hasAccessibleRamp": true,
                    "images": [
                        "https://wmimages.cloud.osu.edu/entrance-images/gis/files/1848/photo1.jpg"
                    ],
                    "isButtonActivated": true,
                    "isFullyAutomated": false,
                    "latitude": "39.995916557257516",
                    "longitude": "-83.01928407607801",
                    "description": "TEST 3 South Entrance"
                }
            ]
        }

        if(Array.isArray(data) && data?.length) {
            if(
                key === BUILDING_KEYS.ENTRANCES
            ) {
                buildingInfo.entrances = []
                buildingInfo.listItems = []
                data.forEach((record = {}) => {
                    const primary = record.description || record.entranceId
                    buildingInfo[record?.images?.length ? 'entrances' : 'listItems'].push({
                        primary,
                        ...record,
                        buildingInfo: {
                            name: buildingInfo?.name,
                            description: buildingInfo?.description,
                        }
                    })
                })
            } else if (isRoom) {
                buildingInfo.listItems = data.map(({ number, ...rest }) => ({
                    primary: "Room " + number,
                    ...rest
                }))
            }
        }
        accordions.push(buildingInfo)

    })
    return accordions
}

export {
    createBuildingSections
}