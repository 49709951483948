import { getAccountBalances } from "../enums";
import { chips } from "./shared";

const CONSTANTS = {
    get: getAccountBalances
}

const get = `
    ${CONSTANTS.get} {
        ${chips}
        data {
            accounts {
                balance
                category
                name
                sortOrder
                type
            }
            barCodeValue,
            transactions {
                account
                date
                description
                amount
            }
        }
    }
`;

const source = {
    CONSTANTS,
    QUERIES: {
        get
    }
}

export default source