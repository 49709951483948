import { getGrades } from "../enums";

const CONSTANTS = {
  get: getGrades,
};

const get = `
    ${CONSTANTS.get}(query: $query) {
      muted
      score
      status
      due
      grade
      outOf
      title
      assignmentId
      assignmentGroupId
      assignmentGroup
      dropLowest
      groupWeight
      comments
      commentsList
      itemHash
    }
`;

const source = {
  CONSTANTS,
  QUERIES: {
    get,
  }
};
export default source;
