import { Mui } from "@osu/react-ui";
import { useEffect, useRef } from "react";
import AssignmentsListsByDueDate from "./AssignmentListsByDueDate";
import { selectDashboardAssignments } from '../../services/graphql/slices/assignments'
import { buildResponsiveStyle } from "../../util/functions";
import { useSelector } from "react-redux";

const AssignmentsListView = ({ expanded }) => {
  const { assignments: groupedAssignments, nextUpcomingDueDate } = useSelector(selectDashboardAssignments)
  const assignmentsWrapperRef = useRef(null);
  const targetRef = useRef(null);
  const descriptionLists = <AssignmentsListsByDueDate groupedAssignments={groupedAssignments} nextUpcomingDueDate={nextUpcomingDueDate} ref={targetRef} />;

  useEffect(() => {
    if (expanded && assignmentsWrapperRef.current && targetRef.current) {
      const targetPosition = targetRef.current.offsetTop;
      assignmentsWrapperRef.current.scrollTo(0, targetPosition - 100);
    }
  }, [targetRef, assignmentsWrapperRef, expanded]);

  const minScrollHeight = groupedAssignments?.length > 0 ? "300px" : "100px";
  return (
    <>
      <div ref={assignmentsWrapperRef} style={buildResponsiveStyle(minScrollHeight)}>
        {(groupedAssignments?.length > 0 && descriptionLists) || (
          <Mui.Typography variant="body1" sx={{ margin: "1rem" }}>
            You do not have any assignments right now
          </Mui.Typography>
        )}
      </div>
    </>
  );
};

export default AssignmentsListView;
