import { Mui } from "@osu/react-ui";
import Markdown from "../../../Common/components/Markdown";

const components = {
  ul: ({ children }) => (
    <Mui.List dense className="padding-top-0 stylized" sx={{
        "&.stylized": {
            paddingInlineStart: '1rem'
        },
        ["&.stylized li." + Mui.listItemClasses.root]: {
            listStyleType: "'-'"  
        }
    }}>
      {children}
    </Mui.List>
  ),
  li: ({ children }) => (
    <Mui.ListItem sx={{
        display: 'list-item',
    }}>{children}</Mui.ListItem>
  ),
  p: ({ children }) => (
    <Mui.Typography variant="body2" className="padding-bottom-1">
      {children}
    </Mui.Typography>
  )
};

function Summary({ children }) {
  return <Markdown components={components} markdown={children} />;
}

export default Summary;
