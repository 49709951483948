import React from "react";
import Time from "./Time";
import { formatMiniTimeLabel } from "./util/functions";
import { times } from "./util/day";
import { Mui, MuiIcons } from "@osu/react-ui";
import TableRows from "./TableRows";
import { dashCalComparators, selectDashboardCalendarMeetingData } from "../services/graphql/slices/dashboardCalendar";
import { useSelector } from "react-redux";
import useStylingValues from "./styles/useStylingValues";
import { mappings } from "../styles/icon_colors";
import { CLASSES } from "../services/data-sources/enums";

export const TIME_INDICATOR_ID = "time-indicator";

const currentTime = new Date();
const currentHour = currentTime.getHours();
const currentMinutes = currentTime.getMinutes();

const useTimeIndicatorClasses = () => {
  const { theme, heightPerHour, baseZ } = useStylingValues();
  const zIndex = {
    timeIndicator: baseZ - 3,
    timeCaption: baseZ - 2
  }
  const colorOfClasses = mappings[CLASSES]
  const timeIndicatorColor = theme.palette.colors[colorOfClasses ?? "blue"]

  return {
    timeIndicator:({ minutes }) => {
      let cls = {
        position: "absolute",
        right: 0,
        left: 0,
        display: "flex",
        width: "100%",
        height: "2rem",
        marginTop: "-1rem",
        zIndex: zIndex.timeIndicator,
        top: `calc(${minutes/60}*${heightPerHour})`,
        backgroundColor: "transparent",
        paddingY: "1rem",
        alignItems: "center",
        [" ." + Mui.svgIconClasses.root]: {
          fontSize: "8px",
          marginLeft: "-2px",
          color: timeIndicatorColor,
        },
        " hr": {
          borderWidth: ".1px",
          width: "100%",
          height: "2px",
          borderColor: timeIndicatorColor,
          backgroundColor: timeIndicatorColor,
          opacity: .25
        },
        [" ." + Mui.typographyClasses.caption]: {
          paddingRight: ".4rem",
          zIndex: zIndex.timeCaption,
        }
      }
      return cls
    }
  };
};

const MatchingHour = () => {
  const classes = useTimeIndicatorClasses();

  return (
    <Mui.Box
      id={TIME_INDICATOR_ID}
      component="td"
      sx={classes.timeIndicator({
        hour: currentHour,
        minutes: currentMinutes,
      })}
    >
      <Mui.Typography className={Mui.accordionClasses.root} variant="caption">
        <Mui.Typography component="span" variant="srOnly">Current Time</Mui.Typography>
        {formatMiniTimeLabel(currentTime, false)}
      </Mui.Typography>
      <MuiIcons.Circle aria-hidden="true" />
      <Mui.Divider aria-hidden="true" />
    </Mui.Box>
  );
};

function Times() {
  const { meetings: events, heightPerHour } = useSelector(
    selectDashboardCalendarMeetingData, (a,b) => {
      const meetingHash = dashCalComparators.meetings(a?.meetings, b?.meetings)
      const isEqual = meetingHash && (a.heightPerHour === b.heightPerHour)
      return isEqual
    })

  return times.map((time, idx) => {
    const { hour, label } = time;

    return (
      <Time key={hour + "-" + label + "-" + idx} label={label}>
        {currentHour === hour && <MatchingHour />}
        <TableRows
          events={events}
          hour={hour}
          index={idx}
          heightPerHour={heightPerHour}
        />
      </Time>
    );
  });
}

export default Times;
