import { Link, useLocation } from "react-router-dom";
import { Mui } from "@osu/react-ui";
import { mergeSxStyles } from "../../styles/util";
import { APPLICATION_NAME } from "../../util/constants";

const linkStyles = ({ isSame, disableHighlightCurrent }) => [
  (theme) => {
    const isDarkMode = theme.palette.mode === "dark";
    const darkGrays = theme.palette.brand.gray.darker;
    const lightGrays = theme.palette.brand.gray.lighter;
    let cls = {
      textTransform: "none",
      borderBottom: `4px solid transparent`,
      height: '100%',
      borderRadius: 0,
      padding: `.25rem 0`,
      alignSelf: "bottom",
      color: theme.palette.textColor,
      [`&.${Mui.buttonClasses.textPrimary}`]: {
        color: theme.palette.textColor,
      },
      "&:hover": {
        backgroundColor: (isDarkMode ? darkGrays : lightGrays)[80],
        borderBottomColor: (isDarkMode ? lightGrays : darkGrays)[40],
      },
    };
    if(isSame && !disableHighlightCurrent) {
        cls.borderBottomColor = theme.palette.brand.gray.main
    }
    return cls
  }
]

const InternalLink = ({ to, children, disableHighlightCurrent, sx }) => {
  const { pathname } = useLocation()
  const isSame = pathname === to
  const styles = mergeSxStyles(linkStyles({ isSame, disableHighlightCurrent }), sx)
  const props = {
    component: Link,
    to
  }
  if(isSame) {
    props["aria-current"] = "page"
  }
  return (
    <Mui.Button
      sx={styles}
      {...props}
    >
      {children}
    </Mui.Button>
  );
};

export const AppLink = (props = {}) => <InternalLink to="/" disableHighlightCurrent {...props}>
  {APPLICATION_NAME}
</InternalLink>

export default InternalLink;
