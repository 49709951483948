import React from "react";
import { Mui } from "@osu/react-ui";

const Departments = Mui.createSvgIcon(
  <g>
    <path
      d="M22.5178987,12.8275862 C23.509124,12.8275862 24,13.318477 24,14.2908152 L24,20.054036 C24,21.0263646 23.509124,21.5172414 22.5178987,21.5172414 L16.792451,21.5172414 C15.8059527,21.5172414 15.3103448,21.0311013 15.3103448,20.054036 L15.3103448,14.2908152 C15.3103448,13.3137548 15.8059527,12.8275862 16.792451,12.8275862 L22.5178987,12.8275862 Z M19.6575153,18.7251608 C18.1275285,18.7251608 16.8680896,19.5806759 16.508918,20.7681332 L22.8014269,20.7681332 C22.4422791,19.5806759 21.1828497,18.7251608 19.6575153,18.7251608 Z M19.6528486,14.4756243 C18.7619147,14.4756243 18.0528952,15.2516722 18.0575163,16.1913409 C18.0622238,17.1917827 18.7619147,17.9350865 19.6528486,17.9444693 C20.5437969,17.9538043 21.2481449,17.1917827 21.2481449,16.1913409 C21.2481449,15.2516722 20.5437969,14.4756243 19.6528486,14.4756243 Z"
    ></path>
    <path
      d="M7.20755384,12.8275862 C8.19877915,12.8275862 8.68965517,13.318477 8.68965517,14.2908152 L8.68965517,20.054036 C8.68965517,21.0263646 8.19877915,21.5172414 7.20755384,21.5172414 L1.48210618,21.5172414 C0.495607861,21.5172414 0,21.0311013 0,20.054036 L0,14.2908152 C0,13.3137548 0.495607861,12.8275862 1.48210618,12.8275862 L7.20755384,12.8275862 Z M4.3471705,18.7251608 C2.8171837,18.7251608 1.55774475,19.5806759 1.19857312,20.7681332 L7.49108205,20.7681332 C7.13193427,19.5806759 5.87250483,18.7251608 4.3471705,18.7251608 Z M4.34250378,14.4756243 C3.4515699,14.4756243 2.74255033,15.2516722 2.74717145,16.1913409 C2.75187894,17.1917827 3.4515699,17.9350865 4.34250378,17.9444693 C5.23345208,17.9538043 5.93780009,17.1917827 5.93780009,16.1913409 C5.93780009,15.2516722 5.23345208,14.4756243 4.34250378,14.4756243 Z"
    ></path>
    <path
      d="M15.0696228,0 C16.0608481,0 16.5517241,0.490890752 16.5517241,1.46322902 L16.5517241,7.22644976 C16.5517241,8.19877835 16.0608481,8.68965517 15.0696228,8.68965517 L9.34417512,8.68965517 C8.3576768,8.68965517 7.86206897,8.20351505 7.86206897,7.22644976 L7.86206897,1.46322902 C7.86206897,0.486168551 8.3576768,0 9.34417512,0 L15.0696228,0 Z M12.2092075,5.79310345 C10.700037,5.79310345 9.45773325,6.65950336 9.10344828,7.86206897 L15.3103448,7.86206897 C14.9560833,6.65950336 13.7137891,5.79310345 12.2092075,5.79310345 Z M12.0000187,1.65517241 C11.0756559,1.65517241 10.3400332,2.39574272 10.3448276,3.29245364 C10.3497117,4.24715936 11.0756559,4.95648237 12.0000187,4.96543627 C12.9243964,4.97434449 13.6551724,4.24715936 13.6551724,3.29245364 C13.6551724,2.39574272 12.9243964,1.65517241 12.0000187,1.65517241 Z"
    ></path>
    <g transform="translate(3.724138, 3.724138)">
      <path
        d="M1.83908046,0 C2.60383987,0 3.23231748,0.596752929 3.30360979,1.35978933 L3.31034483,1.50470219 L3.31034483,8.27586207 L1.83908046,8.27586207 L1.83834483,1.50388715 L0,1.50470219 L0,0 L1.83908046,0 Z"
        transform="translate(1.655172, 4.137931) scale(-1, 1) translate(-1.655172, -4.137931) "
      ></path>
      <path
        d="M15.4942529,0 C16.2590123,0 16.8874899,0.596752929 16.9587822,1.35978933 L16.9655172,1.50470219 L16.9655172,8.27586207 L15.4942529,8.27586207 L15.4935172,1.50388715 L13.6551724,1.50470219 L13.6551724,0 L15.4942529,0 Z"
      ></path>
    </g>
    <polygon
      transform="translate(12.000000, 16.897363) rotate(180.000000) translate(-12.000000, -16.897363) "
      points="9.51724138 16.137931 14.4827586 16.137931 14.4827586 17.6567951 9.51724138 17.6567951"
    ></polygon>
  </g>,
  "people working together in a department"
);

const DepartmentsIcon = (props) => {
  return <Departments {...props} viewBox="0 0 24 21.52" />;
};

export default DepartmentsIcon;
