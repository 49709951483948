import { getEmergencyAlerts } from "../enums";

const CONSTANTS = {
  get: getEmergencyAlerts,
};

const get = `
    ${CONSTANTS.get} {
      list {
        date
        description
        itemHash
        title
        links
      }
    }
`;

const source = {
  CONSTANTS,
  QUERIES: {
    get,
  },
};

export default source;
