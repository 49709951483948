/**SERVICES */
export const CONTENT_BUCKET_SERVICE = "CONTENT_BUCKET_SERVICE"
export const CONTENT_SERVER_SERVICE = "CONTENT_SERVER_SERVICE"
export const GOOGLE_SERVICE = "GOOGLE_SERVICE"
export const AWS_LAMBDA_SERVICE = "AWS_LAMBDA_SERVICE"
export const LEX_SERVICE = "LEX_SERVICE"

/**QUERY REFERENCES */
export const BALANCES = "BALANCES"
export const CARMEN = "CARMEN"
export const CLASSES = "CLASSES"
export const HOLDS = "HOLDS"
export const GRADES = "GRADES"
export const SEARCH_CONTENT_SERVER = "SEARCH_CONTENT_SERVER"
export const SOA = "SOA"
export const STUDENT_ORGS = "STUDENT_ORGS"
export const BUCKEYELINK = "BUCKEYELINK"
export const GOOGLE = "GOOGLE"
export const ARTICLES = "ARTICLES"
export const AFFILIATIONS = "AFFILIATIONS"
export const ASSIGNMENTS = "ASSIGNMENTS"
export const WELLNESS = "WELLNESS"
export const BUILDINGS = "BUILDINGS"
export const ANNOUNCEMENTS = "ANNOUNCEMENTS"
export const PERSONS = "PERSONS"
export const EMERGENCY_ALERTS = "EMERGENCY_ALERTS"
export const ENROLLMENTS = "ENROLLMENTS"
export const FINAL_GRADES = "FINAL_GRADES"
export const ACTIVE_TERMS = "ACTIVE_TERMS"
export const INSTRUCTORS = "INSTRUCTORS"
export const BUCKEYELEARN = "BUCKEYELEARN"
export const CHAT = "CHAT"
export const HR_DATA = "HR_DATA"
export const PAY = "PAY"
export const LEAVE = "LEAVE"
export const TODOS = "TODOS"

/**GRAPHQL FieldName */
export const getEnrollments = "getEnrollments"
export const getGrades = "getGrades"
export const getBuckeyeLinkData = "getBuckeyeLinkData"
export const getArticles = "getArticles"
export const getAccountBalances = "getAccountBalances"
export const getAffiliations = "getAffiliationPersonInfo"
export const getAssignments = "getAssignments"
export const getCarmen = "getCarmen"
export const getClasses = "getClasses"
export const getHolds = "getHolds"
export const searchAcrossContentServer = "searchAcrossContentServer"
export const getStatementOfAccount = "getStatementOfAccount"
export const getStudentOrganizations = "getStudentOrganizations"
export const getGoogle = "getGoogleResults"
export const getWellness = "getWellnessResources"
export const getBuildings = "getBuildings"
export const getAnnouncements = "getAnnouncements"
export const dismissAnnouncements = "dismissAnnouncements"
export const getAllAnnouncements = "getAllAnnouncements"
export const getPerson = "getPerson"
export const getEmergencyAlerts = "getEmergencyAlerts"
export const getFinalGrades = "getFinalGrades"
export const getActiveTerms = "getActiveTerms"
export const getInstructors = 'getSEI'
export const getBuckeyeLearnTranscript = "getBuckeyeLearnTranscript"
export const sendTextMessageToLex = "sendTextMessageToLex"
export const getHRProfileData = 'getHRProfileData'
export const getToDoList = "getToDoList"
