import React, { useContext, useEffect } from "react";
import { PageHeadingContext } from "@osu/react-ui";

function Unauthorized(props = {}) {
  const { setHeading } = useContext(PageHeadingContext);

  useEffect(() => {
    setHeading && setHeading("Unauthorized");
  }, [setHeading]);

  return <span>You are not authorized to see this page!</span>;
}

export default Unauthorized;
