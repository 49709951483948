import { forwardRef } from "react";
import { Mui, MuiStyles } from "@osu/react-ui";
import Assignment from "./Assignment";
import useColorizeListByKey from "../../Common/hooks/useColorizeListByKey";

const AssignmentListsByDueDate = forwardRef(({ groupedAssignments, nextUpcomingDueDate }, ref) => {
  const theme = MuiStyles.useTheme();
  const { map: colorList } = useColorizeListByKey();
  return (
    <>
      {groupedAssignments?.map((assignments, index1) => {
        const dueDate = assignments[0].dueAt ? new Date(assignments[0].dueAt).toDateString() : null;
        let displayDate = "";
        if (!dueDate) {
          displayDate = "No Due Date";
        } else {
          displayDate = dueDate;
        }

        const hasDate = nextUpcomingDueDate && new Date(nextUpcomingDueDate).toDateString() === dueDate;

        let header = (
          <h4 ref={hasDate ? ref : null}>
            <Mui.Typography variant="subtitle" sx={{ width: "60%", fontWeight: "bold" }}>
              <time dateTime={assignments[0].dueAt}>{displayDate}</time>
            </Mui.Typography>
          </h4>
        );

        const gridSize = window.innerWidth < theme.breakpoints.values.lg ? 12 : 6;
        return (
          <div key={index1}>
            {header}
            <Mui.Grid container spacing={2}>
              {assignments.map((assignment, index2) => (
                <Mui.Grid item xs={12} key={index2}>
                  <Assignment gridSize={gridSize} assignment={assignment} color={colorList[assignment.courseId]} />
                </Mui.Grid>
              ))}
            </Mui.Grid>
          </div>
        );
      })}
    </>
  );
});

export default AssignmentListsByDueDate;
