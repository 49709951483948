import { createSlice, isDraft, original } from "@reduxjs/toolkit";
import { DISPLAY } from "../../../Calendar/util/enums";
import {
  getMajority,
  recalculateDates,
  transformMeetings,
} from "../../../Calendar/util/functions";
import { isValid, isSameDay, parseISO, isBefore, format } from "date-fns";
import { KEYS } from "../../../Common/hooks/useColorizeListByKey";
import { sortAssignments } from "../../../Assignments/util/transform";

const today = new Date()

let initialState = {
  CONTROLS: DISPLAY.CONTROLS,
  VIEW_BY_OPTIONS: DISPLAY.VIEW_BY_OPTIONS,
  ...DISPLAY.DEFAULT_STATE,
  classes: [],
  meetings: [],
  cachedMeetings: [],
  assignmentsDue: [],
  assignmentCourses: [],
  colorReferences: [],
  fetched: false,
  toolbar: {
    termNames: [],
    earliest: {},
    latest: {},
  },
  courseIds: [],
  colorsInitialized: false
};

const defaultHeight = "5rem";
initialState[DISPLAY.CONTROLS.VIEW_BY] = DISPLAY.VIEW_BY_OPTIONS.WEEK;
initialState.heightPerHour = defaultHeight;

const getDate = (desiredDate, backupField) => {
  if (isValid(desiredDate)) {
    return desiredDate;
  }
  if (isValid(parseISO(desiredDate))) {
    return parseISO(desiredDate);
  }
  const backupDate = initialState[backupField];

  if (isValid(backupDate)) return backupDate;
  if (isValid(parseISO(backupDate))) return parseISO(backupDate);
  return new Date();
};

const findCachedMeeting = (state = {}, start, end, viewBy) => {
  const matchingViewBy =
    viewBy &&
    state.cachedMeetings.filter(
      (meeting) => meeting.viewBy === viewBy && !!meeting?.meetings?.length
    );

  if (matchingViewBy?.length) {
    return matchingViewBy[matchingViewBy.length - 1];
  }

  const meetingDataIsCached = state.cachedMeetings.find((meeting) => {
    const { meetings, startDate, endDate } = meeting;
    const startIsoA = start ?? (state.START_DATE && parseISO(state.START_DATE));
    const startIsoB = startDate && parseISO(startDate);

    const endIsoA = end ?? (state.END_DATE && parseISO(state.END_DATE));
    const endIsoB = endDate && parseISO(endDate);

    const isSameStart =
      startIsoA && startIsoB && isSameDay(startIsoA, startIsoB);
    const isSameEnd = endIsoA && endIsoB && isSameDay(endIsoA, endIsoB);
    const hasClasses = !!meetings?.length;

    return isSameStart && isSameEnd && hasClasses;
  });

  return meetingDataIsCached;
};

const adjustHeight = ({ earliestHours, latestHours, sizing }) => {
  const existingHeightPerHour = sizing.HEIGHT_PER_HOUR;
  const maxHeight = sizing.MAX_HEIGHT;
  const hphValue = Number(existingHeightPerHour.replace("rem", ""));
  let newHeight = maxHeight / (latestHours - earliestHours);
  if (newHeight > hphValue) {
    return (newHeight += "rem");
  }
  return existingHeightPerHour ?? defaultHeight;
};

const adjustCalendarWithNewData = ({ classes, startDate, endDate, sizing }) => {
  const transformation = transformMeetings(classes, {
    startDate,
    endDate,
  });
  const { scrollToEarliest, latest } = transformation;

  const heightPerHour = adjustHeight({ earliestHours: scrollToEarliest?.hours, latestHours: latest?.hours, sizing });

  return {
    ...transformation,
    heightPerHour,
  };
};

const updateWithWeekend = ({ classes, state }) => {
  if (Array.isArray(classes)) {
    let hasWeekendClasses = false
    classes.forEach(({ saturday, sunday }) => {
      if(saturday || sunday) {
        hasWeekendClasses = true
      }
    });
    state.showWeekend = hasWeekendClasses
  }
}

const setNewClasses = (state, classes, startDateOverride, endDateOverride) => {
  const newClasses = classes;
  if (Array.isArray(newClasses)) {
    const startDate = getDate(
      startDateOverride ?? state.START_DATE,
      "START_DATE"
    );
    const endDate = getDate(endDateOverride ?? state.END_DATE, "END_DATE");
    state.classes = newClasses;
    const { meetings, heightPerHour, scrollToEarliest, latest } =
      adjustCalendarWithNewData({
        classes: newClasses,
        startDate,
        endDate,
        sizing: state.SIZING,
      });

    const isoStart = startDate.toISOString();
    const isoEnd = endDate.toISOString();
    state.cachedMeetings.push({
      startDate: isoStart,
      endDate: isoEnd,
      meetings,
      heightPerHour,
      viewBy: state[DISPLAY.CONTROLS.VIEW_BY],
    });
    state.START_DATE = isoStart;
    state.END_DATE = isoEnd;
    state.meetings = meetings;
    state.heightPerHour = heightPerHour;
    state.scrollToEarliest = scrollToEarliest;
    state.latest = latest;
    updateWithWeekend({ classes: newClasses, state })
  }
};

const dashboardCalendarSlice = createSlice({
  name: "dashboardCalendar",
  initialState,
  reducers: {
    setClasses: (state, action) => {
      const newClasses = action.payload.classes;
      const chips = action.payload.chips
      state.toolbar.termNames = action.payload.termNames ?? []
      if(Array.isArray(newClasses)) {
        state.toolbar.earliest = newClasses[0]
        state.toolbar.latest = newClasses[newClasses.length - 1]
        setNewClasses(state, newClasses);
      }
      if(chips?.length) {
        state.chips = chips
      }
      if(action?.payload?.courseIds?.length) {
        state.courseIds = action.payload.courseIds
      }
      state.fetched = true
    },
    setViewByOptions: (state, action) => {
      const payload = action?.payload ?? {};
      const currentViewBy = state[DISPLAY.CONTROLS.VIEW_BY];
      let startDate, endDate;

      if (payload.startDate) {
        startDate = getDate(payload.startDate, "START_DATE");
        endDate = getDate(payload.endDate ?? state.END_DATE, "END_DATE");
      }

      if (payload.endDate) {
        endDate = getDate(payload.endDate, "END_DATE");
        startDate = getDate(
          payload.startDate ?? state.START_DATE,
          "START_DATE"
        );
      }

      const useViewBy =
        !startDate &&
        !endDate &&
        payload?.viewBy &&
        currentViewBy !== payload.viewBy;
      if (useViewBy) {
        startDate = getDate(state.START_DATE, "START_DATE");
        endDate = getDate(state.END_DATE, "END_DATE");
        state[DISPLAY.CONTROLS.VIEW_BY] = payload.viewBy;

        const defaultStart = parseISO(DISPLAY.DEFAULT_STATE.START_DATE);
        const defaultEnd = parseISO(DISPLAY.DEFAULT_STATE.END_DATE);

        const revertToDefault =
          payload.viewBy === DISPLAY.VIEW_BY_OPTIONS.WEEK &&
          currentViewBy === DISPLAY.VIEW_BY_OPTIONS.DAY &&
          isSameDay(startDate, endDate);
        if (revertToDefault) {
          startDate = defaultStart;
          endDate = defaultEnd;
        }

        const { start, end } = recalculateDates(
          startDate,
          endDate,
          payload.viewBy
        );
        startDate = start;
        endDate = end;
      }

      const cachedMeeting = findCachedMeeting(
        state,
        startDate,
        endDate,
        payload.viewBy
      );
      if (cachedMeeting) {
        const { meetings, heightPerHour, scrollToEarliest, latest } =
          cachedMeeting;

        state.meetings = meetings;
        state.heightPerHour = heightPerHour;
        state.scrollToEarliest = scrollToEarliest;
        state.latest = latest;
        state.START_DATE = cachedMeeting.startDate;
        state.END_DATE = cachedMeeting.endDate;
      } else {
        setNewClasses(state, state.classes, startDate, endDate);
        if (startDate) state.START_DATE = startDate.toISOString();
        if (endDate) state.END_DATE = endDate.toISOString();
      }
      const startAndEndSame = isSameDay(
        parseISO(state.START_DATE),
        parseISO(state.END_DATE)
      );
      state[DISPLAY.CONTROLS.VIEW_BY] = startAndEndSame
        ? DISPLAY.VIEW_BY_OPTIONS.DAY
        : DISPLAY.VIEW_BY_OPTIONS.WEEK;
    },
    setAssignmentCourses: (state, action) => {
      const currentAssignments = action?.payload?.assignmentsDue ?? []
      const courseType = {
        enrollmentName: currentAssignments[0]?.enrollmentName,
        courseId: currentAssignments[0]?.courseId,
      }
      const alreadyExists = state.assignmentCourses.find(o => {
        return o.enrollmentName && (o.enrollmentName === courseType.enrollmentName)
          && o.courseId && (o.courseId === courseType.courseId)
      })
      if(!alreadyExists) {
        const existingAssignments = isDraft(state.assignmentsDue) ? original(state.assignmentsDue) :state.assignmentsDue
        const newAssignments = existingAssignments.concat(currentAssignments)
        state.assignmentsDue = sortAssignments(newAssignments)
        state.assignmentCourses = []
        state.assignmentsDue.forEach(({ enrollmentName, courseId }) => {
          const alreadyExists = state.assignmentCourses.find(({enrollmentName: en, courseId: cid }) => {
            return en === enrollmentName && courseId === cid
          })
  
          if(!alreadyExists) {
            state.assignmentCourses.push({ enrollmentName, courseId })
          }
        })
      }
    },
    initializeColorList: (state) => {
      const { classes = [], assignmentCourses } = state
      state.colorsInitialized = true
      let mergedClassesAndAssignments = assignmentCourses.map(({ courseId, ...rest }) => ({
        ...rest,
        [KEYS.ASSIGNMENTCID]: courseId,
        [KEYS.COLORID]: courseId
       }))
       
       classes.forEach((classData = {}) => {
        const { courseId, catalogNumber, subject, description } = classData
         const matchingAssignmentCourse = findMatchingAssignmentCourseColorId({
            description, catalogNumber, subject, assignmentCourses: state.assignmentCourses
          })
          const refToMerged = matchingAssignmentCourse?.courseId && mergedClassesAndAssignments.find(existing => {
            return existing[KEYS.ASSIGNMENTCID] === matchingAssignmentCourse.courseId
          })
          const addedFields = { [KEYS.CLASSCOURSEID]: courseId, subject, description }
          if(refToMerged) {
            Object.assign(refToMerged, addedFields)
          } else {
            mergedClassesAndAssignments.push({
              ...addedFields,
              [KEYS.COLORID]: matchingAssignmentCourse?.courseId ?? courseId
            })
          }
      })

      mergedClassesAndAssignments.forEach(mergedItem => {
        const match = state.colorReferences.find(reference => mergedItem?.[KEYS.COLORID] === reference?.[KEYS.COLORID])
        if(!match?.[KEYS.COLORID]) {
          if(mergedItem[KEYS.COLORID]) {
            state.colorReferences.push(mergedItem)
          }
        }
      })

    }
  },
});

export const { setClasses, setViewByOptions, setAssignmentCourses, initializeColorList } = dashboardCalendarSlice.actions;

const findMatchingAssignmentCourseColorId = ({description, catalogNumber, subject, assignmentCourses = []}) => {
  let matchingAssignmentCourse = assignmentCourses.find(({ enrollmentName = "" }) => {
    const match = [description, catalogNumber, subject].every(field => {
      if(!!enrollmentName && !!field) {
        const lcEnrollment = enrollmentName.toLowerCase()
        const lcField = field.toLowerCase()
        return lcEnrollment.includes(lcField)
      }
      return false
    })
    return match
  })

  return matchingAssignmentCourse
}

const reducers = {
  getDashboardCalendarDisplay: dashboardCalendarSlice.reducer,
};

export const selectDashboardCalendarDateRange = (state = {}) => {
  const start = state.dashboardCalendar?.START_DATE;
  const end = state.dashboardCalendar?.END_DATE;
  return {
    start: start && new Date(start),
    end: end && new Date(end),
  };
};

export const selectDashboardCalendarViewBy = (state = {}) => {
  return state.dashboardCalendar[DISPLAY.CONTROLS.VIEW_BY];
};

const fallback = transformMeetings([], {
  startDate: new Date(initialState.START_DATE),
  endDate: new Date(initialState.END_DATE) 
})

export const selectDashboardCalendarMeetingData = (state = {}) => {
  const { meetings: _meetings, heightPerHour, scrollToEarliest, latest } =
    state?.dashboardCalendar ?? {};
  const meetings = _meetings?.length ? _meetings : fallback.meetings
  const { month, year } = getMajority(meetings);
  
  return {
    meetings,
    heightPerHour,
    scrollToEarliest,
    latest,
    month,
    year,
  };
};

export const dashCalComparators = {
  meetings: (meetingsA, meetingsB) => {
    const createUnique = (meets =[]) => {
      return meets.map(({ dayOfYear, meetings }) => {
        const ids = meetings.map(({ meetingId }) => meetingId).join()
        return dayOfYear + ids
      }).join()
    }
    return createUnique(meetingsA ?? []) === createUnique(meetingsB ?? [])
  }
}

export const selectHeightPerHour = (state = {}) => {
  return state?.dashboardCalendar?.heightPerHour;
};

export const selectMaxHeight = (state = {}) => {
  return state?.dashboardCalendar?.SIZING?.MAX_HEIGHT;
};

export const selectColorReferences = (state = {}) => {
  return state?.dashboardCalendar?.colorReferences ?? [];
};

export const selectShowWeekend = (state = {}) => {
    return state?.dashboardCalendar?.showWeekend
}

export const selectAssignmentsDue = (state = {}) => {
  return state?.dashboardCalendar?.assignmentsDue ?? []
}

export const selectCalendarToolbar = (state = {}) => {
  return state?.dashboardCalendar?.toolbar ?? {}
}

export const selectCalendarLimits = (state = {}) => {
  const { earliest, latest } = selectCalendarToolbar(state)
  
  let dates = {
    earliest: earliest?.start && new Date(earliest.start),
    latest: latest?.end && new Date(latest.end)
  }
  
  const isBeforeToday = isBefore(dates.latest, today)
  if(isBeforeToday) {
    dates.latest = today
  }
  
  return dates
}

export const selectCalendarShowingTermsLabel = (state = {}) => {
  const termNames = state?.dashboardCalendar?.toolbar?.termNames?? []
  const { earliest, latest } = selectCalendarToolbar(state)

  const names = termNames.join(", ")
  let label = names.length ? `Displaying classes for ${names}` : ""
  const isBeforeToday = latest?.end && isBefore(new Date(latest.end), today)
  const dateFormat = "MMMM do, yyyy"
  if(isBeforeToday) {
    const start = new Date(earliest.start)
    const end = new Date(latest.end)
    label = `This calendar shows classes that started ${format(start, dateFormat)} and ended ${format(end, dateFormat)}.`
  }

  return label
}

export const selectClassCalChips = (state = {}) => {
  const chips = state?.dashboardCalendar?.chips ?? []
  return chips
}

export const selectClasssesFetched = (state = {}) => {
  return state?.dashboardCalendar?.fetched
}

export const selectColorsInitialized = (state = {}) => {
  return state?.dashboardCalendar?.colorsInitialized
}

export {
  initialState
}
export default reducers;
