import { Mui } from "@osu/react-ui"
import { BUCK_ID, DINING_DOLLARS } from "../components/constants"

const useColorStyles = (account, active) => {
    const theme = Mui.useTheme()
    let palette = theme?.palette;
    
    let styles = {
        '&:hover': {
            color: '#000000'
        }
    }
    
    styles.backgroundColor = _getColor(account, active);
    styles.borderColor = _getColor(account);
    if(styles.backgroundColor){
        styles.color = palette?.getContrastText(styles.backgroundColor)
        styles['&:hover'].backgroundColor = Mui.darken(styles.backgroundColor, .4);
        styles['&:hover'].color = palette?.getContrastText(styles['&:hover'].backgroundColor)
    }
    
    return styles;
}

const _getColor = (account, active) => {
    let color;
    const theme = Mui.useTheme()
    let palette = theme?.palette;
    let colors = palette?.colors || {};
    const darkMode = palette?.mode === "dark";
    if(active) {
        color = !darkMode ? palette?.background?.paper : "#fafafa";
    } else if (account === BUCK_ID){
        color = colors?.red;
    } else if (account === DINING_DOLLARS){
        color = colors?.teal;
    } else {
        color = colors?.osuGray;
    }
    return color;
}

export default useColorStyles