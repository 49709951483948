import React from "react";
import { Mui } from "@osu/react-ui";

function Chip(props = {}) {
  return (
    <Mui.Chip
      size="small"
      {...props}
    />
  );
}

export default Chip;
