import { Mui } from "@osu/react-ui";
import InfoSection from "./InfoSection";
import SubmissionSection from "./SubmissionSection";
import useColorTileSx from "../../Common/hooks/useColorTilesSx";
import useColorizeListByKey from "../../Common/hooks/useColorizeListByKey";

const Assignment = ({ assignment, gridSize, showDate = false }) => {
  const theme = Mui.useTheme()
  const { map: colorList } = useColorizeListByKey(); 
  const backgroundColor = colorList[assignment?.courseId] ?? theme.palette.colors.gray 
  const colorSx = useColorTileSx({ backgroundColor })
  
  return (
    <Mui.Paper
      elevation={0}
      sx={{
        ...colorSx,
        mb: "1rem",
        mr: "1rem"
      }}
    >
      <Mui.Grid container spacing={0}>
        <Mui.Grid item xs={gridSize}>
          <InfoSection
            assignment={assignment}
            backgroundColor={backgroundColor}
            showDate={showDate}
          ></InfoSection>
        </Mui.Grid>
        <Mui.Grid item xs={gridSize} >
          <SubmissionSection
            assignment={assignment}
            backgroundColor={backgroundColor}
            alignment={gridSize > 6 ? "left" : "right"}
          ></SubmissionSection>
        </Mui.Grid>
      </Mui.Grid>
    </Mui.Paper>
  );
};

export default Assignment;
