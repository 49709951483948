import { Mui } from '@osu/react-ui';
import useGridSizing from '../../Dashboard/hooks/useGridSizing';

export const useClasses = () => {
  const theme = Mui.useTheme();
  const {padding} = useGridSizing()

  return {
    appBar: (zIndex) => ({
      top: "0",
      zIndex,
      paddingX: theme.spacing(padding),
      borderBottom:
        theme.palette.mode === "dark" && `solid 1px ${theme.palette.divider}`,
    }),
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  };
};
