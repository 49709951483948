
import {Mui, MuiStyles} from '@osu/react-ui';
import DynamicTableCells from './DynamicTableCells';
import useTableDisplay from '../hooks/useTableDisplay';
import { TRANSACTION_DISPLAY_INFO, MOBILE_TRANSACTION_DISPLAY_INFO } from './constants';
import { labels, timelineReferences } from '../../Dashboard/util/enums';


const TransactionsTable = ({filteredTransactions}) => {
    filteredTransactions = filteredTransactions.length ? filteredTransactions : [{description: "No Recent Transactions" }]
    return (
        <Mui.Table stickyHeader>
            <Mui.Typography sx={{ captionSide: 'top' }} variant="subtitle1" component="caption" className="margin-bottom-1">
                Showing {labels.BALANCES} activity for the past {timelineReferences.BALANCES}
            </Mui.Typography>
            <TableHeader />
            <Mui.TableBody>
            {filteredTransactions.map(( transaction, index) => {
                return(  
                    <TransactionTableRow index={index} key={index} transaction={transaction} />
                )
            })}
            </Mui.TableBody>
        </Mui.Table>
    )
}

const TableHeader = () => {
    const shouldTableDisplay = useTableDisplay()
    const headerInfo = shouldTableDisplay ? TRANSACTION_DISPLAY_INFO : MOBILE_TRANSACTION_DISPLAY_INFO

    return (
        <Mui.TableHead>
            <Mui.TableRow>
                {Object.entries(headerInfo ?? {}).map( ([key, width], index) => {
                    return (
                        <Mui.TableCell width={width} key={`${key}-${index}`}>{key}</Mui.TableCell>
                    )
                })}
            </Mui.TableRow>
        </Mui.TableHead>
    )
}

const TransactionTableRow = ({index, transaction}) => {
    const theme = MuiStyles.useTheme()
    const shouldTableDisplay = useTableDisplay()
    const columnInfo = shouldTableDisplay ? TRANSACTION_DISPLAY_INFO : MOBILE_TRANSACTION_DISPLAY_INFO
    const backgroundColor = index % 2 === 0 ? theme?.palette?.rows?.even : theme?.palette?.rows?.odd;

    return (
        <Mui.TableRow sx={{backgroundColor}}>
            <DynamicTableCells transaction={transaction} />
            <Mui.TableCell  width={columnInfo.Amount}>{transaction.amount ?? "-"}</Mui.TableCell>
        </Mui.TableRow>
    )
}

export default TransactionsTable