import { Mui, MuiIcons } from "@osu/react-ui";
import ExternalLink from "../../Common/components/ExternalLink";
import { getTime } from "../util/time";
import { useSelector } from "react-redux";
import { selectEnrollmentData } from "../../services/graphql/slices/dashboard";

const getDueDateTime = (dueAt, showDate) => {
  if (!dueAt) {
    return "No due date";
  } else if (showDate) {
    return new Date(dueAt).getMonth() + 1 + "/" + new Date(dueAt).getDate() + " at " + getTime(new Date(dueAt));
  } else {
    return getTime(new Date(dueAt));
  }
};

const InfoSection = ({ assignment, backgroundColor, showDate }) => {
  const courseId = assignment.courseId ?? "";
  const enrollmentName = useSelector(state => {
    if(assignment?.enrollmentName) {
      return assignment.enrollmentName
    }
    return selectEnrollmentData(state,courseId)?.name
  });
  const name = assignment.name ?? "";
  const dueDatetime = getDueDateTime(assignment.dueAt, showDate);
  const theme = Mui.useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const link = assignment.htmlURL ?? "";
  const dueAtLeadText = assignment.dueAt ? "Due at " : "";
  return (
    <>
      <dt style={{ margin: "1rem 1rem .5rem 1rem" }}>
        <ExternalLink underline="hover" variant="body2" href={link}>
          <Mui.Typography
            variant="subtitle"
            sx={{
              fontSize: "1rem",
              fontWeight: "bolder",
              color: isDarkMode ? Mui.lighten(backgroundColor, 14 / 20) : Mui.darken(backgroundColor, 14 / 20),
            }}>
            {name}
          </Mui.Typography>
        </ExternalLink>
      </dt>
      <dd style={{ margin: ".5rem 1rem .5rem 1rem" }}>
        {(!showDate && enrollmentName) && (
          <Mui.Link underline="none" variant="subtitle2" color="secondary" href={"/carmen-class/" + courseId}>
            <Mui.Typography
              sx={{
                textAlign: "center",
                border: ".1rem",
                borderStyle: "solid",
                borderColor: backgroundColor,
                borderRadius: "1rem",
                backgroundColor: isDarkMode ? Mui.darken(backgroundColor, 17 / 20) : Mui.lighten(backgroundColor, 17 / 20),
                color: isDarkMode ? Mui.lighten(backgroundColor, 14 / 20) : Mui.darken(backgroundColor, 14 / 20),
                textWrap: "balance",
                "&:hover": {
                  backgroundColor,
                  color: theme.palette.getContrastText(backgroundColor)
                }
              }}>
              {enrollmentName}
              <MuiIcons.ArrowRight sx={{ verticalAlign: "middle" }} />
            </Mui.Typography>
          </Mui.Link>
        )}
      </dd>
      <dd
        style={{
          align: "bottom",
          alignItems: 'center',
          display: 'flex',
          margin: "0rem 1rem .5rem 1rem",
          color: isDarkMode ? Mui.lighten(backgroundColor, 14 / 20) : Mui.darken(backgroundColor, 14 / 20),
        }}>
        <MuiIcons.CalendarMonth sx={{ verticalAlign: "middle", maxHeight: "1rem" }} />
        {dueAtLeadText}
        <time style={{paddingLeft: ".25rem"}} dateTime={dueDatetime}>{dueDatetime}</time>
      </dd>
    </>
  );
};

export default InfoSection;
