import { Mui } from "@osu/react-ui"

const usePaperStyleProps = () => {
    const theme = Mui.useTheme()
    const darkMode = theme?.palette?.mode === "dark"
    const zIndex = {
        navbar: theme.zIndex.appBar,
        highest: theme.zIndex.snackbar
    }

    let props = {
        default: {
            elevation: 1
        },
        search: {
            elevation: 8
        },
        child: {
            elevation: 2
        },
        dashboard: {
            elevation: 4
        },
        accordionChildren: {
            elevation: 6
        },
        welcome: {
            elevation: 6
        },
        nestedDetail: {
        },
        zIndex
    }
    if(darkMode) {
        props.nestedDetail.elevation = 10
        props.dashboard.elevation = 2
        props.welcome.elevation = 2
        props.accordionChildren.elevation = 6
    } else {
        props.nestedDetail.variant = "outlined"
    }

    return props
}

export default usePaperStyleProps