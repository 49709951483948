import { Fragment, useEffect, useRef } from "react";
import { Mui } from "@osu/react-ui";
import { useSelector } from "react-redux";
import {
  selectDashboardCalendarMeetingData
} from "../services/graphql/slices/dashboardCalendar";
import useStylingValues from "./styles/useStylingValues";
import { BROWSER_FONT_SIZE } from "../util/constants";
import { StyledTable } from "./styles/components";
import { HTML_TABLE_IDS } from "./util/enums";

export const TIME_INDICATOR_ID = "time-indicator";

function Table({ children, forceAdjustTableScroll }) {
  const theme = Mui.useTheme()
  const smDevices = Mui.useMediaQuery(theme.breakpoints.down("sm"));
  const {
    scrollToEarliest, 
    heightPerHour
  } = useSelector((state) => selectDashboardCalendarMeetingData(state, {
    sm: smDevices
  }), (a,b) => {
    const isEqual = (a.heightPerHour === b.heightPerHour) 
      && (a?.scrollToEarliest?.meetingId === b?.scrollToEarliest?.meetingId) 

    return isEqual
  });
  const tableRef = useRef()
  const { maxHeight = 30 } = useStylingValues()
  const scrollHeight = scrollToEarliest?.hours
    ? (scrollToEarliest?.hours - 0.1) * heightPerHour.replace("rem", "")
    : 0;

  useEffect(() => {
    if ((tableRef?.current?.scrollTo && scrollHeight) || forceAdjustTableScroll) {
      const top = scrollHeight * BROWSER_FONT_SIZE.replace("px", "");
      tableRef.current.scrollTo({
        top,
      });
    }
  }, [forceAdjustTableScroll, scrollHeight]);

  return (
    <Fragment>
      <Mui.TableContainer ref={tableRef} sx={{ maxHeight: `${maxHeight}rem` }}>
        <StyledTable stickyHeader aria-labelledby={HTML_TABLE_IDS.tableLabel} aria-describedby={HTML_TABLE_IDS.tableDescriptor} className={`margin-top-2 ${Mui.accordionClasses.root}`} size="small">
            {children}
        </StyledTable>
      </Mui.TableContainer>
    </Fragment>
  );
}

export default Table;
