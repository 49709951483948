import { Fragment } from 'react';
import { Mui } from "@osu/react-ui";
import StatementBalance from './statement-balance';
import DashAction from '../../Dashboard/components/DashAction';

const StatementOfAccount = ({ data }) => {
  const statementOfAccount = data;
  const theme = Mui.useTheme()
  const xs = Mui.useMediaQuery(theme.breakpoints.down("sm"));

  if (statementOfAccount) {
    let { currentDue, futureDue, lastModified, pastDue } = statementOfAccount;

    const lastModifiedDate = (new Date(lastModified)).toLocaleString();
    const lastModifiedString = lastModified ? "Last Modified: " + lastModifiedDate : ""

    const balanceWarningText = "This total may not reflect recent changes to your tuition and fees."
    const balanceLinkOutText = "Statement of Account"
    const balanceLinkOutHref = process.env.REACT_APP_SIS_SOA
    const paymentLinkOutText = "Make a Payment"

    return <Fragment>
      <Mui.Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
      }}>
        <StatementBalance balanceType="Current Due" amount={currentDue} />

        <StatementBalance balanceType="Future Due" amount={futureDue} />

        <StatementBalance balanceType="Past Due" amount={pastDue} />
      </Mui.Box>


      <Mui.Typography  sx={{ "fontSize": "smaller", "marginLeft": '1rem', "marginRight": "1rem", "marginBottom": "1rem" }}>
        {lastModifiedString}
      </Mui.Typography>
      <Mui.Typography variant="caption" sx={{  "marginLeft": '1rem', "marginRight": "1rem", "marginBottom": "1rem" }}>
        {balanceWarningText + " "}
      </Mui.Typography>
      <Mui.Divider className="margin-top-1 margin-bottom-2" />
      <Mui.Box display="flex" flexDirection={xs ? "column" : "row"} className="margin-top-2">
        {balanceLinkOutHref && <DashAction href={balanceLinkOutHref}>{balanceLinkOutText}</DashAction>}
        <DashAction  href="https://webauth.service.ohio-state.edu/cgi-bin/cashnet.cgi">{paymentLinkOutText}</DashAction>
      </Mui.Box>
    </Fragment>
  }
  else {
    return false
  }

}

export default StatementOfAccount;
