import { Fragment } from "react";
import { Mui } from "@osu/react-ui";
import Toolbar from "./Toolbar";
import { useSelector } from "react-redux";
import {
  selectCalendarShowingTermsLabel,
  selectDashboardCalendarMeetingData
} from "../services/graphql/slices/dashboardCalendar";
import { HTML_TABLE_IDS } from "./util/enums";
export const TIME_INDICATOR_ID = "time-indicator";

function Header({ setForceAdjustTableScroll}) {
  const theme = Mui.useTheme()
  const smDevices = Mui.useMediaQuery(theme.breakpoints.down("sm"));
  const {
    month,
    year
  } = useSelector((state) => selectDashboardCalendarMeetingData(state, {
    sm: smDevices
  }), (a,b) => {
    const isEqual = (a.month === b.month) && (a.year === b.year)

    return isEqual
  });
  const calLabel = useSelector(selectCalendarShowingTermsLabel)

  return (
    <Fragment>
       <Mui.Typography
          sx={{ display: "block", textAlign: "left" }}
          variant="h5"
          component="span"
          id={HTML_TABLE_IDS.tableLabel}
        >
          {month} {year}
      </Mui.Typography>
      <Mui.Typography className="margin-bottom-2" id={HTML_TABLE_IDS.tableDescriptor} sx={{ captionSide: 'bottom', padding: 0, display: "block" }} component="span" variant="caption">
        {calLabel ?? "Only displays classes for active terms"}
      </Mui.Typography>
      <Toolbar setForceAdjustTableScroll={setForceAdjustTableScroll} className="margin-bottom-2" />
    </Fragment>
  );
}

export default Header;
