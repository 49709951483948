import { Route } from "react-router-dom";
import { RenderableView } from "./components/RenderableView";

const convertPathsToViews = (paths = [], additionalProps = {}) =>
  paths.map((pathInformation, index) => {
    return (
      <Route
        key={`route${pathInformation.path}#${index}`}
        path={pathInformation.path}
        element={<RenderableView {...additionalProps} {...pathInformation} />}
      />
    );
  });

  const createSearchPageTitle = ({ searchParams = {}, query = "", loading = false, ...rest }) => {
  const q = (searchParams?.get ? searchParams?.get("q") : query) ?? ""
  let searchQuery = q
  
  try {
    const parsed = searchQuery ? decodeURIComponent(searchQuery ?? "") : ''
    searchQuery = parsed    
  } catch (error) {
    console.error(error)
  }
  if(searchQuery) {
    searchQuery = searchQuery.replaceAll("#", "")
  }

  if(loading) {
    return `Searching${searchQuery ? `: ${searchQuery}` : ""}`
  } else if(searchQuery) {
    return `Search Results: ` + searchQuery
  }

  return "Search"
}

export { convertPathsToViews, createSearchPageTitle };
