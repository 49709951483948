import { Mui } from "@osu/react-ui";
import { format } from "date-fns";
import { toDoStatuses } from "../../../services/graphql/slices/todos";

const DateChip = ({ statusName, statusDate, dueDate, className, ...rest }) => {
  let label = '', date = ''
  const lcStatus = statusName && statusName.toLowerCase()
  let color = undefined, variant = undefined, fontWeight
  if(lcStatus === toDoStatuses.keys.incomplete && dueDate) {
    label = 'Due Date'
    date = dueDate
    color = 'yellow'
    variant = 'outlined'
    fontWeight = 'bold'
  } else if(lcStatus === toDoStatuses.keys.completed && statusDate) {
    label = 'Completed'
    date = statusDate
  }


  if(!label ||!date) return false
  return (
    <Mui.Chip
    {...rest}
    variant={variant}
    label={label + ": " + format(date, "PP")}
    color={color}
    className={"margin-bottom-1" + (className ? ` ${className}` : '') }
    sx={{
      fontWeight,
      borderWidth: ".15em",
    }}
  />
  );
};

export default DateChip;
